import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// import useAccessKey from './useAccessKey';
import type { GroupDetailResult } from '../types/Group/groupDetail';
import {
  GROUP_DETAIL_API_ERROR_TYPE,
  GROUP_DETAIL_API_RESULT_CODE,
} from '../apis/callGroupDetailApi';
import {
  API_CALL_STATE,
  DIRECTION_TYPE,
  DisplayContentState,
  FILTERING_STATE,
  GROUP_DEVICE_LIST_TABLE_DATA_KEY,
  SortConfig,
  TableHeaderData,
  TableRowData,
} from '../types/datatable/dataTable.d';
import usePagination from './usePagination';
import {
  SERVER_MESSAGE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../constants/constants';
import { fetchGroupDeviceList } from '../utils/group/groupDeviceList';
import { GROUP_DEVICE_LIST_API_RESULT_CODE } from '../apis/callGroupDeviceListApi';
import { GroupDeviceListResult } from '../types/Group/groupDeviceList.d';
import {
  convertGroupDeviceListArrayToTableDataArray,
  sortedItems,
  updateSortConfig,
} from '../utils/datatable/dataTableUtil';
import { GroupDeviceListInfo } from '../types/apis/groupDeviceListApi.d';
// import useFilter from './useFilter';
import useRedirectDialog from './useRedirectDialog';
// import useLogout from './useLogout';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useAccountInfo from './useAccountInfo';

export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  onClickFirstPage: () => void;
  onClickLastPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  onClickAddGroupPage: () => void;
};

export type useGroupDeviceList = {
  groupData: React.MutableRefObject<GroupDetailResult | null | undefined>;
  errorType: GROUP_DETAIL_API_ERROR_TYPE | null;
  setErrorType: (
    value: React.SetStateAction<GROUP_DETAIL_API_ERROR_TYPE | null>,
  ) => void;
  errorMessage: string;
  isCallingApi: boolean;
  onClickGroupDetail: (groupId: string) => void;
  onClickGroupList: () => void;
  headerColumns: TableHeaderData[];
  paginationValue: PaginationValue;
};

type Props = {
  searchText: string;
  openManageGroupDialog: boolean;
};

type IDParams = {
  groupId: string;
};

const useGroupDeviceList = ({ searchText, openManageGroupDialog }: Props) => {
  const { accountInfo } = useAccountInfo();
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const groupData = useRef<GroupDetailResult | null>();
  const [errorType, setErrorType] = useState<string | null>(null);
  // const { accessKey } = useAccessKey();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();
  // const { t } = useSwitchLocaleLanguage();
  // const { onClickFilterSearch, filterInputData } = useFilter('');
  const [totalData, setTotalData] = useState<number>(0);
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState<number>(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);

  const filterGroupInfos = useRef<GroupDeviceListInfo[]>([]);

  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);
  const originalDeviceListInfos = useRef<GroupDeviceListInfo[]>([]);
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API call status (before call)
      filteringState: FILTERING_STATE.inactive, // Filtering applied state (filtering off)
    });
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });

  const { groupId } = useParams<IDParams>();

  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 1);

  const [tableDataPayload, setTableDataPayload] = useState({
    groupID: groupId,
    searchText,
    sortDirection: '',
    colName: '',
    rowLimitPerPage: currentRowNumberPerPage,
    currentPageNumber: currentPage,
  });
  const [previousPayload, setPreviousPayload] = useState({});
  const [previousSearchText, setPreviousSearchText] = useState<string>();
  const { t } = useSwitchLocaleLanguage();

  const headerColumns = useMemo(
    (): TableHeaderData[] => [
      {
        key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NUMBER_IMEI,
        value: t('deviceList.header.deviceNumber'),
        isSort: true,
      },
      {
        key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NAME,
        value: t('deviceList.header.deviceName'),
        isSort: true,
      },
      {
        key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_MODEL,
        value: t('deviceList.header.deviceModel'),
        isSort: true,
      },
      // {
      //   key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.ICC_ID_NO,
      //   value: 'ICCID No.',
      //   isSort: true,
      // },

      // {
      //   key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.SIM_EXP_DATE,
      //   value: 'Sim Exp. Date',
      //   isSort: true,
      // },
      {
        key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.CREATED_DATE,
        value: t('deviceList.header.groupAddedDate'),
        isSort: true,
      },
      // {
      //   key: GROUP_DEVICE_LIST_TABLE_DATA_KEY.LAST_USED,
      //   value: 'Last Used',
      //   isSort: true,
      // },
    ],
    [t],
  );

  /**
   * What to do when filtering is updated
   */
  useEffect(() => {
    changeCurrentPage(1);
    setPageDataIndexFrom(1);
  }, [searchText, changeCurrentPage]);
  const createDisplayDataApiFulfilled = useCallback(() => {
    let list: GroupDeviceListInfo[] = [];
    if (displayContentState.filteringState === FILTERING_STATE.inactive) {
      list = originalDeviceListInfos.current;
    }
    // with filter
    else if (displayContentState.filteringState === FILTERING_STATE.active) {
      list = filterGroupInfos.current;
    }
    let tableRowDataArray: TableRowData[] =
      convertGroupDeviceListArrayToTableDataArray(list);

    // apply sort
    if (sortConfig.direction !== DIRECTION_TYPE.none) {
      tableRowDataArray = sortedItems(tableRowDataArray, sortConfig);
    }
    setCurrentPageDataRows(tableRowDataArray);
  }, [sortConfig, displayContentState.filteringState]);

  const callGroupListApi = useCallback(
    () => {
      // checking to prevent double api calling
      if (
        openManageGroupDialog &&
        JSON.stringify(previousPayload) ===
          JSON.stringify({
            ...tableDataPayload,
            rowLimitPerPage: currentRowNumberPerPage,
            currentPageNumber:
              searchText !== previousSearchText ? 1 : currentPage,
            searchText,
            sortDirection: sortConfig.direction.toUpperCase(),
          })
      ) {
        return;
      }
      setIsCallingApi(true);
      // console.log('ID from useDeviceList', groupId);
      // console.log(searchText);
      fetchGroupDeviceList({
        ...tableDataPayload,
        groupId,
        searchText,
        rowLimitPerPage: currentRowNumberPerPage,
        currentPageNumber:
          searchText !== previousSearchText ? 0 : currentPage - 1,
        // sortDirection:
        //   searchText !== previousSearchText
        //     ? ''
        //     : sortConfig.direction.toUpperCase(),
      })
        .then((result: GroupDeviceListResult) => {
          if (
            result.resultCode ===
            GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
          ) {
            // If successful, set the list returned from the API
            originalDeviceListInfos.current = result.groupDeviceList;
            changeTotalPages(
              Math.ceil(result.totalCount / currentRowNumberPerPage),
            );
            setPageDataIndexFrom(
              ((searchText !== previousSearchText ? 1 : currentPage) - 1) *
                currentRowNumberPerPage +
                1,
            );
            setPageDataIndexTo(
              (searchText !== previousSearchText ? 1 : currentPage) *
                currentRowNumberPerPage >
                result.totalCount
                ? result.totalCount
                : (searchText !== previousSearchText ? 1 : currentPage) *
                    currentRowNumberPerPage,
            );
            setTotalData(result.totalCount);
            setErrorType('');
            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.fulfilled,
            });
            createDisplayDataApiFulfilled();
          } else if (
            result.resultCode ===
            GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup'),
            });
          } else if (
            result.resultCode ===
            GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
          } else if (
            result.resultCode ===
            GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH
          ) {
            // displayRedirectDialog({
            //   open: true,
            //   title: t('common.error.notAuthorized'),
            //   message: t('common.error.roleChanged'),
            //   routePath: onClickLogoff,
            // });
          } else if (
            result.resultCode === SERVER_MESSAGE.USER_CONSENT_REQUIRED
          ) {
            setErrorType('');
            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.rejected,
            });
          } else {
            // Set an empty list on failure
            originalDeviceListInfos.current = [];
            setErrorType(result.resultCode);

            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.rejected,
            });
          }

          // loadingBarHide
          setIsCallingApi(false);
          // Show message
          // overTimeHideSuccessMessage(storageSuccessMessageRef.current);
        })
        .finally(() => {
          // Do nothing (if you do not add finally, an error will occur)
        });
      // setting previous api payload
      setPreviousPayload({
        ...tableDataPayload,
        rowLimitPerPage: currentRowNumberPerPage,
        currentPageNumber: searchText !== previousSearchText ? 1 : currentPage,
        searchText,
        sortDirection: sortConfig.direction.toUpperCase(),
      });
      setPreviousSearchText(searchText);
    },

    // add invalid comment because we don't want to detect displayContentState here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tableDataPayload,
      currentRowNumberPerPage,
      currentPage,
      searchText,
      sortConfig,
      previousPayload,
      openManageGroupDialog,
    ],
  );
  /*eslint-disable*/
  // resetting page number when searchText changes
  useEffect(() => {
    changeCurrentPage(1);
    setPageDataIndexFrom(1);
  }, [searchText]);

  // useEffect for api calling
  useEffect(() => {
    callGroupListApi();
  }, [
    currentRowNumberPerPage,
    currentPage,
    sortConfig,
    searchText,
    openManageGroupDialog,
    accountInfo.locale,
  ]);

  /**
   * Sort button clicked
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort || isCallingApi) {
        // If the column does not allow sorting, do nothing
        return;
      }
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
      changeCurrentPage(1);
      setTableDataPayload({
        ...tableDataPayload,
        sortDirection: sortConfigUpdate.direction,
        colName: data.key,
      });

      setDisplayContentState({
        ...displayContentState,
        apiCallState: API_CALL_STATE.pending,
      });
    },
    [
      sortConfig,
      displayContentState,
      tableDataPayload,
      changeCurrentPage,
      isCallingApi,
    ],
  );

  return {
    groupData,
    currentPageDataRows,
    errorType,
    onClickSortButton,
    isCallingApi,
    setErrorType,
    headerColumns,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      pageDataIndexFrom,
      pageDataIndexTo,
      onClickFirstPage,
      onClickLastPage,
      totalData,
    },
  };
};

export default useGroupDeviceList;
