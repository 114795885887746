import type {
  AccountRegisterInvitationApiRequestParameter,
  AccountRegisterInvitationApiResponse,
} from '../types/apis/accountRegistrationInvitation';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/*eslint-disable*/
const callAccountRegistrationInvitationApi = (
  requestParams: AccountRegisterInvitationApiRequestParameter,
): Promise<AccountRegisterInvitationApiResponse> => {
  const response = callApiUtil
    .post<AccountRegisterInvitationApiResponse>(
      API_URL.REGISTRATION_INVITATION_API,
      requestParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callAccountRegistrationInvitationApi;
