import { useDispatch } from 'react-redux';
import {
  RedirectDialogState,
  RedirectDialogActions,
} from '../state/slice/redirectDialog';

const useRedirectDialog = () => {
  const dispatch = useDispatch();

  const displayRedirectDialog = (redirectDialog: RedirectDialogState) => {
    dispatch(RedirectDialogActions.openRedirectDialog(redirectDialog));
  };

  const closeRedirectDialog = () => {
    dispatch(RedirectDialogActions.closeRedirectDialog());
  };

  return { displayRedirectDialog, closeRedirectDialog } as const;
};

export default useRedirectDialog;
