import {
  LOGIN_API_RESULT_CODE,
  LOGIN_ERROR_TYPE,
} from '../../apis/callLoginApi';
import {
  ACCOUNT_RRGISTER_API_RESULT_CODE,
  ACCOUNT_RRGISTER_ERROR_TYPE,
} from '../../apis/callAccountRegisterApi';
import {
  DEVICE_LIST_API_RESULT_CODE,
  DEVICE_LIST_ERROR_TYPE,
} from '../../apis/callDeviceListApi';
import {
  DEVICE_INFO_API_RESULT_CODE,
  DEVICE_INFO_ERROR_TYPE,
} from '../../apis/callDeviceInfoApi';
import {
  DEVICE_UPDATE_API_RESULT_CODE,
  DEVICE_UPDATE_ERROR_TYPE,
} from '../../apis/callDeviceUpdateApi';
import {
  TRANS_COUNT_LIST_API_RESULT_CODE,
  TRANS_COUNT_LIST_ERROR_TYPE,
} from '../../apis/callTransCountListApi';
import {
  FROM_LANG_LIST_API_RESULT_CODE,
  FROM_LANG_LIST_ERROR_TYPE,
} from '../../apis/callFromLangListApi';
import {
  TO_LANG_LIST_API_RESULT_CODE,
  TO_LANG_LIST_ERROR_TYPE,
} from '../../apis/callToLangListApi';
import {
  PW_REMINDER_SEND_MAIL_API_RESULT_CODE,
  PW_REMINDER_SEND_MAIL_ERROR_TYPE,
} from '../../apis/callPasswordReminderSendMailApi';
import {
  PASSWORD_RESET_API_RESULT_CODE,
  PASSWORD_RESET_ERROR_TYPE,
} from '../../apis/callPasswordResetApi';
import {
  FETCH_SIM_INFO_API_RESULT_CODE,
  FETCH_SIM_INFO_ERROR_TYPE,
} from '../../apis/callFetchSimInfoApi';
import {
  SETTING_INFO_API_RESULT_CODE,
  SETTING_INFO_ERROR_TYPE,
} from '../../apis/callSettingInfoApi';
import {
  SETTING_UPDATE_API_RESULT_CODE,
  SETTING_UPDATE_ERROR_TYPE,
} from '../../apis/callSettingUpdateApi';
import {
  DEVICE_DEREGISTER_API_RESULT_CODE,
  DEVICE_DEREGISTER_ERROR_TYPE,
} from '../../apis/callDeviceDeregisterApi';
import {
  TRANS_HISTORY_LIST_API_RESULT_CODE,
  TRANS_HISTORY_LIST_ERROR_TYPE,
} from '../../apis/callTransHistoryListApi';
import {
  USER_LIST_API_ERROR_TYPE,
  USER_LIST_API_RESULT_CODE,
} from '../../apis/callUserListApi';
import {
  AUTH_LIST_API_ERROR_TYPE,
  AUTH_LIST_API_RESULT_CODE,
} from '../../apis/callAuthListApi';
import {
  USER_INFO_API_RESULT_CODE,
  USER_INFO_ERROR_TYPE,
} from '../../apis/callUserInfoApi';
import {
  USER_UPDATE_API_RESULT_CODE,
  USER_UPDATE_ERROR_TYPE,
} from '../../apis/callUserUpdateApi';
import {
  USER_PREADD_API_ERROR_TYPE,
  USER_PREADD_API_RESULT_CODE,
} from '../../apis/callUserPreAddApi';
import {
  USER_DELETE_API_RESULT_CODE,
  USER_DELETE_ERROR_TYPE,
} from '../../apis/callUserDeleteApi';
import {
  PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE,
  PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE,
} from '../../apis/callPasswordReminderSendMailAuthApi';
import {
  GROUP_ADD_API_ERROR_TYPE,
  GROUP_ADD_API_RESULT_CODE,
} from '../../apis/callGroupAddApi';
import {
  GROUP_CHECK_API_ERROR_TYPE,
  GROUP_CHECK_API_RESULT_CODE,
} from '../../apis/callGroupCheckApi';
import {
  GROUP_LIST_API_RESULT_CODE,
  GROUP_LIST_API_ERROR_TYPE,
} from '../../apis/callGroupListApi';
import {
  GROUP_TOP_USER_API_RESULT_CODE,
  GROUP_TOP_USER_ERROR_TYPE,
} from '../../apis/callTopUserListApi';
import {
  GROUP_DEVICE_LIST_API_ERROR_TYPE,
  GROUP_DEVICE_LIST_API_RESULT_CODE,
} from '../../apis/callGroupDeviceListApi';
import {
  UNGROUP_USER_LIST_API_ERROR_TYPE,
  UNGROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callUnGroupUserListApi';
import {
  MANAGE_GROUP_USER_LIST_API_ERROR_TYPE,
  MANAGE_GROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callManageGroupUserList';
import {
  MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE,
  MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callManageUnGroupUserList';
import {
  MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE,
  MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE,
} from '../../apis/callManageGroupUserListUpdate';
import {
  GROUP_DELETE_API_RESULT_CODE,
  GROUP_DELETE_ERROR_TYPE,
} from '../../apis/callGroupDeleteApi';
import {
  GROUP_NAME_EDIT_API_ERROR_TYPE,
  GROUP_NAME_EDIT_API_RESULT_CODE,
} from '../../apis/callGroupNameEdit';
import {
  GROUP_DETAIL_API_RESULT_CODE,
  GROUP_DETAIL_API_ERROR_TYPE,
} from '../../apis/callGroupDetailApi';
import {
  REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE,
  REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureLimitListApi';
import {
  REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureSetupInfoApi';
import {
  REMOTE_FEATURE_SETUP_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureSetupPostApi';
import {
  FACTORY_RESET_API_ERROR_TYPE,
  FACTORY_RESET_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureFactoryReset';
import {
  MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE,
  MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE,
} from '../../apis/callManageGroupDeviceList';
import {
  MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE,
  MANAGE_UNGROUP_DEVICE_LIST_API_ERROR_TYPE,
} from '../../apis/callManageUnGroupDeviceList';
import {
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE,
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE,
} from '../../apis/callManageGroupDeviceListUpdate';

import { SERVER_MESSAGE, ALL_ERROR_TYPE } from '../../constants/constants';
import {
  UPDATE_PERSONAL_SETTINGS_ERROR_TYPE,
  UPDATE_PERSONAL_SETTINGS_RESULT_CODE,
} from '../../apis/callPersonalSettingsUpdateApi';
import {
  FETCH_PERSONAL_SETTINGS_ERROR_TYPE,
  FETCH_PERSONAL_SETTINGS_RESULT_CODE,
} from '../../apis/callPersonalSettingsFetchApi';
import {
  CORPORATE_DETAILS_API_ERROR_TYPE,
  CORPORATE_DETAILS_API_RESULT_CODE,
} from '../../apis/callGetCorporateDetailsApi';
import {
  UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE,
  UPDATE_CORPORATE_DETAILS_API_RESULT_CODE,
} from '../../apis/callUpdateCorporateDetailsApi';

export const isErrorType = (
  target: ALL_ERROR_TYPE | unknown,
): target is ALL_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === SERVER_MESSAGE.LOGIN_SUCCESS ||
    target === SERVER_MESSAGE.WARN_INPUT_PARAM ||
    target === SERVER_MESSAGE.ERR_REQUEST ||
    target === SERVER_MESSAGE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.INTERNAL_SERVER_ERROR ||
    // Entity Not Found
    target === SERVER_MESSAGE.USER_NOT_FOUND ||
    target === SERVER_MESSAGE.TEMP_USER_NOT_FOUND ||
    target === SERVER_MESSAGE.LANGUAGE_NOT_FOUND ||
    target === SERVER_MESSAGE.DEVICE_NOT_FOUND ||
    target === SERVER_MESSAGE.GROUP_UPDATE_VALIDATION_FAILED ||
    target === SERVER_MESSAGE.GROUP_NOT_FOUND ||
    target === SERVER_MESSAGE.DATA_NOT_FOUND ||
    target === SERVER_MESSAGE.AUTH_DATA_NOT_FOUND ||
    target === SERVER_MESSAGE.ONE_TIME_KEY_NOT_FOUND ||
    target === SERVER_MESSAGE.CORP_NOT_FOUND ||
    // Group Service
    target === SERVER_MESSAGE.UNGROUPED_NOT_ALLOWED ||
    target === SERVER_MESSAGE.CORP_NO_ACCESS ||
    target === SERVER_MESSAGE.GROUP_NO_ACCESS ||
    target === SERVER_MESSAGE.DUPLICATE_GROUP_NAME ||
    // User Service
    target === SERVER_MESSAGE.USER_ALREADY_REGISTERED ||
    target === SERVER_MESSAGE.INFO_ALREADY_INVITED ||
    target === SERVER_MESSAGE.KEY_CREATION_FAILED ||
    target === SERVER_MESSAGE.AUTH_NOT_ALLOWED ||
    target === SERVER_MESSAGE.DELETE_NOT_ALLOWED ||
    target === SERVER_MESSAGE.ONE_TIME_KEY_EXPIRED ||
    target === SERVER_MESSAGE.ONE_TIME_KEY_CHECK_FAILED ||
    target === SERVER_MESSAGE.REGISTRATION_KEY_MAIL_MISMATCH ||
    target === SERVER_MESSAGE.REGISTRATION_SEND_MESSAGE_FAILED ||
    target === SERVER_MESSAGE.ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN ||
    target ===
      SERVER_MESSAGE.ROLE_UPDATE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST ||
    target === SERVER_MESSAGE.DELETE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN ||
    target ===
      SERVER_MESSAGE.DELETE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST ||
    target === SERVER_MESSAGE.USER_IS_NOT_ADMIN ||
    // Language Service

    target === SERVER_MESSAGE.PERIOD_CONTRADICTION ||
    target === SERVER_MESSAGE.CORP_ID_NOT_FOUND ||
    target === SERVER_MESSAGE.OVER_COLUMN_SIZE ||
    // Device service

    target === SERVER_MESSAGE.SETTING_INFO_NOT_AVAILABLE ||
    target === SERVER_MESSAGE.DEVICE_ALREADY_DEREGISTERED ||
    target === SERVER_MESSAGE.PIN_REQUIRED ||
    target === SERVER_MESSAGE.LOST_MODE_ALREADY_ENABLED ||
    target === SERVER_MESSAGE.ACCOUNT_NOT_FOUND ||
    target === SERVER_MESSAGE.DEVICE_IN_LOST_MODE ||
    target === SERVER_MESSAGE.LOST_MODE_ENABLE_REQUIRED ||
    target === SERVER_MESSAGE.LOST_MODE_ALREADY_DISABLED ||
    target === SERVER_MESSAGE.DEVICE_DEREGISTERED ||
    // Remote Device Service Message
    target === SERVER_MESSAGE.WARN_INVALID_AUTH ||
    target === SERVER_MESSAGE.DEVICE_RESTORE_INITIATED ||
    // no internet
    target === SERVER_MESSAGE.NO_INTERNET ||
    // Translation Service

    target === SERVER_MESSAGE.INFO_PERIOD_CONTRADICTION ||
    target === SERVER_MESSAGE.INFO_DATA_OVER ||
    target === SERVER_MESSAGE.WARN_NO_SETTING ||
    // Remote setting service
    target === SERVER_MESSAGE.WIFI_SETTING_EXIST ||
    target === SERVER_MESSAGE.NO_WIFI_SETTING_EXIST ||
    // Auth Service
    target === SERVER_MESSAGE.INVALID_TOKEN ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN ||
    target === SERVER_MESSAGE.WRONG_CREDENTIALS ||
    target === SERVER_MESSAGE.LOGIN_SUCCESS ||
    target === SERVER_MESSAGE.LOGOUT_SUCCESS ||
    target === SERVER_MESSAGE.NOT_LOGGED_IN ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED
  ) {
    return true;
  }

  return false;
};
/**
 * 指定されたオブジェクトの型が LOGIN_ERROR_TYPE かチェック
 *
 * @param target LOGIN_ERROR_TYPE(ログインエラータイプ)
 * @returns true=LOGIN_ERROR_TYPE型 / false=左記以外の型
 */
export const isLoginErrorType = (
  target: LOGIN_ERROR_TYPE | unknown,
): target is LOGIN_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === SERVER_MESSAGE.LOGIN_SUCCESS ||
    target === SERVER_MESSAGE.WRONG_CREDENTIALS ||
    target === SERVER_MESSAGE.USER_NOT_FOUND ||
    target === SERVER_MESSAGE.CORP_NOT_FOUND ||
    target === LOGIN_API_RESULT_CODE.LOGIN_SUCCESS ||
    target === LOGIN_API_RESULT_CODE.WRONG_CREDENTIALS ||
    target === LOGIN_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === LOGIN_API_RESULT_CODE.AUTH_DATA_NOT_FOUND ||
    target === LOGIN_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * Check the specified object type is Account_rrgister_error_type
 *
 * @param target ACCOUNT_RRGISTER_ERROR_TYPE(アカウント登録エラー)
 * @returns true=ACCOUNT_RRGISTER_ERROR_TYPE型 / false=左記以外の型
 */
export const isAccountErrorType = (
  target: ACCOUNT_RRGISTER_ERROR_TYPE | unknown,
): target is ACCOUNT_RRGISTER_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.SUCCESSFULLY_CREATED ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.USER_ALREADY_REGISTERED ||
    target ===
      ACCOUNT_RRGISTER_API_RESULT_CODE.REGISTRATION_SEND_MESSAGE_FAILED ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.ONE_TIME_KEY_CHECK_FAILED ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.INFO_NO_ONE_TIME_KEY ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.INFO_NO_LANGUAGE ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.INTERNAL_SERVER_ERROR ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.ERR_REQUEST ||
    target === ACCOUNT_RRGISTER_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が DEVICE_LIST_ERROR_TYPE かチェック
 *
 * @param target DEVICE_LIST_ERROR_TYPE(端末一覧取得エラータイプ)
 * @returns true=DEVICE_LIST_ERROR_TYPE型 / false=左記以外の型
 */
export const isDeviceListErrorType = (
  target: DEVICE_LIST_ERROR_TYPE | unknown,
): target is DEVICE_LIST_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === DEVICE_LIST_API_RESULT_CODE.OK ||
    target === DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA ||
    target === DEVICE_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === DEVICE_LIST_API_RESULT_CODE.INFO_INVALID ||
    target === DEVICE_LIST_API_RESULT_CODE.INFO_EXPIRED ||
    target === DEVICE_LIST_API_RESULT_CODE.NO_INTERNET ||
    target === DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が DEVICE_INFO_ERROR_TYPE かチェック
 * @param target DEVICE_INFO_ERROR_TYPE(端末情報取得エラータイプ)
 * @returns true=DEVICE_INFO_ERROR_TYPE型 / false=左記以外の型
 */
export const isDeviceInfoErrorType = (
  target: DEVICE_INFO_ERROR_TYPE | unknown,
): target is DEVICE_INFO_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === DEVICE_INFO_API_RESULT_CODE.OK ||
    target === DEVICE_INFO_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === DEVICE_INFO_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === DEVICE_INFO_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === DEVICE_INFO_API_RESULT_CODE.INFO_INVALID ||
    target === DEVICE_INFO_API_RESULT_CODE.INFO_EXPIRED ||
    target === DEVICE_INFO_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED||
    target === SERVER_MESSAGE.INVALID_TOKEN||
    target === SERVER_MESSAGE.EXPIRED_TOKEN
  ) {
    return true;
  }

  return false;
};
export const isGroupCheckErrorType = (
  target: GROUP_CHECK_API_ERROR_TYPE | unknown,
): target is GROUP_CHECK_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_CHECK_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_CHECK_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
    target === GROUP_CHECK_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isGroupAddErrorType = (
  target: GROUP_ADD_API_ERROR_TYPE | unknown,
): target is GROUP_ADD_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_ADD_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_ADD_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === GROUP_ADD_API_RESULT_CODE.INFO_INVALID ||
    target === GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_ADD_API_RESULT_CODE.INFO_EXPIRED ||
    target === GROUP_ADD_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
    target === GROUP_ADD_API_RESULT_CODE.ERR_UNKNOWN ||
    target === GROUP_ADD_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    target === GROUP_ADD_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.INVALID_TOKEN ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN||
    target === SERVER_MESSAGE.NO_INTERNET
  ) {
    return true;
  }

  return false;
};
export const isGroupListErrorType = (
  target: GROUP_LIST_API_ERROR_TYPE | unknown,
): target is GROUP_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA ||
    target === GROUP_LIST_API_RESULT_CODE.INFO_NO_USER ||
    target === GROUP_LIST_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
    target === GROUP_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isGroupTopUserErrorType = (
  target: GROUP_TOP_USER_ERROR_TYPE | unknown,
): target is GROUP_TOP_USER_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_TOP_USER_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_TOP_USER_API_RESULT_CODE.CORPORATION_ACCESS_DENIED ||
    target === GROUP_TOP_USER_API_RESULT_CODE.INFO_NO_GROUP ||
    target === GROUP_TOP_USER_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_TOP_USER_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === GROUP_TOP_USER_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isGroupDetailDeviceListErrorType = (
  target: GROUP_DEVICE_LIST_API_ERROR_TYPE | unknown,
): target is GROUP_DEVICE_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET ||
    target === GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED
  ) {
    return true;
  }

  return false;
};

export const isGroupDeleteErrorType = (
  target: GROUP_DELETE_ERROR_TYPE | unknown,
): target is GROUP_DELETE_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_DELETE_API_RESULT_CODE.SUCCESSFULLY_DELETED ||
    target === GROUP_DELETE_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === GROUP_DELETE_API_RESULT_CODE.INFO_NO_GROUP ||
    target === GROUP_DELETE_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === GROUP_DELETE_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_DELETE_API_RESULT_CODE.INFO_NO_CORPORATION ||
    target === GROUP_DELETE_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isGroupDetailErrorType = (
  target: GROUP_DETAIL_API_ERROR_TYPE | unknown,
): target is GROUP_DETAIL_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_DETAIL_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED ||
    target === GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP ||
    target === GROUP_DETAIL_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === GROUP_DETAIL_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
    target === GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isRemoteFeatureLimitListErrorType = (
  target: REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE | unknown,
): target is REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.OK ||
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isRemoteFeatureSetupPostErrorType = (
  target: REMOTE_FEATURE_SETUP_API_ERROR_TYPE | unknown,
): target is REMOTE_FEATURE_SETUP_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.OK ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_GROUP ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_ACCOUNT ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === REMOTE_FEATURE_SETUP_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isRemoteFeatureSetupInfoErrorType = (
  target: REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE | unknown,
): target is REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.OK ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NO_GROUP ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
    target === REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isFactoryResetErrorType = (
  target: FACTORY_RESET_API_ERROR_TYPE | unknown,
): target is FACTORY_RESET_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === FACTORY_RESET_API_RESULT_CODE.OK ||
    target === FACTORY_RESET_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === FACTORY_RESET_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
    target === FACTORY_RESET_API_RESULT_CODE.DEVICE_RESTORE_INITIATED ||
    target === FACTORY_RESET_API_RESULT_CODE.ACCESS_PERMISSION_DENIED ||
    target === FACTORY_RESET_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isGroupNameEditErrorType = (
  target: GROUP_NAME_EDIT_API_ERROR_TYPE | unknown,
): target is GROUP_NAME_EDIT_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === GROUP_NAME_EDIT_API_RESULT_CODE.SUCCESSFULLY_UPDATED ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_GROUP ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_CORPORATION ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    target === GROUP_NAME_EDIT_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isManageGroupUserListErrorType = (
  target: MANAGE_GROUP_USER_LIST_API_ERROR_TYPE | unknown,
): target is MANAGE_GROUP_USER_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UPDATE_DENIED ||
    target ===
      MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED ||
    target ===
      MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED ||
    MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET ||
    target === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isManageUnGroupUserListErrorType = (
  target: MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE | unknown,
): target is MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target ===
      MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED ||
    target ===
      MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET ||
    target === MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isManageGroupUserListUpdateErrorType = (
  target: MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE | unknown,
): target is MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target ===
      MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED ||
    target === MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_USER ||
    target ===
      MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP ||
    target ===
      MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target ===
      MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};
export const isUnGroupUserListErrorType = (
  target: UNGROUP_USER_LIST_API_ERROR_TYPE | unknown,
): target is UNGROUP_USER_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER ||
    target === UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target === UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isGroupDeviceListErrorType = (
  target: MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE | unknown,
): target is MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_USER ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UPDATE_DENIED ||
    target === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.NO_INTERNET
  ) {
    return true;
  }

  return false;
};
export const isUnGroupDeviceListErrorType = (
  target: MANAGE_UNGROUP_DEVICE_LIST_API_ERROR_TYPE | unknown,
): target is MANAGE_UNGROUP_DEVICE_LIST_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target ===
      MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
    target === MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.NO_INTERNET ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED
  ) {
    return true;
  }

  return false;
};

export const isGroupDeviceListUpdateErrorType = (
  target: MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE | unknown,
): target is MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target ===
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED ||
    target ===
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED ||
    target === MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP ||
    target ===
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
    target ===
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target ===
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED ||
    target === MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が DEVICE_UPDATE_ERROR_TYPE かチェック
 * @param target DEVICE_UPDATE_ERROR_TYPE(端末情報取得エラータイプ)
 * @returns true=DEVICE_UPDATE_ERROR_TYPE型 / false=左記以外の型
 */
export const isDeviceUpdateErrorType = (
  target: DEVICE_UPDATE_ERROR_TYPE | unknown,
): target is DEVICE_UPDATE_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === DEVICE_UPDATE_API_RESULT_CODE.OK ||
    target === DEVICE_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === DEVICE_UPDATE_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === DEVICE_UPDATE_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === DEVICE_UPDATE_API_RESULT_CODE.INFO_INVALID ||
    target === DEVICE_UPDATE_API_RESULT_CODE.INFO_EXPIRED ||
    target === DEVICE_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.INVALID_TOKEN ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN ||
    target === DEVICE_UPDATE_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が TRANS_COUNT_LIST_ERROR_TYPE かチェック
 * @param target TRANS_COUNT_LIST_ERROR_TYPE
 * @returns true=TRANS_COUNT_LIST_ERROR_TYPE型 / false=左記以外の型
 */
export const isTransCountListErrorType = (
  target: TRANS_COUNT_LIST_ERROR_TYPE | unknown,
): target is TRANS_COUNT_LIST_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === TRANS_COUNT_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.INFO_INVALID ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.INFO_EXPIRED ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.INFO_DATA_OVER ||
    target === TRANS_COUNT_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が FROM_LANG_LIST_ERROR_TYPE かチェック
 * @param target FROM_LANG_LIST_ERROR_TYPE
 * @returns true=FROM_LANG_LIST_ERROR_TYPE / false=左記以外の型
 */
export const isFromLangListErrorType = (
  target: FROM_LANG_LIST_ERROR_TYPE | unknown,
): target is FROM_LANG_LIST_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === FROM_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === FROM_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === FROM_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === FROM_LANG_LIST_API_RESULT_CODE.INFO_INVALID ||
    target === FROM_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED ||
    target === FROM_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER ||
    target === FROM_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN ||
    target === FROM_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === FROM_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === FROM_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    target === FROM_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED || 
    target === FROM_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が TO_LANG_LIST_ERROR_TYPE かチェック
 * @param target TO_LANG_LIST_ERROR_TYPE
 * @returns true=TO_LANG_LIST_ERROR_TYPE型 / false=左記以外の型
 */
export const isToLangListErrorType = (
  target: TO_LANG_LIST_ERROR_TYPE | unknown,
): target is TO_LANG_LIST_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === TO_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === TO_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === TO_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === TO_LANG_LIST_API_RESULT_CODE.INFO_INVALID ||
    target === TO_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED ||
    target === TO_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER ||
    target === TO_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === TO_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN ||
    target === TO_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === TO_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    target === TO_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    target === TO_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が PW_REMINDER_SEND_MAIL_ERROR_TYPE かチェック
 * @param target PW_REMINDER_SEND_MAIL_ERROR_TYPE(ログインエラータイプ)
 * @returns true=PW_REMINDER_SEND_MAIL_ERROR_TYPE / false=左記以外の型
 */
export const isReminderSendMailErrorType = (
  target: PW_REMINDER_SEND_MAIL_ERROR_TYPE | unknown,
): target is PW_REMINDER_SEND_MAIL_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === PW_REMINDER_SEND_MAIL_API_RESULT_CODE.SUCCESSFULLY_CREATED ||
    target === PW_REMINDER_SEND_MAIL_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === PW_REMINDER_SEND_MAIL_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が PASSWORD_RESET_ERROR_TYPE かチェック
 * @param target PASSWORD_RESET_ERROR_TYPE(パスワードリセットエラータイプ)
 * @returns true=PASSWORD_RESET_ERROR_TYPE型 / false=左記以外の型
 */
export const isPasswordResetErrorType = (
  target: PASSWORD_RESET_ERROR_TYPE | unknown,
): target is PASSWORD_RESET_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === PASSWORD_RESET_API_RESULT_CODE.OK_CREATED ||
    target === PASSWORD_RESET_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === PASSWORD_RESET_API_RESULT_CODE.INFO_EXPIRED ||
    target === PASSWORD_RESET_API_RESULT_CODE.INFO_INVALID ||
    target === PASSWORD_RESET_API_RESULT_CODE.OLD_AND_NEW_PASSWORD_SAME ||
    target === PASSWORD_RESET_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が FETCH_SIM_INFO_ERROR_TYPE かチェック
 *
 * @param target FETCH_SIM_INFO_ERROR_TYPE
 * @returns true=FETCH_SIM_INFO_ERROR_TYPE 型 / false=左記以外の型
 */
export const isFetchSimInfoErrorType = (
  target: FETCH_SIM_INFO_ERROR_TYPE | unknown,
): target is FETCH_SIM_INFO_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === FETCH_SIM_INFO_API_RESULT_CODE.OK ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.WARN_INVALID_TYPE ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.WARN_ICCID_REGISTERD ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOT_FOUND ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOT_FOUND_DB ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.INFO_INVALID ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.INFO_EXPIRED ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.ERR_AUTH_FAILED ||
    target === FETCH_SIM_INFO_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が SETTING_INFO_ERROR_TYPE かチェック
 *
 * @param target SETTING_INFO_ERROR_TYPE
 * @returns true=SETTING_INFO_ERROR_TYPE 型 / false=左記以外の型
 */
export const isSettingInfoErrorType = (
  target: SETTING_INFO_ERROR_TYPE | unknown,
): target is SETTING_INFO_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === SETTING_INFO_API_RESULT_CODE.OK ||
    target === SETTING_INFO_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === SETTING_INFO_API_RESULT_CODE.INFO_INVALID ||
    target === SETTING_INFO_API_RESULT_CODE.INFO_EXPIRED ||
    target === SETTING_INFO_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が SETTING_UPDATE_ERROR_TYPE かチェック
 *
 * @param target SETTING_UPDATE_ERROR_TYPE
 * @returns true=SETTING_UPDATE_ERROR_TYPE 型 / false=左記以外の型
 */
export const isSettingUpdateErrorType = (
  target: SETTING_UPDATE_ERROR_TYPE | unknown,
): target is SETTING_UPDATE_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === SETTING_UPDATE_API_RESULT_CODE.OK ||
    target === SETTING_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === SETTING_UPDATE_API_RESULT_CODE.WARN_NO_SETTING ||
    target === SETTING_UPDATE_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === SETTING_UPDATE_API_RESULT_CODE.INFO_INVALID ||
    target === SETTING_UPDATE_API_RESULT_CODE.INFO_EXPIRED ||
    target === SETTING_UPDATE_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が DEVICE_DEREGISTER_ERROR_TYPE かチェック
 * @param target DEVICE_DEREGISTER_ERROR_TYPE(端末登録解除エラータイプ)
 * @returns true=DEVICE_DEREGISTER_ERROR_TYPE型 / false=左記以外の型
 */
export const isDeviceDeregisterErrorType = (
  target: DEVICE_DEREGISTER_ERROR_TYPE | unknown,
): target is DEVICE_DEREGISTER_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === DEVICE_DEREGISTER_API_RESULT_CODE.OK ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.INFO_ALREADY_DEREGISTER ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.INFO_INVALID ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.INFO_EXPIRED ||
    target === DEVICE_DEREGISTER_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が TRANS_HISTORY_LIST_ERROR_TYPE かチェック
 * @param target TRANS_HISTORY_LIST_ERROR_TYPE(翻訳履歴取得エラータイプ)
 * @returns true=TRANS_HISTORY_LIST_ERROR_TYPE型 / false=左記以外の型
 */
export const isTransHistoryListErrorType = (
  target: TRANS_HISTORY_LIST_ERROR_TYPE | unknown,
): target is TRANS_HISTORY_LIST_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.OK ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NO_DEVICE ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_PERIOD_CONTRADICTION ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_INVALID ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_EXPIRED ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED ||
    target === TRANS_HISTORY_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
  ) {
    return true;
  }

  return false;
};

/**
 * 指定されたオブジェクトの型が USER_LIST_API_ERROR_TYPE かチェック
 * @param target USER_LIST_API_ERROR_TYPE(ユーザー一覧取得エラータイプ)
 * @returns true=USER_LIST_API_ERROR_TYPE型 / false=左記以外の型
 */
// export const isUserListErrorType = (
//   target: USER_LIST_API_ERROR_TYPE | unknown,
// ): target is USER_LIST_API_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }
//   if (
//     target === USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
//     target === USER_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
//     target === USER_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === USER_LIST_API_RESULT_CODE.INFO_INVALID ||
//     target === USER_LIST_API_RESULT_CODE.INFO_EXPIRED ||
//     target === USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
//     target === USER_LIST_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が AUTH_LIST_API_ERROR_TYPE かチェック
 * @param target AUTH_LIST_API_ERROR_TYPE(ユーザー一覧取得エラータイプ)
 * @returns true=AUTH_LIST_API_ERROR_TYPE型 / false=左記以外の型
 */
// export const isAuthListErrorType = (
//   target: AUTH_LIST_API_ERROR_TYPE | unknown,
// ): target is AUTH_LIST_API_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }
//   if (
//     target === AUTH_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
//     target === AUTH_LIST_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === AUTH_LIST_API_RESULT_CODE.INFO_INVALID ||
//     target === AUTH_LIST_API_RESULT_CODE.INFO_EXPIRED ||
//     target === AUTH_LIST_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が USER_INFO_ERROR_TYPE かチェック
 * @param target USER_INFO_ERROR_TYPE(ユーザ取得エラータイプ)
 * @returns true=USER_INFO_ERROR_TYPE / false=左記以外の型
 */
// export const isUserInfoErrorType = (
//   target: USER_INFO_ERROR_TYPE | unknown,
// ): target is USER_INFO_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }

//   if (
//     target === USER_INFO_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
//     target === USER_INFO_API_RESULT_CODE.WARN_INPUT_PARAM ||
//     target === USER_INFO_API_RESULT_CODE.INFO_NO_USER ||
//     target === USER_INFO_API_RESULT_CODE.WARN_NO_AUTH_LIST ||
//     target === USER_INFO_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === USER_INFO_API_RESULT_CODE.INFO_INVALID ||
//     target === USER_INFO_API_RESULT_CODE.INFO_EXPIRED ||
//     target === USER_INFO_API_RESULT_CODE.WARN_INVALID_AUTH ||
//     target === USER_INFO_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が USER_UPDATE_ERROR_TYPE かチェック
 * @param target USER_UPDATE_ERROR_TYPE(ユーザ更新エラータイプ)
 * @returns true=USER_UPDATE_ERROR_TYPE / false=左記以外の型
 */
// export const isUserUpdateErrorType = (
//   target: USER_UPDATE_ERROR_TYPE | unknown,
// ): target is USER_UPDATE_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }

//   if (
//     target === USER_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED ||
//     target === USER_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_NO_USER ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_INVALID_AUTH ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_UPDATE_AUTH_OWN ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_INVALID ||
//     target === USER_UPDATE_API_RESULT_CODE.INFO_EXPIRED ||
//     target === USER_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH ||
//     target === USER_UPDATE_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が USER_PREADD_API_ERROR_TYPE かチェック
 * @param target USER_PREADD_API_ERROR_TYPE(ユーザ仮登録取得エラータイプ)
 * @returns true=USER_PREADD_API_ERROR_TYPE型 / false=左記以外の型
 */
// export const isUserPreAddErrorType = (
//   target: USER_PREADD_API_ERROR_TYPE | unknown,
// ): target is USER_PREADD_API_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }
//   if (
//     target === USER_PREADD_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
//     target === USER_PREADD_API_RESULT_CODE.WARN_INPUT_PARAM ||
//     target === USER_PREADD_API_RESULT_CODE.INFO_ALREADY_REGISTED ||
//     target === USER_PREADD_API_RESULT_CODE.INFO_BOUNCE_EMAIL ||
//     target === USER_PREADD_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === USER_PREADD_API_RESULT_CODE.INFO_INVALID ||
//     target === USER_PREADD_API_RESULT_CODE.INFO_EXPIRED ||
//     target === USER_PREADD_API_RESULT_CODE.WARN_INVALID_AUTH ||
//     target === USER_PREADD_API_RESULT_CODE.ERR_SEND_EMAIL ||
//     target === USER_PREADD_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が USER_DELETE_ERROR_TYPE かチェック
 * @param target USER_DELETE_ERROR_TYPE(ユーザ更新エラータイプ)
 * @returns true=USER_DELETE_ERROR_TYPE / false=左記以外の型
 */
// export const isUserDeleteErrorType = (
//   target: USER_DELETE_ERROR_TYPE | unknown,
// ): target is USER_DELETE_ERROR_TYPE => {
//   if (!target) {
//     return false;
//   }

//   if (
//     target === USER_DELETE_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
//     target === USER_DELETE_API_RESULT_CODE.WARN_INPUT_PARAM ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_NO_USER ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_FAILED_DELETE ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_DELETE_OWN ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_NOTHING_KEY ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_INVALID ||
//     target === USER_DELETE_API_RESULT_CODE.INFO_EXPIRED ||
//     target === USER_DELETE_API_RESULT_CODE.WARN_INVALID_AUTH ||
//     target === USER_DELETE_API_RESULT_CODE.ERR_UNKNOWN
//   ) {
//     return true;
//   }

//   return false;
// };

/**
 * 指定されたオブジェクトの型が PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE かチェック
 * @param target PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE(ログインエラータイプ)
 * @returns true=PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE / false=左記以外の型
 */
export const isReminderSendMailAuthErrorType = (
  target: PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE | unknown,
): target is PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target ===
      PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.SUCCESSFULLY_CREATED ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.WARN_INPUT_PARAM ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.INFO_EXPIRED ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.INFO_INVALID ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isUpdatePersonalSettingsErrorType = (
  target: UPDATE_PERSONAL_SETTINGS_ERROR_TYPE | unknown,
): target is UPDATE_PERSONAL_SETTINGS_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === UPDATE_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_UPDATED ||
    target === UPDATE_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH ||
    target === UPDATE_PERSONAL_SETTINGS_RESULT_CODE.ERR_UNKNOWN
  ) {
    return true;
  }

  return false;
};

export const isFetchPersonalSettingsErrorType = (
  target: FETCH_PERSONAL_SETTINGS_ERROR_TYPE | unknown,
): target is FETCH_PERSONAL_SETTINGS_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === FETCH_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === FETCH_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH ||
    target === FETCH_PERSONAL_SETTINGS_RESULT_CODE.USER_ROLE_CHANGED ||
    target === FETCH_PERSONAL_SETTINGS_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.INVALID_TOKEN ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED
  ) {
    return true;
  }

  return false;
};

export const isGetCorporateDetailsErrorType = (
  target: CORPORATE_DETAILS_API_ERROR_TYPE | unknown,
): target is CORPORATE_DETAILS_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_FETCHED ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.INFO_NO_CORPORATION_DETAIL ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.CORP_DETAIL_NOT_FOUND ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.INFO_INVALID ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.INFO_EXPIRED ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
    target === CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN ||
    target === SERVER_MESSAGE.INVALID_TOKEN
  ) {
    return true;
  }

  return false;
};

export const isUpdateCorporateDetailsErrorType = (
  target: UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE | unknown,
): target is UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE => {
  if (!target) {
    return false;
  }
  if (
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_UPDATED ||
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY ||
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH ||
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.USER_IS_NOT_ADMIN ||
    target === UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN ||
    target === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
    target === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
    target === SERVER_MESSAGE.USER_ROLE_CHANGED ||
    target === SERVER_MESSAGE.EXPIRED_TOKEN ||
    target === SERVER_MESSAGE.INVALID_TOKEN
  ) {
    return true;
  }

  return false;
};
