/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  ClickAwayListener,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useStyles } from '../Templates/CommonStyle';
import type { PasswordResetSendMailInput } from '../../types/inputs/passwordResetSendMailInput';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { EMAIL_CHECK_PATTERN } from '../../constants/constants';
import Loader from '../Atoms/Loader';

type Props = {
  title: string;
  btnText: string;
  onClickSendMail: (inputData: PasswordResetSendMailInput) => void;
  isCallingApi: boolean;
  onClickGoBack: () => void;
};

const EmailSendCardPasswordReset = React.memo<Props>(
  ({
    title,
    btnText,
    onClickSendMail,
    isCallingApi,
    onClickGoBack,
  }): JSX.Element => {
    // common styles
    const classes = useStyles();
    const { t } = useSwitchLocaleLanguage();
    const [isErrorRemove, setIsErrorRemove] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(false);

    const {
      handleSubmit,
      formState: { errors },
      control,
      watch,
      clearErrors,
    } = useForm<PasswordResetSendMailInput>({
      mode: 'onChange' || 'onSubmit',
      criteriaMode: 'all',
      shouldFocusError: false,
    });
    const { mailAddress } = watch();

    useEffect(() => {
      setIsErrorRemove(false);
    }, [mailAddress]);

    const handleClickAwaymailAddressField = () => {
      if (errors.mailAddress?.types?.pattern) {
        setIsErrorRemove(true);
      }
      clearErrors();
    };

    // mailAddress send btn disable
    useEffect(() => {
      if (
        errors.mailAddress?.types?.required ||
        errors.mailAddress?.types?.pattern ||
        errors.mailAddress?.types?.validate ||
        mailAddress?.length === undefined ||
        mailAddress?.trim().length === 0 ||
        isErrorRemove
      ) {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }, [mailAddress, errors.mailAddress, isErrorRemove]);

    const validateEmail = (value: string) => {
      const trimmedValue = value?.trim();
      if (trimmedValue?.length > 256) {
        return t('common.validationError.email.maxLength');
      }

      return true;
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Box className={classes.emailSendCard}>
          <Typography className={classes.emailSendCardTitle}>
            {title}
          </Typography>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onClickSendMail)}>
            <Box sx={{ display: 'flex', marginTop: '16px', height: '20px' }}>
              <Typography className={classes.loginCardLevel}>
                {t('emailSendCard.label.email')}
              </Typography>
              <Typography className={classes.loginCardLevelMarker}>
                *
              </Typography>
            </Box>
            <ClickAwayListener onClickAway={handleClickAwaymailAddressField}>
              <Box sx={{ position: 'relative' }}>
                <Controller
                  control={control}
                  name="mailAddress"
                  rules={{
                    required: true,
                    validate: validateEmail,
                    pattern: EMAIL_CHECK_PATTERN,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      value={field.value || ''}
                      fullWidth
                      margin="normal"
                      placeholder={t('emailSendCard.placeholder.email')}
                      error={Boolean(errors.mailAddress)}
                      inputProps={{ maxLength: 257 }}
                      autoFocus
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor:
                            errors.mailAddress?.types?.required ||
                            errors.mailAddress?.types?.pattern ||
                            errors.mailAddress?.types?.validate
                              ? '#E1341E !important'
                              : '#939598 !important',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '6px',
                          '&.Mui-focused fieldset': {
                            border:
                              errors.mailAddress?.types?.required ||
                              errors.mailAddress?.types?.pattern ||
                              errors.mailAddress?.types?.validate
                                ? '1px solid #E1341E !important'
                                : '1px solid #939598 !important',
                          },
                        },
                      }}
                      className={classes.loginFormInput}
                    />
                  )}
                />
                {(errors.mailAddress?.types?.required ||
                  errors.mailAddress?.types?.pattern ||
                  errors.mailAddress?.types?.validate) && (
                  <Box className={classes.errorCard}>
                    <p style={{ padding: '0', margin: '0' }}>
                      {(errors.mailAddress?.types?.required &&
                        t('common.validationError.email.empty')) ||
                        (errors.mailAddress?.types?.validate &&
                          t('common.validationError.email.maxLength')) ||
                        (errors.mailAddress?.types?.pattern &&
                          t('common.validationError.email.invalid'))}
                    </p>
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
            <Button
              sx={{ marginTop: '12px !important' }}
              className={classes.submitButtonLogin}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={isBtnDisable || isCallingApi}
            >
              {isCallingApi ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {btnText}
                  <Loader colorCode="#fff" />
                </Box>
              ) : (
                btnText
              )}
            </Button>
          </form>
          <Box className={classes.linkBtnBox}>
            <Typography className={classes.loginCardLevel}>
              {t('emailSendCard.label.alreadyAccount')}
            </Typography>
            <Button
              variant="text"
              className={classes.btnLink}
              onClick={onClickGoBack}
            >
              {t('emailSendCard.label.logIn')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  },
);

export default EmailSendCardPasswordReset;
