import React, {
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react';
import CountryList from 'country-list-with-dial-code-and-flag';
import { TFunction } from 'react-i18next';
import { CORPORATE_DETAILS_API_RESULT_CODE } from '../apis/callGetCorporateDetailsApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import {
  CorporateProfileReturnType,
  getCorporateProfile,
  updateCorporateProfile,
} from '../utils/corporateProfile/corporateProfile';
import {
  CorporateContact,
  SubscriptionInfoType,
  UpdateCorporateDetailsApiRequest,
  UpdateCorporateDetailsApiResponse,
} from '../types/apis/corporateDetailsApi.d';
import { UPDATE_CORPORATE_DETAILS_API_RESULT_CODE } from '../apis/callUpdateCorporateDetailsApi';
import useSnackbar from './useSnackbar';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { sendGAEvent } from './useGAEvent';

export type countryInfo = {
  dialCode: string;
  countryFlagCode: string;
};
export type UseCorporateProfile = {
  isSaveBtnEnabled: boolean;
  setIsSaveBtnEnabled: Dispatch<SetStateAction<boolean>>;
  onClickSaveBtn: (inputData: any) => void;
  // phone no
  handleSelectBoxClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSelectPhoneDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectCountryInfo: (info: countryInfo) => void;
  selectAnchor: HTMLElement | null;
  selectOpen: boolean;
  countryFlagCode: string;
  countryDialCode: string;
  // country name
  handleSelectCountryBoxClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSelectCountryDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectCountry: (info: any) => void;
  selectAnchorCountry: HTMLElement | null;
  selectOpenCountry: boolean;
  countryNameCode: string;
  countryName: string;
  // state name
  handleSelectStateBoxClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSelectStateDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectState: (info: string) => void;
  selectAnchorState: HTMLElement | null;
  selectOpenState: boolean;
  stateName: string;
  // card btn
  onClickSubscriptionBtn: () => void;
  onClickOrderHistoryBtn: () => void;
  onClickPaymentInfoBtn: () => void;
  // contactInfo
  contactInfo: CorporateContact;
  isCallingApi: boolean;
  isCallingUpdateApi: boolean;
  t: TFunction;
  subscriptionInfo: SubscriptionInfoType;
  phoneNumberRef: any;
};

const useCorporateProfile = (): UseCorporateProfile => {
  const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false);
  const phoneNumberRef = useRef<any>(null);
  const { displaySnackbar } = useSnackbar();
  const { accountInfo, saveCompany } = useAccountInfo();
  const { t } = useSwitchLocaleLanguage();

  // phone number select box click
  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSelectAnchor(event.currentTarget);
    if (selectOpen) {
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
  };
  // subscription info
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SubscriptionInfoType>({
      subscriptionLink: '',
      currentPlan: '',
      expiredDate: '',
      nextBillingDate: '',
      trialFlag: false,
    });
  // Select tag status toggling
  const handleSelectPhoneDialOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (selectOpen) {
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
  };
  const [countryFlagCode, setCountryFlagCode] = useState('JP');
  const [countryDialCode, setCountryDialCode] = useState('+81');
  // phone number select drowpdown click
  const handleSelectCountryInfo = (info: countryInfo) => {
    setSelectOpen(false);
    setCountryFlagCode(info.countryFlagCode);
    setCountryDialCode(info.dialCode);
    (phoneNumberRef.current as HTMLInputElement).focus();
  };

  // end phone number select box click

  // country select box
  const allCountry = CountryList.getAll();

  const [selectAnchorCountry, setSelectAnchorCountry] =
    useState<null | HTMLElement>(null);
  const [selectOpenCountry, setSelectOpenCountry] = useState<boolean>(false);
  const handleSelectCountryBoxClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    setSelectAnchorCountry(event.currentTarget);
    if (selectOpenCountry) {
      setSelectOpenCountry(false);
    } else {
      setSelectOpenCountry(true);
    }
  };
  const handleSelectCountryDialOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (selectOpenCountry) {
      setSelectOpenCountry(false);
    } else {
      setSelectOpenCountry(true);
    }
  };
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [countryNameCode, setCountryNameCode] = useState('');
  const [countryName, setCountryName] = useState('');
  // country select drowpdown click
  const handleSelectCountry = (info: any) => {
    setSelectOpenCountry(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setCountryNameCode(info.countryCode as string);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setCountryName(info.countryName as string);
    setIsCountryChange(true);
  };
  // end country select box

  // state select box
  const [selectAnchorState, setSelectAnchorState] =
    useState<null | HTMLElement>(null);
  const [selectOpenState, setSelectOpenState] = useState<boolean>(false);
  const handleSelectStateBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSelectAnchorState(event.currentTarget);
    if (selectOpenState) {
      setSelectOpenState(false);
    } else {
      setSelectOpenState(true);
    }
  };
  const handleSelectStateDialOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (selectOpenState) {
      setSelectOpenState(false);
    } else {
      setSelectOpenState(true);
    }
  };

  const [stateName, setStateName] = useState('');
  // State select drowpdown click
  const handleSelectState = (info: string) => {
    setSelectOpenState(false);
    setStateName(info);
  };
  // end state select box
  // urls object
  const [urlList, setUrlList] = useState<{
    subscriptionUrl: string;
    orderHistoryUrl: string;
    paymentInfoUrl: string;
  }>({
    subscriptionUrl: '',
    orderHistoryUrl: '',
    paymentInfoUrl: '',
  });
  const onClickSubscriptionBtn = () => {
    window.open(urlList.subscriptionUrl, '_blank', 'noreferrer');
  };
  const onClickOrderHistoryBtn = () => {
    window.open(urlList.orderHistoryUrl, '_blank', 'noreferrer');

    // GA tags
    sendGAEvent('press_btn', 'link_name', 'order_history_link');
    sendGAEvent('press_btn', 'order_history_link', accountInfo.corpId);
  };
  const onClickPaymentInfoBtn = () => {
    window.open(urlList.paymentInfoUrl, '_blank', 'noreferrer');

    // GA tags
    sendGAEvent('press_btn', 'link_name', 'payment_info_link');
    sendGAEvent('press_btn', 'payment_info_link', accountInfo.corpId);
  };

  const [contactInfo, setContactInfo] = useState<CorporateContact>({
    corpId: '',
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    address: '',
    country: '',
    countryCode: '',
    countryFlag: '',
    state: '',
    zipCode: '',
    corporateName: '',
  });

  // start
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);

  const getCorporateDetails = () => {
    setIsCallingApi(true);
    /*eslint-disable*/
    getCorporateProfile({ corpId: accountInfo.corpId })
      .then((result: CorporateProfileReturnType) => {
        switch (result.message) {
          case CORPORATE_DETAILS_API_RESULT_CODE.CORP_DETAIL_NOT_FOUND: {
            displaySnackbar({
              message: t('corporateProfile.message.noCorporate'),
              type: 'error',
            });
            break;
          }
          case CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_FETCHED: {
            let tempCountryFlag = 'JP';
            if (result.details.corporateContactResponse.countryFlag) {
              tempCountryFlag =
                result.details.corporateContactResponse.countryFlag;
            } else if (result.details.corporateContactResponse.countryCode) {
              const countryCodeValue = allCountry.filter((country) => {
                return (
                  country.dial_code ===
                  result.details.corporateContactResponse.countryCode
                );
              });
              if (countryCodeValue.length) {
                tempCountryFlag = countryCodeValue[0].code;
              }
            }
            setCountryFlagCode(tempCountryFlag);
            saveCompany(result?.details?.corporateInfoResponse.corporateName);
            setContactInfo({
              // ...result.details.corporateContactResponse,
              corpId: result.details.corporateContactResponse.corpId
                ? result.details.corporateContactResponse.corpId
                : '',
              firstName: result.details.corporateContactResponse.firstName
                ? result.details.corporateContactResponse.firstName
                : '',
              lastName: result.details.corporateContactResponse.lastName
                ? result.details.corporateContactResponse.lastName
                : '',
              email: result.details.corporateContactResponse.email
                ? result.details.corporateContactResponse.email
                : '',
              tel: result.details.corporateContactResponse.tel
                ? result.details.corporateContactResponse.tel
                : '',
              address: result.details.corporateContactResponse.address
                ? result.details.corporateContactResponse.address
                : '',
              country: result.details.corporateContactResponse.country
                ? result.details.corporateContactResponse.country
                : '',
              countryFlag: tempCountryFlag,
              countryCode: result.details.corporateContactResponse.countryCode
                ? result.details.corporateContactResponse.countryCode
                : '+81',
              state: result.details.corporateContactResponse.state
                ? result.details.corporateContactResponse.state
                : '',
              zipCode: result.details.corporateContactResponse.zipCode
                ? result.details.corporateContactResponse.zipCode
                : '',
              corporateName: result.details.corporateInfoResponse.corporateName,
            });

            setCountryName(
              result.details.corporateContactResponse.country
                ? result.details.corporateContactResponse.country
                : '',
            );
            if (result.details.corporateContactResponse.country) {
              const countryNameCodeValue = allCountry.filter((country) => {
                return (
                  country.name ===
                  result.details.corporateContactResponse.country
                );
              });
              if (countryNameCodeValue.length) {
                setCountryNameCode(countryNameCodeValue[0].code);
              }
            }
            setStateName(
              result.details.corporateContactResponse.state
                ? result.details.corporateContactResponse.state
                : '',
            );

            if (result.details.corporateContactResponse.countryCode) {
              setCountryDialCode(
                result.details.corporateContactResponse.countryCode,
              );
            }
            // setting subscription info
            setSubscriptionInfo(result.details?.subscriptionInfoResponse);

            // setting URLs
            const tempUrl = {
              subscriptionUrl: result.details.subscriptionInfoResponse
                .subscriptionLink
                ? result.details.subscriptionInfoResponse.subscriptionLink
                : '',
              orderHistoryUrl: result.details.orderInfoResponse.orderHistoryLink
                ? result.details.orderInfoResponse.orderHistoryLink
                : '',
              paymentInfoUrl: result.details.paymentInfoResponse.paymentInfoLink
                ? result.details.paymentInfoResponse.paymentInfoLink
                : '',
            };
            setUrlList(tempUrl);
            break;
          }
          case SERVER_MESSAGE.USER_CONSENT_REQUIRED: {
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default:
            throw result.message;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'Error details');
        displaySnackbar({
          message: t('common.error.serverErr'),
          type: 'error',
        });
        setIsCallingApi(false);
      })
      .finally(() => {
        setIsCallingApi(false);
      });
  };

  useEffect(() => {
    getCorporateDetails();
  }, []);

  const updateCorporateDetails = (
    requestPayload: UpdateCorporateDetailsApiRequest,
  ) => {
    setIsCallingUpdateApi(true);
    setIsCallingApi(true);

    /*eslint-disable*/
    updateCorporateProfile(requestPayload)
      .then((result: UpdateCorporateDetailsApiResponse) => {
        switch (result.message) {
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_UPDATED: {
            setIsSaveBtnEnabled(false);
            getCorporateDetails();
            // success message
            displaySnackbar({
              message: t('corporateProfile.message.updateSuccess'),
            });

            break;
          }
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED: {
            displaySnackbar({
              message: t('corporateProfile.message.changeNotAllowed'),
              type: 'error',
            });
            break;
          }
          case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.USER_IS_NOT_ADMIN: {
            displaySnackbar({
              message: t('corporateProfile.message.adminEmail'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default:
            throw result.message;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        displaySnackbar({
          message: t('corporateProfile.message.updateFailed'),
          type: 'error',
        });
        setIsCallingApi(false);
      })
      .finally(() => {
        setIsCallingUpdateApi(false);
        setIsCallingApi(false);
      });
  };

  // handle save btn
  const onClickSaveBtn = (inputData: {
    address: string;
    corporateName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    zipCode: string;
  }) => {
    const temp = {
      corpId: accountInfo.corpId,
      corporateName: inputData.corporateName
        ? inputData.corporateName.trim()
        : '',
      firstName: inputData.firstName ? inputData.firstName.trim() : '',
      lastName: inputData.lastName ? inputData.lastName.trim() : '',
      email: inputData.email ? inputData.email.trim() : '',
      country: countryName,
      countryCode: countryDialCode,
      countryFlag: countryFlagCode,
      state: stateName,
      zipCode: inputData.zipCode ? inputData.zipCode.trim() : '',
      address: inputData.address ? inputData.address.trim() : '',
      tel: inputData.phoneNumber ? inputData.phoneNumber.trim() : '',
    };

    updateCorporateDetails(temp);

    // GA tags
    sendGAEvent('press_btn', 'btn_name', 'save');
    sendGAEvent('press_btn', 'save_value', accountInfo.corpId);
    sendGAEvent('press_btn', 'input_corporate_name_value', temp.corporateName);
    sendGAEvent('press_btn', 'input_first_name_value', temp.firstName);
    sendGAEvent('press_btn', 'input_last_name_value', temp.lastName);
    sendGAEvent('press_btn', 'input_contract_email_value', temp.email);
    sendGAEvent('press_btn', 'input_corporate_tel_value', temp.tel);
    sendGAEvent('press_btn', 'input_corporate_address_value', temp.address);
    sendGAEvent('press_btn', 'input_corporate_country_value', temp.country);
    sendGAEvent('press_btn', 'input_corporate_state_value', temp.state);
    sendGAEvent('press_btn', 'input_zip_code_value', temp.zipCode);
  };

  useEffect(() => {
    if (isCountryChange) {
      setStateName('');
    }
  }, [countryNameCode]);

  return {
    isSaveBtnEnabled,
    setIsSaveBtnEnabled,
    onClickSaveBtn,
    // phone no
    handleSelectBoxClick,
    handleSelectPhoneDialOpen,
    selectAnchor,
    selectOpen,
    handleSelectCountryInfo,
    countryFlagCode,
    countryDialCode,
    // country name
    handleSelectCountryBoxClick,
    handleSelectCountryDialOpen,
    handleSelectCountry,
    selectAnchorCountry,
    selectOpenCountry,
    countryNameCode,
    countryName,
    // state name
    handleSelectStateBoxClick,
    handleSelectStateDialOpen,
    handleSelectState,
    selectAnchorState,
    selectOpenState,
    stateName,
    // card btn
    onClickSubscriptionBtn,
    onClickOrderHistoryBtn,
    onClickPaymentInfoBtn,
    // contactInfo
    contactInfo,
    isCallingApi,
    isCallingUpdateApi,
    t,
    subscriptionInfo,
    phoneNumberRef,
  };
};

export default useCorporateProfile;
