/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import type {
  FromLangListApiParameter,
  FromLangListApiResponse,
} from '../../types/apis/fromLangListApi';
import callFromLangListApi, {
  FROM_LANG_LIST_API_RESULT_CODE,
  FROM_LANG_LIST_ERROR_TYPE,
} from '../../apis/callFromLangListApi';
import {
  TableHeaderData,
  FROM_LANG_TABLE_DATA_KEY,
} from '../../types/datatable/dataTable.d';
import { isFromLangListErrorType } from '../../types/apis/apiErrorTypeGuard';
import type {
  FromLangListResult,
  REPORT_VIEW_TYPE,
} from '../../types/report/fromLangList';
import { convertStringArrayToTableHeaderColumns } from '../datatable/dataTableUtil';

/**
 * 翻訳元言語画面に依存する汎用クラス
 */

/**
 * 翻訳元言語一覧取得APIにリクエスト送信して、一覧を取得
 *
 * @param accessKey アクセスキー
 * @param corpId 法人ID
 * @param from 期間開始日
 * @param to 期間終了日
 * @param view ビュータイプ
 * @returns
 */
export const fetchFromLangList = async (
  corpId: string,
  from: string,
  to: string,
  view: string,
): Promise<FromLangListResult> => {
  // リクエストパラメータ
  const requestParam: FromLangListApiParameter = {
    corpId,
    from,
    to,
    view,
  };
  try {
    // 翻訳元言語取得API呼び出し
    const apiResponse: FromLangListApiResponse = await callFromLangListApi(
      requestParam,
    );
    if (
      apiResponse.message ===
      FROM_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
    ) {
      // テーブルのヘッダー作成（APIから返却された「日・週・月別の利用回数列のヘッダ」も表示）
      const tableHeader: TableHeaderData[] =
        convertStringArrayToTableHeaderColumns(
          apiResponse.details.titleList,
          FROM_LANG_TABLE_DATA_KEY.COUNT_LIST,
          view,
        );

      return {
        resultCode: FROM_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
        fromLangInfos: apiResponse.details.data,
        tableHeader,
        // tslint-disable
        viewType: apiResponse.details.viewType as REPORT_VIEW_TYPE,
      };
    }
    // 翻訳元言語の取得に失敗
    switch (apiResponse.message) {
      case FROM_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER:
        throw FROM_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER;
      case FROM_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw FROM_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case FROM_LANG_LIST_API_RESULT_CODE.INFO_INVALID:
        throw FROM_LANG_LIST_API_RESULT_CODE.INFO_INVALID;
      case FROM_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw FROM_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED;
      case FROM_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN:
        throw FROM_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN;
      case FROM_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw FROM_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      case FROM_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
        throw FROM_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED;
      case FROM_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
        throw FROM_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED;
      case FROM_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw FROM_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED;
      default:
        throw FROM_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err?.message === FROM_LANG_LIST_API_RESULT_CODE.NO_INTERNET) {
      const message: FROM_LANG_LIST_ERROR_TYPE =
        err.message as FROM_LANG_LIST_ERROR_TYPE;

      return {
        resultCode: message,
        fromLangInfos: null,
        tableHeader: [],
        viewType: '',
      };
    }
    let resultCode: FROM_LANG_LIST_ERROR_TYPE =
      FROM_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isFromLangListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      fromLangInfos: null,
      tableHeader: [],
      viewType: '',
    };
  }
};
