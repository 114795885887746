import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type { LogOutApiResponse } from '../types/apis/logoutApi';

/**
 * ユーザ削除API 結果コード
 */
export const LOGOUT_API_RESULT_CODE = {
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
} as const;

/**
 * Logout error type
 */
export type LOGOUT_ERROR_TYPE =
  typeof LOGOUT_API_RESULT_CODE[keyof typeof LOGOUT_API_RESULT_CODE];

const callLogOutApi = (): Promise<LogOutApiResponse> => {
  // リクエスト送信(POST)
  const response = callApiUtil
    .delete<LogOutApiResponse>(API_URL.LOGOUT_API)
    .then((apiResponse) => apiResponse)
    /* eslint-disable */
    .catch((error) => error.response);

  return response;
};

export default callLogOutApi;
