import type {
  EulaUpdateApiRequestParameter,
  EulaUpdateApiResponse,
} from '../types/apis/eulaUpdateApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * Eula Update Api
 *
 * @param requestParams request parameter
 * @returns
 */
const callEulaUpdateApi = (
  requestParams: EulaUpdateApiRequestParameter,
): Promise<EulaUpdateApiResponse> => {
  // Request parameter
  const jsonParams = {
    // eslint-disable
    privacyPolicyId: requestParams.privacyPolicyId,
    termsOfUseId: requestParams.termsOfUseId,
    // eslint-enable
  };
  // (POST)
  /*eslint-disable*/
  const response = callApiUtil
    .post<EulaUpdateApiResponse>(API_URL.EULA_UPDATE_API, jsonParams, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callEulaUpdateApi;
