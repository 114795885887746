import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AUTH_LIST_API_ERROR_TYPE,
  AUTH_LIST_API_RESULT_CODE,
} from '../apis/callAuthListApi';
// import {
//   USER_PREADD_API_ERROR_TYPE,
//   USER_PREADD_API_RESULT_CODE,
// } from '../apis/callUserPreAddApi';
import type { AuthInfo } from '../types/apis/authListApi';
import type { UserAddInput } from '../types/inputs/userAddInput';
import type { AuthListResult, UserPreAddResult } from '../types/user/userAdd';
import {
  USER_LIST_TRANS_METHOD_CODE,
  USER_INFO_PROSESS_RESULT_CODE,
} from '../types/user/userList.d';
import {
  fetchAuthList,
  sendMail,
  transUserList,
} from '../utils/user/userAddUtil';
// import useAccessKey from './useAccessKey';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import useSnackbar from './useSnackbar';
import { SERVER_MESSAGE, ALL_ERROR_TYPE } from '../constants/constants';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import { sendGAEvent } from './useGAEvent';
import useAccountInfo from './useAccountInfo';
// import useUserList from './useUserList';
/**
 * ユーザー招待メール送信(ユーザー追加)に関するエラー情報
 */
export type UserAddError = {
  // ユーザー招待メール送信(ユーザー追加)エラータイプ
  userPreAddErrorType: ALL_ERROR_TYPE | null;
  // 権限一覧取得エラータイプ
  authListErrorType: AUTH_LIST_API_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
  onClickLogoutButton: () => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseUserAddValue = {
  // ユーザー追加(招待メール送信)に関するエラー情報
  userAddError: UserAddError;
  // APIコール中か否か(true=コール中)
  isCallingApi: boolean;
  isCallingUserAddApi: boolean;
  isAPISuccess: boolean;
  // 権限一覧
  authList: AuthInfo[];
  // [戻る]ボタンがクリックされた場合の処理
  onClickBackButton: () => void;
  // [送信]ボタンがクリックされた場合の処理
  onClickSendButton: (inpudData: UserAddInput) => void;
};

/**
 * ユーザー招待メール送信(ユーザー追加)画面 hooks
 *
 * @returns
 */
const useUserAdd = (): UseUserAddValue => {
  const { accountInfo } = useAccountInfo();
  // 権限一覧
  const [authList, setAuthList] = useState<AuthInfo[]>([]);
  const [isAPISuccess, setIsAPISuccess] = useState<boolean>(false);

  const { displaySnackbar } = useSnackbar();

  // ユーザー追加(招待メール送信)エラータイプ get/set
  const [userPreAddErrorType, setUserPreAddErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  // 権限一覧取得エラータイプ get/set
  const [authListErrorType, setAuthListErrorType] =
    useState<AUTH_LIST_API_ERROR_TYPE | null>(null);
  // APIコール中か否か(true=コール中)
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingUserAddApi, setIsCallingUserAddApi] =
    useState<boolean>(false);

  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  // navigate(画面遷移)
  const navigate = useNavigate();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();
  // const { setDisplayContentState, displayContentState } = useUserList();
  /**
   * エラーメッセージ
   * 定義した以外は空文字を返す
   */
  const errorMessage = useMemo((): string => {
    if (!authListErrorType && !userPreAddErrorType) {
      return ''; // エラーコンポーネント自体非表示にする
    }

    // Permanent acquisition error
    if (authListErrorType) {
      switch (authListErrorType) {
        case SERVER_MESSAGE.AUTH_DATA_NOT_FOUND:
        case SERVER_MESSAGE.INVALID_TOKEN:
        case SERVER_MESSAGE.EXPIRED_TOKEN:
          return ''; // For login authentication, there is no message because the error is an error dialog
        default:
          return t('userAdd.authListApiError');
      }
    }
    // Sending email (user registration) Failure
    if (userPreAddErrorType) {
      switch (userPreAddErrorType) {
        case SERVER_MESSAGE.WARN_INPUT_PARAM:
          return t('userAdd.userPreAddApiError.warnInputParam');
        case SERVER_MESSAGE.USER_ALREADY_REGISTERED:
          return t('userAdd.userPreAddApiError.alreadyRegisted');
        case SERVER_MESSAGE.AUTH_DATA_NOT_FOUND:
        case SERVER_MESSAGE.INVALID_TOKEN:
        case SERVER_MESSAGE.EXPIRED_TOKEN:
          return ''; // Login authentication, authority fraud error has no message because it issues error dialog
        default:
          return t('userAdd.userPreAddApiError.unknown');
      }
    }

    return '';
  }, [authListErrorType, userPreAddErrorType, t]);

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutButton = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setUserPreAddErrorType(null);
    setAuthListErrorType(null);
  };

  /**
   * [戻る]ボタンがクリックされた場合の処理
   */
  const onClickBackButton = (): void => {
    // [ユーザー管理]画面に戻る
    transUserList(
      navigate,
      USER_LIST_TRANS_METHOD_CODE.BACK_BUTTON,
      USER_INFO_PROSESS_RESULT_CODE.CANCEL,
    );
  };

  /**
   * [送信]ボタンがクリックされた場合の処理
   *
   * @param inpudData 入力内容」
   */
  const onClickSendButton = (inpudData: UserAddInput): void => {
    // loadingBar表示
    setIsCallingApi(true);
    setIsCallingUserAddApi(true);
    // エラータイプ初期化
    setUserPreAddErrorType(null);
    // メール送信
    sendMail(inpudData.email.trim(), inpudData.userName, inpudData.roleCode)
      .then((result: UserPreAddResult) => {
        // loadingBar非表示
        setIsCallingApi(false);
        setIsCallingUserAddApi(false);

        // 成功
        if (result.message === SERVER_MESSAGE.CREATE_OK) {
          // [ユーザー管理]画面に遷移(成功メッセージ渡す)
          transUserList(
            navigate,
            USER_LIST_TRANS_METHOD_CODE.SEND_BUTTON,
            USER_INFO_PROSESS_RESULT_CODE.SEND_MAIL_SUCCESS,
          );
          setIsAPISuccess(true);
          displaySnackbar({
            message: t('userAdd.message.mailSentSuccess'),
          });
          // setDisplayContentState({
          //   ...displayContentState,
          //   apiCallState: 'pending',
          // });

          return;
        }
        // 失敗
        setUserPreAddErrorType(result.message);
        setIsAPISuccess(false);
        if (
          result.message === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          result.message === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          result.message === SERVER_MESSAGE.INVALID_TOKEN ||
          result.message === SERVER_MESSAGE.EXPIRED_TOKEN
        ) {
          return;
        }
        // console.log(result.message, 'result.message');
        if (result.message === SERVER_MESSAGE.USER_ALREADY_REGISTERED) {
          displaySnackbar({
            message: t('userAdd.errors.alreadyRegistered'),
            type: 'error',
          });
        } else if (result.message === SERVER_MESSAGE.INFO_ALREADY_INVITED) {
          displaySnackbar({
            message: t('userAdd.errors.alreadyInvited'),
            type: 'error',
            timeout: 3001,
          });
        } else {
          displaySnackbar({
            message: t('userAdd.errors.regMailSentFailure'),
            type: 'error',
          });
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setUserPreAddErrorType(error);
          displaySnackbar({
            message: t('userAdd.errors.regMailSentFailure'),
            type: 'error',
          });
        } else {
          setUserPreAddErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
          displaySnackbar({
            message: t('common.error.serverErr'),
            type: 'error',
          });
        }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingApi(false);
        setIsCallingUserAddApi(false);
      });

    // GA tag
    sendGAEvent('edit_text_char', 'txt_name', 'input_email_address');
    sendGAEvent('edit_text_char', 'input_email_address_value', inpudData.email);
    sendGAEvent('edit_text_char', 'txt_name', 'input_name');
    sendGAEvent('edit_text_char', 'input_email_name_value', inpudData.userName);
    sendGAEvent('press_btn', 'btn_name', 'send');
  };

  /**
   * 権限一覧取得処理
   *
   * @returns
   */
  const authListProc = (): void => {
    // loadingBar表示
    setIsCallingApi(true);
    // エラータイプ初期化
    setAuthListErrorType(null);

    // 権限一覧取得
    fetchAuthList()
      .then((result: AuthListResult) => {
        // 成功
        if (result.message === AUTH_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED) {
          setAuthList(result.details);

          return;
        }

        // 失敗
        setAuthList([]);
        setAuthListErrorType(result.message);
      })
      .finally(() => {
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  /**
   * 画面表示処理
   */
  useEffect(() => {
    if (!allowTransition()) {
      return;
    }
    // 権限一覧取得処理
    authListProc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.locale]);

  return {
    userAddError: {
      userPreAddErrorType,
      authListErrorType,
      errorMessage,
      onClickLogoutButton,
    },
    isCallingApi,
    isCallingUserAddApi,
    isAPISuccess,
    authList,
    onClickBackButton,
    onClickSendButton,
  };
};

export default useUserAdd;
