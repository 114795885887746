import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// import { unwrapResult } from '@reduxjs/toolkit';
import { TFunction } from 'react-i18next';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
// import useCheckTransition from './useCheckTransition';
import callRefreshTokenApi from '../apis/callRefreshTokenApi';
import {
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  ALL_ERROR_TYPE,
} from '../constants/constants';
import { AppDispatch, useAppDispatch } from '../state/store';
import useSnackbar from './useSnackbar';
import { accessKeySlice } from '../state/slice/accessKeySlice';
import callApiUtil from '../utils/callApiUtil';
import { encryptText } from '../utils/utility';

export type UseLoginValue = {
  // ［ログイン］ボタンクリック時処理
  onClickRefresh: (setIsCalling: (value: boolean) => void) => void;
  // エラータイプ
  errorType: ALL_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // APIコール中か否か
  isCallingApi: boolean;
  // [パスワードを忘れた]リンク押下時処理
  // onClickPasswordReminder: () => void;
  // onClickRegisterBtn: () => void;
};

const useRefresh = (): UseLoginValue => {
  // エラータイプ get/set
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  // navigate(画面遷移)
  const navigate = useNavigate();
  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  const date = new Date();
  date.setTime(date.getTime() + 24 * 3600 * 1000); // 24 Hours

  const dispatch: AppDispatch = useAppDispatch();
  const { displaySnackbar } = useSnackbar();
  const { updateToken } = accessKeySlice.actions;
  // ログインAPIから返却されたアカウント情報保存hooks
  const {
    saveLocale,
    saveEmail,
    saveCorpId,
    saveCompany,
    saveCorpCreatedDt,
    saveRoleCode,
    saveRoleName,
    saveUserName,
    saveUserId,
    saveTotalGroups,
    saveFeaturePermissions,
    saveRoleChange,
    saveSubcriptionChange,
    savePlanCode,
    saveUserRoleChangeApiCall,
    accountInfo,
  } = useAccountInfo();

  //   const { closeDrawer } = useDrawerInfo();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  /**
   * ログイン処理
   * @param inputData 入力フォームの内
   */
  const refresh = (setIsCalling: (value: boolean) => void): void => {
    setIsCallingApi(true);
    setErrorType(null);

    callRefreshTokenApi()
      //   .then(unwrapResult)
      .then((apiResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.REFRESH_SUCCESS) {
          dispatch(updateToken(encryptText(apiResponse.details.accessKey)));

          if (apiResponse.details.planCode !== accountInfo.planCode) {
            saveSubcriptionChange(true);
          } else {
            saveRoleChange(true);
          }
          saveLocale(apiResponse.details.locale);
          saveEmail(apiResponse.details.mailAddress);
          saveCorpId(apiResponse.details.corpId);
          saveCompany(apiResponse.details.company);
          saveCorpCreatedDt(apiResponse.details.corpCreatedDt);
          saveRoleCode(apiResponse.details.roleCode);
          saveRoleName(apiResponse.details.roleName);
          savePlanCode(apiResponse.details.planCode);
          saveUserName(apiResponse.details.userName);
          saveUserId(apiResponse.details.userId);
          saveTotalGroups(apiResponse.details.totalGroups);
          saveFeaturePermissions(apiResponse.details.featurePermissions);
          // Save account information to storage
          callApiUtil.defaults.headers.common.authorization = `Console-Token ${apiResponse.details.accessKey}`;

          displaySnackbar({
            message:
              apiResponse.details.planCode !== accountInfo.planCode
                ? `${t('common.error.subcriptionChange')} ${
                    apiResponse.details.planName
                  }.`
                : `${t('common.error.roleChange')} ${
                    apiResponse.details.roleName
                  }.`,
            type: 'warning',
            timeout: 3005,
          });
          //   closeDrawer();
          // loadingBar非表示
          // finallyで実行すると警告が出るためここで実行する。
          setIsCallingApi(false);
          setIsCalling(false);
          // If the API is successful, transition to the [Dashboard] screen
          saveUserRoleChangeApiCall(false);
          navigate(PAGE_PATH_NAME.DASHBOARD, { replace: true }); // Prevent returning to the [Login] screen even if the browser is backed up

          return;
        }
        switch (apiResponse.message) {
          case SERVER_MESSAGE.WRONG_CREDENTIALS:
            throw SERVER_MESSAGE.WRONG_CREDENTIALS;
          case SERVER_MESSAGE.WARN_INPUT_PARAM:
            throw SERVER_MESSAGE.WARN_INPUT_PARAM;
          case SERVER_MESSAGE.USER_NOT_FOUND:
            throw SERVER_MESSAGE.USER_NOT_FOUND;
          default:
            throw SERVER_MESSAGE.ERR_UNKNOWN;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        // loadingBar非表示
        setIsCallingApi(false);

        if (isErrorType(error)) {
          setErrorType(error);
        } else {
          setErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        }
      });
  };

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo(
    (): string => {
      if (!errorType) {
        return ''; // エラーコンポーネント自体非表示にする
      }
      switch (errorType) {
        case SERVER_MESSAGE.WARN_INPUT_PARAM:
          return t('login.loginApiError.warnInputParam');
        case SERVER_MESSAGE.USER_NOT_FOUND:
          return t('login.loginApiError.noAccount');
        default:
          return t('login.loginApiError.unknown');
      }
    },
    [errorType, t], // エラータイプが変わる度にエラーメッセージが返却される
  );

  /**
   * ［ログイン］ボタンクリック処理
   *
   * @param inputData 入力フォームデータ
   */
  const onClickRefresh = (setIsCalling: (value: boolean) => void) => {
    // ログイン処理
    refresh(setIsCalling);
  };

  return {
    onClickRefresh,
    errorType,
    errorMessage,
    t,
    isCallingApi,
    //   onClickPasswordReminder,
    //   onClickRegisterBtn,
  };
};

export default useRefresh;
