/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useState, useEffect, useRef } from 'react';
import { State } from 'country-state-city';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import { FixedSizeList as VariableListWindow } from 'react-window';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  countryNameCode: string;
  handleSelectStateBoxClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  selectAnchorState: null | HTMLElement;
  selectOpenState: boolean;
  width: number;
  handleSelectStateDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectState: (stateName: string) => void;
  selectedState: string;
};

const CountryStateList = ({
  countryNameCode,
  handleSelectStateBoxClick,
  selectAnchorState,
  selectOpenState,
  width,
  handleSelectStateDialOpen,
  handleSelectState,
  selectedState,
}: Props) => {
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();
  const [stateNameList, setStateNameList] = useState<any[]>([]);
  useEffect(() => {
    const stateList = State.getStatesOfCountry(countryNameCode);
    setStateNameList(stateList);
  }, [countryNameCode]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };
  const listRef = useRef(null);

  return (
    <FormControl
      sx={{ width: '100%', height: '38px' }}
      className="custom-form-input"
    >
      <Box
        onClick={stateNameList.length ? handleSelectStateBoxClick : handleClick}
        className={classes.stateCountrySelectBox}
        sx={{
          border: selectOpenState ? '1px solid #939598' : '1px solid #d9d9d9',
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedState ? (
          <Typography className={classes.stateCountrySelectItem}>
            {selectedState}
          </Typography>
        ) : countryNameCode && stateNameList.length === 0 ? (
          <Typography className={classes.stateCountrySelectText}>
            {t('corporateProfile.placeholder.noState')}
          </Typography>
        ) : (
          <Typography className={classes.stateCountrySelectText}>
            {t('corporateProfile.placeholder.select')}
          </Typography>
        )}
        <ArrowDown />
      </Box>
      <Popper
        placement="bottom-start"
        anchorEl={selectAnchorState}
        open={selectOpenState}
        className={classes.countryCodeModel}
        sx={{
          width: '17.8%',
          minWidth: `${width}px`,
          maxWidth: `${width}px`,
        }}
      >
        <ClickAwayListener onClickAway={handleSelectStateDialOpen}>
          <Paper className={classes.countryPopperBox}>
            <Box className={classes.countryPopperBoxScrollBar}>
              <SimpleBarReact className={classes.countryPopperScrollBar}>
                {({ scrollableNodeRef, contentNodeRef }) => (
                  <VariableListWindow
                    className={classes.listWindowContainer}
                    itemCount={stateNameList.length}
                    itemSize={37}
                    height={183}
                    width={width}
                    outerRef={scrollableNodeRef}
                    innerRef={contentNodeRef}
                    ref={listRef}
                  >
                    {({ index, style }) => {
                      const item = stateNameList[index];

                      return (
                        <MenuItem
                          key={item.isoCode as string}
                          style={style}
                          className={classes.countryPopperMenuItem}
                          sx={{
                            backgroundColor:
                              selectedState === item.name
                                ? '#EAEAEB'
                                : '#ffffff',
                          }}
                          // value={item.code}
                          onClick={() => {
                            handleSelectState(item.name as string);
                          }}
                        >
                          <Typography
                            className={classes.stateCountryPopperMenuItemName}
                          >
                            {item.name}
                          </Typography>
                          <Box>
                            {selectedState === item.name && <CheckIcon />}
                          </Box>
                        </MenuItem>
                      );
                    }}
                  </VariableListWindow>
                )}
              </SimpleBarReact>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </FormControl>
  );
};

export default CountryStateList;
