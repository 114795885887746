import { useState } from 'react';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import callEulaLinkApi from '../apis/callEulaGetLinkApi';
import useSnackbar from './useSnackbar';
import { EulaInfo } from '../types/apis/eulaGetLinkApi.d';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useLocale from './useLocale';

export type UseEulaValue = {
  isCallingEulaApi: boolean;
  onClickEulaApi: () => void;
  eulaValue: EulaInfo;
};

const useEulaGetLink = (): UseEulaValue => {
  const { localeLanguage } = useLocale();
  const [isCallingEulaApi, setIsCallingEulaApi] = useState<boolean>(false);
  const [eulaValue, setEulaValue] = useState<EulaInfo>({
    privacyPolicyId: 0,
    privacyPolicy: '',
    termsOfUseId: 0,
    termsOfUse: '',
  });
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();

  const onClickEulaApi = () => {
    setIsCallingEulaApi(true);
    void callEulaLinkApi(localeLanguage)
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.FETCH_OK: {
            setEulaValue(apiResponse.details);
            setIsCallingEulaApi(false);

            break;
          }
          case SERVER_MESSAGE.WARN_INPUT_PARAM: {
            displaySnackbar({
              message: t('eulaLink.errors.wrongInput'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          default: {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        setIsCallingEulaApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error');
        setIsCallingEulaApi(false);
      });
  };

  return {
    isCallingEulaApi,
    onClickEulaApi,
    eulaValue,
  };
};
export default useEulaGetLink;
