import Cookies from 'universal-cookie';
/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SERVER_MESSAGE } from '../constants/constants';
import useSnackbar from '../hooks/useSnackbar';
import useAccountInfo from '../hooks/useAccountInfo';
import useRefresh from '../hooks/useRefreshToken';
import useLogout from '../hooks/useLogout';
import AgreementUpdateReLogin from '../components/Dialog/AgreementUpdateReLogin';
import useAccessKey from '../hooks/useAccessKey';
import useCheckEulaTransition from '../hooks/useCheckEulaTransition';
import useRefreshRedux from '../hooks/useRefreshRedux';
import useSwitchLocaleLanguage from '../hooks/useSwitchLocaleLanguage';
import { decryptText } from './utility';
import AgreementUpdate from '../components/Dialog/AgreementUpdate';
import useGetUpdateUserInfo from '../hooks/useGetUpdateUserInfo';
import { AccountInfoState } from '../state/slice/accountInfoSlice';

type persistData = {
  accountInfo?: string;
};
class CheckResp {
  setResp: any;
  setValue: any;
  isCallingApi: any;
  CheckResponse = ({ children }: { children: React.ReactElement }) => {
    const { t } = useSwitchLocaleLanguage();
    const { displaySnackbar } = useSnackbar();
    // update user info hook
    const { callGetUpdateUserInfo } = useGetUpdateUserInfo();
    const {
      accountInfo,
      reLoginEula,
      saveReLoginEulaState,
      saveUserRoleChangeApiCall,
      saveLatestEulaConsented,
      saveLocale,
      saveCompany,
      saveImageUrl,
    } = useAccountInfo();
    const { onClickRefresh } = useRefresh();
    const { onClickLogoff } = useLogout();
    const { accessKeyRedux } = useAccessKey();
    const { pathname } = useLocation();
    const { refreshRedux } = useRefreshRedux();
    const [response, setResponse] = useState<{ message: string; url?: string }>(
      {
        message: '',
      },
    );
    const [isCallingApi, setIscallingApi] = useState<boolean>(false);
    const [detail, setDetail] = useState({
      message: '',
      details: {
        accessKey: '',
        roleCode: 0,
        roleName: '',
        featurePermissions: {
          DEVICEMANAGEMENT: [],
          DEVICEREMOTE_CONTROL: [],
          GROUPMANAGEMENT: [],
          REPORTING: [],
          ROLEMANAGEMENT: [],
          USERMANAGEMENT: [],
        },
      },
    });
    // const navigate = useNavigate();
    this.setResp = setResponse;
    this.setValue = setDetail;
    this.isCallingApi = isCallingApi;

    const { allowTransition } = useCheckEulaTransition();
    useEffect(() => {
      allowTransition();
      // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
      if (
        response.message !== SERVER_MESSAGE.USER_ROLE_CHANGED &&
        response.message !== SERVER_MESSAGE.REFRESH_SUCCESS &&
        response.message !== SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED &&
        !(
          response.url &&
          (response.url.includes('get-updated-user-info') ||
            response.url.includes('refresh'))
        )
      ) {
        refreshRedux();
        if (accountInfo.locale && response.message !== 'Network Error') {
          callGetUpdateUserInfo();
        }
      }
      let isApiSubscribed = true;

      //   console.log(detail, 'detail');
      //   console.log(detail.details, 'accessKey');
      //   console.log(window.location.pathname, 'url');
      if (
        response.message === SERVER_MESSAGE.FETCH_OK &&
        response.url &&
        response.url.includes('eula-contents')
      ) {
        return;
      }
      if (response.message === SERVER_MESSAGE.FETCH_OK) {
        saveReLoginEulaState(false);
        saveLatestEulaConsented(true);
        return;
      }
      if (response.message === SERVER_MESSAGE.USER_CONSENT_REQUIRED) {
        const serializedState = localStorage.getItem('persist:root');
        if (serializedState !== null) {
          const state = JSON.parse(serializedState) as persistData;
          if (state.accountInfo) {
            const updateAccountInfo = JSON.parse(
              state.accountInfo,
            ) as AccountInfoState;
            if (
              updateAccountInfo.company &&
              accountInfo.company &&
              updateAccountInfo.company !== accountInfo.company
            ) {
              saveCompany(updateAccountInfo.company);
            }
            if (updateAccountInfo.imageUrl !== accountInfo.imageUrl) {
              saveImageUrl(updateAccountInfo.imageUrl);
            }
            if (
              updateAccountInfo.locale &&
              accountInfo.locale &&
              updateAccountInfo.locale !== accountInfo.locale
            ) {
              console.log('updateAccountInfo.locale', updateAccountInfo.locale);
              saveLocale(updateAccountInfo.locale);
            }
          }
        }
        saveReLoginEulaState(true);
        return;
      }
      // No Internet
      if (response.message === 'Network Error') {
        displaySnackbar({
          message: t('common.error.noInternet'),
          type: 'error',
          timeout: 3010,
        });
        return;
      }

      // // Timeout
      // if (detail?.toString()?.includes('timeout')) {
      //   displaySnackbar({
      //     message: 'Request Timout.',
      //     type: 'error',
      //     timeout: 3010,
      //   });
      //   return;
      // }
      if (
        response.message === SERVER_MESSAGE.USER_ROLE_CHANGED &&
        !isCallingApi
      ) {
        saveUserRoleChangeApiCall(true);
        const cookies = new Cookies();
        const oldToken = decryptText(
          JSON.parse(
            JSON.parse(localStorage.getItem('persist:root')!).iotAccessKey,
          )!.iotAccessKey,
        );

        const oldTokenDate = new Date();
        oldTokenDate.setTime(oldTokenDate.getTime() + 10 * 60 * 1000); // 10 minutes
        cookies.set('oldAuthToken', oldToken, {
          expires: oldTokenDate,
          path: '/',
        });
        setIscallingApi(true);
        onClickRefresh((value: boolean) => {
          setIscallingApi(value);
          return;
        });

        return;
      }

      if (
        response.message === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED &&
        !isCallingApi
      ) {
        const cookies = new Cookies();
        const oldToken = decryptText(
          JSON.parse(
            JSON.parse(localStorage.getItem('persist:root')!).iotAccessKey,
          )!.iotAccessKey,
        );

        const oldTokenDate = new Date();
        oldTokenDate.setTime(oldTokenDate.getTime() + 10 * 60 * 1000); // 10 minutes
        cookies.set('oldAuthToken', oldToken, {
          expires: oldTokenDate,
          path: '/',
        });
        setIscallingApi(true);
        onClickRefresh((value: boolean) => {
          setIscallingApi(value);
          return;
        });

        return;
      }
      if (
        (response.message === SERVER_MESSAGE.INVALID_TOKEN ||
          response.message === SERVER_MESSAGE.EXPIRED_TOKEN) &&
        (!accountInfo.roleChange || !accountInfo.subcriptionChange) &&
        !accountInfo.userRoleChangeApiCall
      ) {
        const cookies = new Cookies();
        if (
          !(response.url && response.url.includes('logout')) &&
          (!accountInfo.roleChange || !accountInfo.subcriptionChange)
        ) {
          onClickLogoff();
        }
        return;
      }
      if (
        (response.message === SERVER_MESSAGE.ERR_UNKNOWN ||
          (response.message === SERVER_MESSAGE.INTERNAL_SERVER_ERROR &&
            !response?.url?.includes('logout'))) &&
        !response?.url?.includes('update-group-users-device')
      ) {
        displaySnackbar({
          message: t('common.error.serverErr'),
          type: 'error',
          timeout: 3010,
        });
        return;
      }

      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      };
    }, [response, detail?.details?.roleCode]);

    return (
      <>
        <div>
          {reLoginEula && accessKeyRedux && accountInfo.latestEulaConsented && (
            <AgreementUpdateReLogin
              open={reLoginEula}
              setReloginState={() => {
                saveReLoginEulaState(false);
              }}
            />
          )}
          {children}
        </div>
        {accessKeyRedux &&
          accountInfo.locale &&
          accountInfo.latestEulaConsented === false && <AgreementUpdate />}
      </>
    );
  };
}

export default new CheckResp();
