import { useEffect, useRef } from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import useDeviceList from '../../hooks/useDeviceList';
import '../../locales/locales';
import {
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../../constants/constants';
import TableTemplateContainer from '../Templates/TableTemplateContainer';
import { useStyles } from '../Templates/CommonStyle';
import DeviceFilter from '../Modecules/DeviceFilter';
import Breadcrumb from '../Modecules/Breadcrumb';
import DeviceListPageTemplate from '../Templates/DeviceListTemplate';
import { ReactComponent as GroupDevice } from '../../images/icon/no-device-latest.svg';
import { DEVICE_LIST_API_RESULT_CODE } from '../../apis/callDeviceListApi';
import useAccountInfo from '../../hooks/useAccountInfo';
/**
 * 端末一覧画面 コンポーネント
 *
 * @returns
 */
const DeviceList = (): JSX.Element => {
  // 端末一覧hooks
  const {
    errorType,
    errorMessage,
    t,
    isCallingApi,
    headerColumns,
    totalListCount,
    sortValue,
    filterValue,
    paginationValue,
    totalGroup,
    selectedGroup,
    currentPageDataRows,
    selectOpen,
    selectAnchor,
    // onClickLogoutModal,
    onClickImeiLink,
    handleSelectGroup,
    handleDropDownSearch,
    handleSelectGroupOpen,
    handleSelectBoxClick,
  } = useDeviceList();
  const classes = useStyles();
  const { accountInfo } = useAccountInfo();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEBASIC?.includes(
        FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEBASIC.VIEWLIST,
      )
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <Box className={`${classes.breadcrumbBox} ${'res-breadcrumb'}`}>
        <Breadcrumb />
      </Box>
      <DeviceListPageTemplate title={t('deviceList.viewName')}>
        <Container disableGutters sx={{ padding: '0px' }}>
          <DeviceFilter
            filterValue={filterValue}
            handleSelectBoxClick={handleSelectBoxClick}
            selectedGroup={selectedGroup}
            totalGroup={totalGroup}
            selectAnchor={selectAnchor}
            selectOpen={selectOpen}
            handleDropDownSearch={handleDropDownSearch}
            handleSelectGroupOpen={handleSelectGroupOpen}
            handleSelectGroup={handleSelectGroup}
            disabled={
              !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEBASIC?.includes(
                FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEBASIC.SEARCH,
              )
            }
          />
          {currentPageDataRows.length === 0 && !isCallingApi && (
            <Box className={classes.tableCard}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: '26px',
                }}
              >
                {t('deviceList.label.deviceList')} (0)
              </Typography>
              <Grid container className={classes.noGroupDeviceContainer}>
                <>
                  <GroupDevice className={classes.noGroupDeviceIcon} />
                  {errorType ? (
                    <>
                      {errorType !== DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA &&
                        errorType !== DEVICE_LIST_API_RESULT_CODE.OK &&
                        errorType !==
                          DEVICE_LIST_API_RESULT_CODE.NO_INTERNET && (
                          <Typography
                            className={classes.searchNoDeviceTextPrimary}
                          >
                            {errorMessage}
                          </Typography>
                        )}
                      {errorType ===
                        DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA && (
                        <Typography
                          className={classes.searchNoDeviceTextPrimary}
                        >
                          {t('deviceList.errors.noDevice')}
                        </Typography>
                      )}
                      {errorType === DEVICE_LIST_API_RESULT_CODE.OK && (
                        <>
                          <Typography
                            className={classes.searchNoDeviceTextPrimary}
                          >
                            {t('deviceList.errors.notFound')}
                          </Typography>
                          {filterValue.filterInputData.searchText && (
                            <Typography
                              className={classes.searchNoDeviceTextSecondary}
                            >
                              {t('common.error.tryAgain')}
                            </Typography>
                          )}
                        </>
                      )}
                      {errorType ===
                        DEVICE_LIST_API_RESULT_CODE.NO_INTERNET && (
                        <Typography
                          className={classes.searchNoDeviceTextPrimary}
                        >
                          {t('common.error.noData')}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography className={classes.searchNoDeviceTextPrimary}>
                        {t('deviceList.errors.notFound')}
                      </Typography>
                      <Typography
                        className={classes.searchNoDeviceTextSecondary}
                      >
                        {t('common.error.tryAgain')}
                      </Typography>
                    </>
                  )}
                </>
              </Grid>
            </Box>
          )}
          {!(currentPageDataRows.length === 0 && !isCallingApi) && (
            <Box
              className={classes.tableCard}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TableTemplateContainer
                simpleBarRef={simpleBarRef}
                headerColumns={headerColumns}
                dataRows={currentPageDataRows}
                totalDataRowsLabel={t('deviceList.label.totalDataRows')}
                totalDataRows={totalListCount}
                clickSortButton={sortValue.onClickSortButton}
                sortConfig={sortValue.sortConfig}
                currentPage={paginationValue.currentPage}
                totalPages={paginationValue.totalPages}
                clickPrevPageButton={() => {
                  paginationValue.onClickPrevPage();
                }}
                clickNextPageButton={() => {
                  paginationValue.onClickNextPage();
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={
                  paginationValue.currentRowNumberPerPage
                }
                changeRowNumber={paginationValue.onChangeRowNumber}
                isCallingApi={isCallingApi}
                containFirstAndLastButton
                clickFirstPageButton={() => {
                  /* eslint-disable */
                  paginationValue.onClickFirstPage &&
                    paginationValue.onClickFirstPage();
                }}
                clickLastPageButton={() => {
                  paginationValue.onClickLastPage &&
                    paginationValue.onClickLastPage();
                }}
                imeiLinkClicked={onClickImeiLink}
                pageDataIndexFrom={paginationValue.pageDataIndexFrom}
                pageDataIndexTo={paginationValue.pageDataIndexTo}
                tableSrcPage="Device"
                clickDisable={
                  // eslint-disable-next-line
                  !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEBASIC?.includes(
                    FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEBASIC.VIEWDETAILS,
                  )
                }
              />
            </Box>
          )}
        </Container>
      </DeviceListPageTemplate>
    </>
  );
};

export default DeviceList;
