import { useEffect } from 'react';
import useCheckTransition from './useCheckTransition';

/**
 * パスワード再設定メール送信完了画面 hooks
 * 画面初期表示処理以外の処理がないので返り値は無し
 */
const usePasswordResetSendMailComplete = (): void => {
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    allowTransition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default usePasswordResetSendMailComplete;
