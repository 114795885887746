import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../../constants/constants';
import EmailSendCard from '../Modecules/EmailSendCard';
import useCheckTransition from '../../hooks/useCheckTransition';
import useAccountRegistrationInvitation from '../../hooks/useAccountRegistrationInvitation';
import type { AccountCreateSendMailInput } from '../../types/inputs/accountCreateSendMailInput';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';
import useAccessKey from '../../hooks/useAccessKey';
import useLocale from '../../hooks/useLocale';

export type iotAccountMailAddressStateProps = {
  iotAccountMailAddress: string;
};

const AccountCreateSendMail = (): JSX.Element => {
  const { allowTransition } = useCheckTransition();

  const {
    callAccountRegistrationInvitationApi,
    isCallingRegistrationInvitationApi,
    t,
  } = useAccountRegistrationInvitation();

  const onClickSendMail = (inputData: AccountCreateSendMailInput) => {
    callAccountRegistrationInvitationApi(inputData);
  };
  // navigate(Screen transition)
  const navigate = useNavigate();
  // web browser's language (for granting URL parameters when screen transition)
  const { browserLanguage } = useLocale();

  const { accountoInfoReset } = useAccountInfo();
  const { reset } = useAccessKey();

  const onClickGoBack = () => {
    accountoInfoReset();
    reset();
    navigate(
      `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'back_login_btn');
  };
  useEffect(() => {
    if (!allowTransition()) {
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <EmailSendCard
      title={t('emailSendCard.accountCreateSendMail.title')}
      btnText={t('emailSendCard.accountCreateSendMail.registerBtn')}
      onClickSendMail={onClickSendMail}
      isCallingApi={isCallingRegistrationInvitationApi}
      onClickGoBack={onClickGoBack}
    />
  );
};

export default AccountCreateSendMail;
