import type {
  AccountRegisterApiRequestParameter,
  AccountRegisterApiResponse,
} from '../types/apis/accountRegisterApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * アカウント登録API 結果コード
 */
export const ACCOUNT_RRGISTER_API_RESULT_CODE = {
  // Account Reg. Error Code
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED',
  ONE_TIME_MAIL_MISMATCH: 'ONE_TIME_MAIL_MISMATCH',
  ONE_TIME_KEY_CHECK_FAILED: 'ONE_TIME_KEY_CHECK_FAILED',
  REGISTRATION_SEND_MESSAGE_FAILED: 'REGISTRATION_SEND_MESSAGE_FAILED',
  INFO_NO_LANGUAGE: 'INFO_NO_LANGUAGE',
  INFO_NO_ONE_TIME_KEY: 'INFO_NO_ONE_TIME_KEY',
  USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
} as const;

/**
 * アカウント登録エラータイプ
 */
export type ACCOUNT_RRGISTER_ERROR_TYPE =
  typeof ACCOUNT_RRGISTER_API_RESULT_CODE[keyof typeof ACCOUNT_RRGISTER_API_RESULT_CODE];

/**
 * アカウント登録API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @returns アカウント登録APIからのレスポンス
 */
const callAccountRegisterApi = (
  requestParams: AccountRegisterApiRequestParameter,
): Promise<AccountRegisterApiResponse> => {
  // Request parameter
  const jsonParams = {
    mailAddress: requestParams.mailAddress,
    password: requestParams.password,
    company: requestParams.company,
    name: requestParams.name,
    oneTimeKey: requestParams.onetimekey,
    locale: requestParams.locale,
    // eslint-disable
    privacyPolicyId: requestParams.privacyPolicyId,
    termsOfUseId: requestParams.termsOfUseId,
    // eslint-enable
  };
  // リクエスト送信(POST)
  /*eslint-disable*/
  const response = callApiUtil
    .post<AccountRegisterApiResponse>(
      API_URL.ACCOUNT_RRGISTER_API,
      jsonParams,
      { headers: { 'Content-Type': 'application/json' } },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callAccountRegisterApi;
