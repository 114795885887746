import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as LinkExpire } from '../../images/icon/link_expire.svg';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../../constants/constants';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale from '../../hooks/useLocale';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  text: string;
};

const RegistrationExpiredLinkCard = React.memo<Props>(
  ({ text }): JSX.Element => {
    const { t } = useSwitchLocaleLanguage();
    // common styles
    const classes = useStyles();

    // navigate(Screen transition)
    const navigate = useNavigate();
    // web browser's language (for granting URL parameters when screen transition)
    const { browserLanguage } = useLocale();

    const onClickGoBack = () => {
      navigate(
        `${PAGE_PATH_NAME.ACCOUNT_RRGISTED_SEND_MAIL}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
      );

      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'back_create_account');
    };

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ minHeight: '275px' }} className={classes.expireLinkCard}>
          <LinkExpire />
          <Typography
            sx={{ marginTop: '12px', color: '#DD0000', fontSize: '18px' }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              color: '#232F3E',
              marginTop: '6px',
            }}
          >
            {t('account.expiredURL')}
          </Typography>
          <Button
            className={classes.submitButtonLogin}
            fullWidth
            size="large"
            variant="contained"
            onClick={onClickGoBack}
          >
            {t('account.backToReg')}
          </Button>
        </Box>
      </Box>
    );
  },
);

export default RegistrationExpiredLinkCard;
