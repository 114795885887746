import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import callLogOutApi from '../apis/callLogOutApi';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../constants/constants';
import useDrawerInfo from './useDrawerInfo';
import { fireBaseAuth } from '../firebase/firebase';
import useLocale from './useLocale';

/**
 * 本カスタムフックからの返却値
 */
export type UseLogoutValue = {
  // ［ログアウト］ボタンクリック処理
  onClickLogoff: () => void;
};

/**
 * ログアウト hooks
 *
 * @returns
 */
const useLogout = (): UseLogoutValue => {
  // navigate(画面遷移)
  const navigate = useNavigate();

  // アクセスキー hooks
  const { reset } = useAccessKey();
  // // アカウント情報 hooks
  const { accountoInfoReset } = useAccountInfo();
  const { closeDrawer } = useDrawerInfo();
  // web browser's language (for granting URL parameters when screen transition)
  const { browserLanguage } = useLocale();
  /**
   * ［ログアウト］ボタンクリック処理
   */
  const onClickLogoff = (): void => {
    // Call logOut api
    void callLogOutApi().finally(() => {
      // Delete access keys from session storage
      // Delete account information from Store
      reset();
      accountoInfoReset();

      signOut(fireBaseAuth)
        .then((resp) => console.log(resp))
        .catch((error) => console.log(error));
      closeDrawer();
      navigate(
        `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
      );
    });
    // Delete access keys from session storage
    accountoInfoReset();
    reset();
  };

  return {
    onClickLogoff,
  };
};

export default useLogout;
