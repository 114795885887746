import { useEffect } from 'react';
import useCheckTransition from './useCheckTransition';
/**
 * Account Create email sent completion screen hooks
 */
const useAccountCreateSendMailComplete = (): void => {
  // Screen transition control hooks
  const { allowTransition } = useCheckTransition();

  useEffect(() => {
    allowTransition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default useAccountCreateSendMailComplete;
