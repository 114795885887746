import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAccessKey from '../hooks/useAccessKey';
import { PAGE_PATH_NAME } from '../constants/constants';

/**
 * Only Admin user can access
 * @returns
 */
const AuthRoute = () => {
  const { accessKey } = useAccessKey();
  const isAuthenticated = accessKey;
  /* eslint-disable */

  const { pathname } = useLocation();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={PAGE_PATH_NAME.LOGON} state={{ pageSrc: pathname }} />
  );
};

export default AuthRoute;
