import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

import { sendGAEvent } from './useGAEvent';
import { AppDispatch, useAppDispatch } from '../state/store';
import { translationListSlice } from '../state/slice/translationCountSlice';
import { PAGE_PATH_NAME } from '../constants/constants';

export type UsePaginationValue = {
  currentPage: number;
  totalPages: number;
  onClickPrevPage: () => void;
  onClickNextPage: () => void;
  onClickFirstPage: () => void;
  onClickLastPage: () => void;
  currentRowNumberPerPage: number;
  onChangeRowNumber: (selectedRowNumber: number) => void;
  changeTotalPages: (pages: number) => void;
  restoreCurrentPage: (iotCurrentPage: number, iotRowNumber: number) => void;
  changeCurrentPage: (page: number) => void;
};

const usePagination = (
  defaultRowNumberPerPage: number,
  defaultCurrentPage: number,
): UsePaginationValue => {
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [currentRowNumberPerPage, setCurrentRowNumberPerPage] =
    useState<number>(defaultRowNumberPerPage);
  const [totalPages, setTotalPages] = useState<number>(1);
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();

  const { addTableRowNumber, addPageNumber } = translationListSlice.actions;

  const onClickPrevPage = useCallback((): void => {
    setCurrentPage((prev) => prev - 1);
    // set GA tag
    sendGAEvent('press_btn', 'btn_name', 'paging_Switching');
  }, []);

  const onClickNextPage = useCallback((): void => {
    setCurrentPage((prev) => prev + 1);

    // set GA tag
    sendGAEvent('press_btn', 'btn_name', 'paging_Switching');
    sendGAEvent('press_btn', 'btn_name', 'page_next');
    sendGAEvent('press_btn', 'page_next_value', (currentPage + 1).toString());
  }, [currentPage]);

  const onClickFirstPage = useCallback((): void => {
    setCurrentPage(1);

    // set GA tag
    sendGAEvent('press_btn', 'btn_name', 'paging_Switching');
  }, []);

  const onClickLastPage = useCallback((): void => {
    setCurrentPage(totalPages);

    // set GA tag
    sendGAEvent('press_btn', 'btn_name', 'paging_Switching');
  }, [totalPages]);

  useEffect(() => {
    if (location.pathname === PAGE_PATH_NAME.TRANS_COUNT_LIST) {
      dispatch(addPageNumber(currentPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const onChangeRowNumber = useCallback((selectedRowNumber: number) => {
    setCurrentRowNumberPerPage(selectedRowNumber);
    setCurrentPage(1);
    if (location.pathname === PAGE_PATH_NAME.TRANS_COUNT_LIST) {
      dispatch(addTableRowNumber(selectedRowNumber));
    }
    // set GA tag
    sendGAEvent(
      'press_btn',
      'paging_switching_value',
      selectedRowNumber.toString(),
    );
    // eslint-disable-next-line
  }, []);

  const changeTotalPages = useCallback((pages: number): void => {
    setTotalPages(pages);
  }, []);

  const restoreCurrentPage = useCallback(
    (iotCurrentPage: number, iotRowNumber: number) => {
      if (iotCurrentPage && iotCurrentPage !== 1) {
        setCurrentPage(iotCurrentPage);
      }

      if (iotRowNumber) {
        setCurrentRowNumberPerPage(iotRowNumber);
      }
    },
    [],
  );

  const changeCurrentPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    restoreCurrentPage,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  };
};

export default usePagination;
