/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callAuthListApi, {
  AUTH_LIST_API_ERROR_TYPE,
  AUTH_LIST_API_RESULT_CODE,
} from '../../apis/callAuthListApi';
import callUserPreAddApi, {
  USER_PREADD_API_RESULT_CODE,
  USER_PREADD_API_ERROR_TYPE,
} from '../../apis/callUserPreAddApi';
import {
  // isAuthListErrorType,
  isErrorType,
  // isUserPreAddErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import { AuthListApiResponse } from '../../types/apis/authListApi.d';
import {
  UserPreAddApiRequestParameter,
  UserPreAddApiResponse,
} from '../../types/apis/userPreAddApi.d';
import { AuthListResult, UserPreAddResult } from '../../types/user/userAdd.d';
import {
  UserInfoProsessResultState,
  UserListState,
  UserListTransState,
  USER_INFO_PROSESS_RESULT_TYPE,
  USER_LIST_TRANS_METHOD_CODE,
  USER_LIST_TRANS_METHOD_TYPE,
} from '../../types/user/userList.d';
import {
  ALL_ERROR_TYPE,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../../constants/constants';

/**
 * 権限一覧取得
 *
 * @param accessKey アクセスキー
 * @returns
 */
/*eslint-disable*/
export const fetchAuthList = async (): Promise<AuthListResult> => {
  // 権限一覧取得API呼び出し
  try {
    const apiResponse: AuthListApiResponse = await callAuthListApi();
    switch (apiResponse.message) {
      // 成功
      case SERVER_MESSAGE.FETCH_OK:
        return {
          message: SERVER_MESSAGE.FETCH_OK,
          details: apiResponse.details,
        };
      // 失敗
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      details: [],
    };
  }
};

/**
 * メール送信処理(ユーザ仮登録)
 *
 * @param accessKey アクセスキー
 * @param email メールアドレス
 * @param userName 新しいユーザー名
 * @param roleCode 新しい権限ID
 * @returns
 */
export const sendMail = async (
  // accessKey: string,
  email: string,
  userName: string,
  roleCode: number,
): Promise<UserPreAddResult> => {
  try {
    // リクエストパラメータ
    const parameter: UserPreAddApiRequestParameter = {
      mailAddress: email,
      userName: userName,
      roleCode: roleCode,
    };
    // ユーザ仮登録API呼び出し
    const apiResponse: UserPreAddApiResponse = await callUserPreAddApi(
      parameter,
    );
    switch (apiResponse.message) {
      // 成功
      case SERVER_MESSAGE.CREATE_OK:
        return { message: SERVER_MESSAGE.CREATE_OK };
      // 失敗
      case SERVER_MESSAGE.WARN_INPUT_PARAM:
        throw SERVER_MESSAGE.WARN_INPUT_PARAM;
      case SERVER_MESSAGE.USER_ALREADY_REGISTERED:
        throw SERVER_MESSAGE.USER_ALREADY_REGISTERED;
      case SERVER_MESSAGE.REGISTRATION_SEND_MESSAGE_FAILED:
        throw SERVER_MESSAGE.REGISTRATION_SEND_MESSAGE_FAILED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.INFO_ALREADY_INVITED:
        throw SERVER_MESSAGE.INFO_ALREADY_INVITED;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.AUTH_DATA_NOT_FOUND:
        throw SERVER_MESSAGE.AUTH_DATA_NOT_FOUND;
     case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return { message };
  }
};

/**
 * [ユーザー管理]画面への遷移処理
 *
 * @param navigate NavigateFunction
 * @param processResult ユーザー情報の操作結果(メール送信成功 or キャンセル)
 */

/**
 * [ユーザー管理]画面への遷移処理
 *
 * @param navigate NavigateFunction
 * @param transMethodCode [ユーザー管理]画面への遷移方法(戻るボタン or メール送信成功時)
 * @param prosessResultCode ユーザー情報の操作結果(メール送信成功 or キャンセル)
 */
export const transUserList = (
  navigate: NavigateFunction,
  transMethodCode: USER_LIST_TRANS_METHOD_TYPE,
  prosessResultCode: USER_INFO_PROSESS_RESULT_TYPE,
): void => {
  const userListState: UserListState = {
    transMethod: transMethodCode,
  };

  const userInfoProcState: UserInfoProsessResultState = {
    userInfoProcessResult: prosessResultCode,
  };

  const transState: UserListTransState = {
    userListState,
    userInfoProcState,
  };
  // [ユーザー管理]画面に遷移
  navigate(PAGE_PATH_NAME.USER_LIST, {
    state: transState,
  });
};
