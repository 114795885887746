/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import { Country } from 'country-state-city';
import React, { useRef } from 'react';
import CountryList from 'country-list-with-dial-code-and-flag';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import { FixedSizeList as VariableListWindow } from 'react-window';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type countryInfo = {
  countryCode: string;
  countryName: string;
};

type Props = {
  handleSelectCountryBoxClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  selectAnchorCountry: null | HTMLElement;
  selectOpenCountry: boolean;
  width: number;
  handleSelectCountryDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectCountry: (countryDataInfo: countryInfo) => void;
  selectedCountry: string;
};

const CountrySelectCard = ({
  handleSelectCountryBoxClick,
  selectAnchorCountry,
  selectOpenCountry,
  width,
  handleSelectCountryDialOpen,
  handleSelectCountry,
  selectedCountry,
}: Props) => {
  const classes = useStyles();
  // const allCountry = Country.getAllCountries();
  const allCountry = CountryList.getAll();
  const listRef = useRef(null);

  const { t } = useSwitchLocaleLanguage();

  return (
    <FormControl
      sx={{ width: '100%', height: '38px' }}
      className="custom-form-input"
    >
      <Box
        onClick={handleSelectCountryBoxClick}
        className={classes.stateCountrySelectBox}
        sx={{
          border: selectOpenCountry ? '1px solid #939598' : '1px solid #d9d9d9',
        }}
      >
        {selectedCountry ? (
          <Typography className={classes.stateCountrySelectItem}>
            {selectedCountry}
          </Typography>
        ) : (
          <Typography className={classes.stateCountrySelectText}>
            {t('corporateProfile.placeholder.select')}
          </Typography>
        )}
        <ArrowDown />
      </Box>
      <Popper
        placement="bottom-start"
        anchorEl={selectAnchorCountry}
        open={selectOpenCountry}
        className={classes.countryCodeModel}
        sx={{
          width: '17.8%',
          minWidth: `${width}px`,
          maxWidth: `${width}px`,
        }}
      >
        <ClickAwayListener onClickAway={handleSelectCountryDialOpen}>
          <Paper className={classes.countryPopperBox}>
            <Box className={classes.countryPopperBoxScrollBar}>
              <SimpleBarReact className={classes.countryPopperScrollBar}>
                {({ scrollableNodeRef, contentNodeRef }) => (
                  <VariableListWindow
                    className={classes.listWindowContainer}
                    itemCount={allCountry.length}
                    itemSize={37}
                    height={183}
                    width={width}
                    outerRef={scrollableNodeRef}
                    innerRef={contentNodeRef}
                    ref={listRef}
                  >
                    {({ index, style }) => {
                      const item = allCountry[index];

                      return (
                        <MenuItem
                          key={item.code}
                          className={classes.countryPopperMenuItem}
                          style={style}
                          sx={{
                            backgroundColor:
                              selectedCountry === item.name
                                ? '#EAEAEB'
                                : '#ffffff',
                          }}
                          // value={item.code}
                          onClick={() => {
                            handleSelectCountry({
                              // countryCode: item.isoCode,
                              countryCode: item.code,
                              countryName: item.name,
                            });
                          }}
                        >
                          <Typography
                            className={classes.stateCountryPopperMenuItemName}
                          >
                            {item.name}
                          </Typography>

                          <Box>
                            {selectedCountry === item.name && <CheckIcon />}
                          </Box>
                        </MenuItem>
                      );
                    }}
                  </VariableListWindow>
                )}
              </SimpleBarReact>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </FormControl>
  );
};

export default CountrySelectCard;
