/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import type {
  ToLangListApiParameter,
  ToLangListApiResponse,
} from '../../types/apis/toLangListApi.d';
import callToLangListApi, {
  TO_LANG_LIST_ERROR_TYPE,
  TO_LANG_LIST_API_RESULT_CODE,
} from '../../apis/callToLangListApi';
import {
  TableHeaderData,
  TO_LANG_TABLE_DATA_KEY,
} from '../../types/datatable/dataTable.d';
import { isToLangListErrorType } from '../../types/apis/apiErrorTypeGuard';
import type { ToLangListResult } from '../../types/report/toLangList.d';
import { convertStringArrayToTableHeaderColumns } from '../datatable/dataTableUtil';

/**
 * 翻訳後言語画面に依存する汎用クラス
 */

/**
 * 翻訳後言語一覧取得APIにリクエスト送信して、一覧を取得
 *
 * @param accessKey アクセスキー
 * @param corpId 法人ID
 * @param from 期間開始日
 * @param to 期間終了日
 * @param view ビュータイプ
 * @returns
 */
export const fetchToLangList = async (
  // accessKey: string,
  corpId: string,
  from: string,
  to: string,
  view: string,
): Promise<ToLangListResult> => {
  // リクエストパラメータ
  const requestParam: ToLangListApiParameter = {
    corpId,
    from,
    to,
    view,
  };
  // 翻訳後言語取得API呼び出し
  try {
    const apiResponse: ToLangListApiResponse = await callToLangListApi(
      requestParam,
    );
    if (
      apiResponse.message === TO_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
    ) {
      // テーブルのヘッダー作成（APIから返却された「日・週・月別の翻訳回数列のヘッダ」も表示）
      const tableHeader: TableHeaderData[] =
        convertStringArrayToTableHeaderColumns(
          apiResponse.details.titleList,
          TO_LANG_TABLE_DATA_KEY.COUNT_LIST,
          view,
        );

      return {
        resultCode: TO_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
        toLangInfos: apiResponse.details.data,
        tableHeader,
        viewType: apiResponse.details.viewType,
      };
    }

    // 翻訳後言語の取得に失敗
    switch (apiResponse.message) {
      case TO_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER:
        throw TO_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER;
      case TO_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw TO_LANG_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case TO_LANG_LIST_API_RESULT_CODE.INFO_INVALID:
        throw TO_LANG_LIST_API_RESULT_CODE.INFO_INVALID;
      case TO_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw TO_LANG_LIST_API_RESULT_CODE.INFO_EXPIRED;
      case TO_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw TO_LANG_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      case TO_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN:
        throw TO_LANG_LIST_API_RESULT_CODE.INVALID_TOKEN;
      case TO_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
        throw TO_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED;
      case TO_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
        throw TO_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED;
      case TO_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw TO_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED;
      default:
        throw TO_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err?.message === TO_LANG_LIST_API_RESULT_CODE.NO_INTERNET) {
      const message: TO_LANG_LIST_ERROR_TYPE =
        err.message as TO_LANG_LIST_ERROR_TYPE;

      return {
        resultCode: message,
        toLangInfos: null,
        tableHeader: [],
        viewType: '',
      };
    }
    let resultCode: TO_LANG_LIST_ERROR_TYPE =
      TO_LANG_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isToLangListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      toLangInfos: null,
      tableHeader: [],
      viewType: '',
    };
  }
};
