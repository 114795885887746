import usePasswordSetComplete from '../../hooks/usePasswordSetComplete';
import PasswordSetSuccessCard from '../Atoms/PasswordSetSuccessCard';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

/**
 * パスワード設定完了画面コンポーネント
 */
const PasswordSetComplete = (): JSX.Element => {
  usePasswordSetComplete();
  const { t } = useSwitchLocaleLanguage();

  return (
    <PasswordSetSuccessCard
      cardText={t('resetPassword.passwordSetComplete.passSetSuccess')}
    />
  );
};

export default PasswordSetComplete;
