import { useEffect, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import callPasswordReminderSendMailApi, {
  PW_REMINDER_SEND_MAIL_API_RESULT_CODE,
  // PW_REMINDER_SEND_MAIL_ERROR_TYPE,
} from '../apis/callPasswordReminderSendMailApi';
import {
  ALL_ERROR_TYPE,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  URL_PARAMETER_KEY,
} from '../constants/constants';
import {
  isErrorType,
  // isReminderSendMailErrorType,
} from '../types/apis/apiErrorTypeGuard';
import type {
  PasswordReminderSendMailApiRequestParam,
  PasswordReminderSendMailApiResponse,
} from '../types/apis/passwordReminderSendMailApi';
import type { PasswordResetSendMailInput } from '../types/inputs/passwordResetSendMailInput';
import useCheckTransition from './useCheckTransition';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useSnackbar from './useSnackbar';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import useLocale from './useLocale';

/**
 * STATE passed to the [Password Reset Email Sending Sending] screen
 */
export type ResetSendMailCompleteState = {
  mailAddress: string;
};

/**
 * Return value from this custom hook
 */
export type UsePasswordResetSendMailValue = {
  onClickSendMail: (inputData: PasswordResetSendMailInput) => void;
  onClickGoBack: () => void;
  isCallingApi: boolean;
  errorMessage: string;
  errorType: ALL_ERROR_TYPE | null;
  t: TFunction;
};
const usePasswordResetSendMail = (): UsePasswordResetSendMailValue => {
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // navigate(画面遷移)
  const navigate = useNavigate();
  // web browser's language (for granting URL parameters when screen transition)
  const { browserLanguage } = useLocale();

  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  // エラータイプ
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  const { displaySnackbar } = useSnackbar();

  const { accountoInfoReset } = useAccountInfo();
  const { reset } = useAccessKey();

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    allowTransition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 送信ボタン押下処理
   * パスワード設定メール送信APIが成功した場合、メール送信フォームを表示する
   */
  const onClickSendMail = (inputData: PasswordResetSendMailInput) => {
    setIsCallingApi(true);

    // エラータイプ初期化
    setErrorType(null);

    const passwordReminderSendMailApiRequestParam: PasswordReminderSendMailApiRequestParam =
      {
        mailAddress: inputData.mailAddress?.trim(),
      };

    callPasswordReminderSendMailApi(passwordReminderSendMailApiRequestParam)
      .then((apiResponse: PasswordReminderSendMailApiResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.CREATE_OK) {
          setIsCallingApi(false);

          // STATE passed to the [Password Reset Email Sending Sending] screen
          const resetSendMailCompleteState: ResetSendMailCompleteState = {
            mailAddress: inputData.mailAddress,
          };

          // Transition to the password setting transmission completion screen
          navigate(
            `${PAGE_PATH_NAME.PASSWORD_RESET_SEND_MAIL_COMPLETE}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
            { state: resetSendMailCompleteState },
          );
        }

        switch (apiResponse.message) {
          case PW_REMINDER_SEND_MAIL_API_RESULT_CODE.WARN_INPUT_PARAM:
            throw PW_REMINDER_SEND_MAIL_API_RESULT_CODE.WARN_INPUT_PARAM;
          case PW_REMINDER_SEND_MAIL_API_RESULT_CODE.INFO_NO_USER:
            throw PW_REMINDER_SEND_MAIL_API_RESULT_CODE.INFO_NO_USER;
          case SERVER_MESSAGE.NO_INTERNET:
            throw SERVER_MESSAGE.NO_INTERNET;
          default:
            throw PW_REMINDER_SEND_MAIL_API_RESULT_CODE.ERR_UNKNOWN;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setErrorType(error);
        } else {
          setErrorType(PW_REMINDER_SEND_MAIL_API_RESULT_CODE.ERR_UNKNOWN);
        }
        setIsCallingApi(false);
      });
  };

  /**
   * [戻る]ボタン押下時処理
   */
  const onClickGoBack = () => {
    accountoInfoReset();
    reset();
    // ログイン画面に戻る
    navigate(
      `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );
  };

  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return '';
    }

    switch (errorType) {
      case PW_REMINDER_SEND_MAIL_API_RESULT_CODE.WARN_INPUT_PARAM:
        return t(
          'emailSendCard.forgotPasswordSendMail.apiErrors.warnInputParam',
        );
      case PW_REMINDER_SEND_MAIL_API_RESULT_CODE.INFO_NO_USER:
        return t(
          'emailSendCard.forgotPasswordSendMail.apiErrors.emailNotExist',
        );
      case SERVER_MESSAGE.NO_INTERNET:
        return t('common.error.noInternet');
      default:
        return t('emailSendCard.forgotPasswordSendMail.apiErrors.otherError');
    }
  }, [errorType, t]);

  // Error card show handle with Snackbar
  useEffect(() => {
    if (errorType) {
      displaySnackbar({
        message: errorMessage,
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType, errorMessage]);

  return {
    onClickSendMail,
    onClickGoBack,
    isCallingApi,
    errorMessage,
    errorType,
    t,
  };
};
export default usePasswordResetSendMail;
