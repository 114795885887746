/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography, Breadcrumbs, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { PAGE_PATH_NAME, PATH_NAMES } from '../../constants/constants';

// common style
import { useStyles } from '../Templates/CommonStyle';

const Breadcrumb = (): JSX.Element => {
  // common styles
  const classes = useStyles();

  const { t } = useSwitchLocaleLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  // Get the path name from the route
  // const pathNames = location.pathname
  //   .split('/')
  //   .filter((x) => x)
  //   .slice(0, 2);
  const pathNames = location.pathname
    .split('/')
    .filter((x) => x && Number.isNaN(Number(x)));

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          fontSize: '14px',
          color: '#939598',
          margin: '0px 7px',
          width: '5px',
          height: '20px',
        },
      }}
    >
      {pathNames.length > 0 ? (
        <Link
          // underline="hover"
          className={classes.breadcrumbLink}
          // Used onClick method instead href to prevent the page refreshing error
          onClick={(e) => {
            e.preventDefault();
            navigate('/dashboard');
            // GA tag
            sendGAEvent('press_btn_link', 'link_name', 'open_url');
            sendGAEvent('press_btn_link', 'open_url_value', '/');
          }}
        >
          {t('Breadcrumbs.home')}
        </Link>
      ) : (
        <Typography color="#939598" fontSize="14px" lineHeight="20px">
          {t('Breadcrumbs.home')}
        </Typography>
      )}
      {pathNames.map((pathname, index) => {
        let routeTo: string;
        if (
          pathname === PATH_NAMES.DEVICEDETAILS ||
          pathname === PATH_NAMES.GROUPS
        ) {
          routeTo = PAGE_PATH_NAME.DEVICE_LIST;
        } else if (
          pathname === PATH_NAMES.GROUPDETAIL ||
          pathname === PATH_NAMES.NEWGROUP
        ) {
          routeTo = PAGE_PATH_NAME.GROUPS;
        } else if (
          pathname === PATH_NAMES.FROMLANGLIST ||
          pathname === PATH_NAMES.TOLANGLIST
        ) {
          routeTo = PAGE_PATH_NAME.TRANS_COUNT_LIST;
        } else {
          // Breadcrum path
          routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
        }

        return (
          <Link
            // underline="hover"
            className={classes.breadcrumbLink}
            key={pathname}
            // Used onClick method instead of href to prevent the page refreshing error
            onClick={(e) => {
              e.preventDefault();

              navigate(routeTo);
              // GA tag
              sendGAEvent('press_btn_link', 'link_name', 'open_url');
              sendGAEvent('press_btn_link', 'open_url_value', routeTo);
            }}
          >
            {t(`Breadcrumbs.${pathname}`)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
