import { useLocation } from 'react-router-dom';
import useAccountCreateSendMailComplete from '../../hooks/useAccountCreateSendMailComplete';
import EmailSendSuccessCard from '../Modecules/EmailSendSuccessCard';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

export type iotAccountMailAddressStateProps = {
  mailAddress: string;
};

const AccountCreateSendMailComplete = (): JSX.Element => {
  const location = useLocation();
  const { t } = useSwitchLocaleLanguage();
  const localeState = location.state as iotAccountMailAddressStateProps;

  let email = '';
  // eslint-disable
  if (localeState !== null) {
    email = localeState?.mailAddress;
  }
  useAccountCreateSendMailComplete();

  return (
    <EmailSendSuccessCard
      cardText={t('emailSendCompleteCard.accountCreateSendMailComplete')}
      email={email}
    />
  );
};
export default AccountCreateSendMailComplete;
