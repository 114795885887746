import type {
  OnetimeCheckApiRequestParameter,
  OnetimeCheckApiResponse,
} from '../types/apis/onetimeCheckApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * ワンタイムキー有効確認API 結果コード
 */
export const ONETIME_CHECK_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 未登録のワンタイムキー
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ
  INFO_EXPIRED: 'ONE_TIME_KEY_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * アカウント登録API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @returns アカウント登録APIからのレスポンス
 */
const callOnetimeCheckApi = (
  requestParams: OnetimeCheckApiRequestParameter,
): Promise<OnetimeCheckApiResponse> => {
  // リクエストパラメータ
  const jsonParams = {
    oneTimeKey: requestParams.onetimekey,
  };
  // リクエスト送信(POST)
  const response = callApiUtil
    .post<OnetimeCheckApiResponse>(API_URL.ONETIME_CHECK_API, jsonParams, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    .catch((error) => error.response.data);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response;
};

export default callOnetimeCheckApi;
