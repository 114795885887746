import { useEffect } from 'react';
import { TFunction } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../constants/constants';
import useCheckTransition from './useCheckTransition';
import { PasswordSetCompleteState } from './usePasswordSet';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useLocale from './useLocale';

/**
 * 本カスタムフックからの返却値
 */
export type UsePasswordSetComplete = {
  // [ログイン画面を表示]ボタン押下時処理
  onClickLogin: () => void;
  // 言語切り替え(フレームワーク)
  t: TFunction;
};

/**
 * パスワード設定完了画面 hooks
 *
 * @returns
 */
const usePasswordSetComplete = (): UsePasswordSetComplete => {
  // web browser's language (for granting URL parameters when screen transition)
  const { browserLanguage } = useLocale();
  // navigate(画面遷移)
  const navigate = useNavigate();
  // ロケーション情報(state=［アカウント登録］画面からアカウント情報を取得)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { pathname, state, search } = useLocation();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  /**
   * ブラウザバック制御
   */
  const browserBackBlockFunction = (): void => {
    const passwordSetCompleteState: PasswordSetCompleteState =
      state as PasswordSetCompleteState;
    navigate(`${pathname}${search}`, {
      state: passwordSetCompleteState,
    }); // [パスワード設定完了］画面に遷移(state付き)
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    if (!allowTransition()) {
      return () => ({});
    }

    // ブラウザバックで［パスワード設定完了］画面にとどまるように制御
    window.history.pushState(null, '', `${pathname}${search}`);
    window.addEventListener('popstate', browserBackBlockFunction);

    return () => {
      window.removeEventListener('popstate', browserBackBlockFunction); // クリーンアップ(メモリーリークしてホワイトアウトするのは防ぐ)
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * [ログイン画面を表示]ボタン押下時処理
   */
  const onClickLogin = () => {
    // [ログイン]画面へ遷移する
    navigate(
      `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );
  };

  return {
    onClickLogin,
    t,
  };
};

export default usePasswordSetComplete;
