/* eslint-disable */
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import {
  DEVICE_LIST_API_RESULT_CODE,
  DEVICE_LIST_ERROR_TYPE,
} from '../apis/callDeviceListApi';
import { DeviceInfo, DEVICE_INFO_KEY } from '../types/apis/deviceListApi.d';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
// import useCheckTransition from './useCheckTransition';
import {
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../constants/constants';
import {
  convertDeviceInfoArrayToTableRowDataArray,
  // sortedItems,
  updateSortConfig,
  filterArray,
} from '../utils/datatable/dataTableUtil';
import {
  TableHeaderData,
  TableRowData,
  SortConfig,
  DEVICE_TABLE_DATA_KEY,
  DIRECTION_TYPE,
  DisplayContentState,
  API_CALL_STATE,
  FILTERING_STATE,
} from '../types/datatable/dataTable.d';
import useFilter from './useFilter';
import type { FilterInput } from '../types/inputs/filterInput';
import usePagination from './usePagination';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import {
  DeviceInfoData,
  // SimInfoData,
  DeviceListResult,
  ExpireDtStateType,
  DEVICE_LIST_WIDTH_ARRAY,
} from '../types/devicelist/deviceList.d';
import {
  // findSimInfo,
  findDeviceInfoData,
  // spliceDeviceInfo,
  // spliceDeviceTableRowData,
  fetchDeviceList,
  isFetchedeExpiredt,
  fetchDeviceGroupList,
} from '../utils/devicelist/deviceListUtil';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import { sendGAEvent } from './useGAEvent';
import {
  DeviceGroupInfo,
  DeviceGroupListApiResponse,
} from '../types/apis/deviceGroupListApi';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import useLogout from './useLogout';

const sortKeyMap: {
  [key: string]: string;
} = {
  groupName: 'group_name',
  imei: 'imei',
  deviceModel: 'product_type',
  deviceName: 'device_name',
  status: 'status',
  mode: 'mode_name',
  simExpDate: 'sim_exp_dt',
  lastUsed: 'latest_trans_dt',
  '': '',
};

/* DEFINING ALL TYPES */
export type SortValue = {
  sortConfig: SortConfig;
  onClickSortButton: (data: TableHeaderData) => void;
};

export type FilterValue = {
  onClickFilterSearch: (inputData: FilterInput) => void;
  isGroupListApiCalling: boolean;
  onGroupListScroll: () => void;
  filterInputData: FilterInput;
};

export type PaginationValue = {
  currentPage: number;
  totalPages: number;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
  onClickPrevPage: () => void;
  onClickNextPage: () => void;
  onClickFirstPage?: () => void;
  onClickLastPage?: () => void;
  currentRowNumberPerPage: number;
  onChangeRowNumber: (selectedRowNumber: number) => void;
  changeTotalPages: (pages: number) => void;
};

export type TransHistoryDaialogValue = {
  // [翻訳履歴]ダイアログを表示するか否か
  isShowTransHistoryDaialog: boolean;
  // [翻訳履歴]ダイアログに渡すIMEI
  deviceDitailImei: MutableRefObject<string>;
  // [閉じる]ボタンまたはダイアログ外がクリックされた場合の処理
  onClickCloseModal: () => void;
};

export type UseDeviceListValue = {
  errorType: DEVICE_LIST_ERROR_TYPE | null;
  errorMessage: string;
  t: TFunction;
  isCallingApi: boolean;
  onClickLogoutModal: () => void;
  headerColumns: TableHeaderData[];
  currentPageDataRows: TableRowData[];
  totalListCount: number;
  onClickImeiLink: (imei: string) => void;
  sortValue: SortValue;
  filterValue: FilterValue;
  paginationValue: PaginationValue;
  transHistoryDaialogValue: TransHistoryDaialogValue;
  totalGroup: DeviceGroupInfo[];
  selectedGroup: string;
  selectOpen: boolean;
  selectAnchor: null | HTMLElement;
  handleSelectGroup: (grouInfo: DeviceGroupInfo) => void;
  handleDropDownSearch: (data: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectGroupOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectBoxClick: (event: React.MouseEvent<HTMLElement>) => void;
  resetGroup: () => void;
};
export type deviceDetailsStateProps = {
  imeiNo: string;
};
/* DEFINING ALL TYPES */

const useDeviceList = (): UseDeviceListValue => {
  /* DEVICE LIST RELATED STATES */
  const { displaySnackbar } = useSnackbar();

  // Total List acquired from API
  const originalDeviceInfos = useRef<DeviceInfoData[]>([]);
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]); // Current Data for given page and search text and row number
  const [errorType, setErrorType] = useState<DEVICE_LIST_ERROR_TYPE | null>(
    null,
  );
  const [canFetchSimInfo, setCanFetchSimInfo] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [listSearchText, setListSearchText] = useState<string>('');
  /* DEVICE LIST RELATED STATES */
  const { displayRedirectDialog } = useRedirectDialog();

  /* GROUP FILTER RELATED STATES */
  const groupRowNumber = 30;
  const [totalGroup, setTotalGroup] = useState<DeviceGroupInfo[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('All');
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [groupSearchText, setGroupSearchText] = useState<string>('');
  const [groupFilterPageNumber, setGroupFilterPageNumber] = useState<number>(0);
  const groupTotalFetchData = useRef<number>(0);
  const groupRowNumberPerPage = useRef<number>(groupRowNumber);
  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [isGroupListApiCalling, setIsGroupListApiCalling] =
    useState<boolean>(false);
  const stopInitialSearch = useRef<boolean>(false);
  /* GROUP FILTER RELATED STATES */

  /* DEVICE LIST PAGINATION */
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);
  const totalData = useRef<number>(0);
  /* DEVICE LIST PAGINATION */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigate = useNavigate();
  const location = useLocation();
  const imeiQueueForSimInfo = useRef<string[]>([]);
  // const { onClickLogoff } = useLogout();

  const { accessKey } = useAccessKey();
  const { accountInfo } = useAccountInfo();
  const { t } = useSwitchLocaleLanguage();
  // Screen transition hook
  // const { allowTransition } = useCheckTransition();
  const { onClickFilterSearch, filterInputData } = useFilter('');
  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeTotalPages,
    changeCurrentPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 0);
  const { updateState, deviceDetailUpdateStateReset } =
    useDeviceDetailUpdateState();
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending,
      filteringState: FILTERING_STATE.inactive,
    });
  const isFirstRenderingRef = useRef(true);
  const [isShowTransHistoryDaialog, setIsShowTransHistoryDaialog] =
    useState<boolean>(false);
  const deviceDitailImei = useRef<string>('');

  const headerColumns = useMemo(
    (): TableHeaderData[] => [
      {
        key: DEVICE_TABLE_DATA_KEY.GROUP_NAME,
        value: t('deviceList.header.groupName'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[0],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.IMEI,
        value: t('deviceList.header.imei'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[1],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.DEVICE_NAME,
        value: t('deviceList.header.deviceName'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[2],
      },
      // {
      //   key: DEVICE_TABLE_DATA_KEY.LATEST_TRANSDT,
      //   value: t('deviceList.header.latestTransdt'),
      //   isSort: true,
      //   width: DEVICE_LIST_WIDTH_ARRAY[2],
      // },
      {
        key: DEVICE_TABLE_DATA_KEY.DEVICE_MODEL,
        value: t('deviceList.header.productType'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[3],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.MODE,
        value: t('deviceList.header.mode'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[4],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.STATUS,
        value: t('deviceList.header.status'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[5],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.SIM_EXP,
        value: t('deviceList.header.expiredt'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[6],
      },
      {
        key: DEVICE_TABLE_DATA_KEY.LAST_USED,
        value: t('deviceList.header.lastUsed'),
        isSort: true,
        width: DEVICE_LIST_WIDTH_ARRAY[7],
      },
      // {
      //   key: DEVICE_TABLE_DATA_KEY.ICCID,
      //   value: t('deviceList.header.iccid'),
      //   isSort: true,
      //   width: DEVICE_LIST_WIDTH_ARRAY[6],
      // },
    ],
    [t],
  );

  const expireDtStateType = useMemo(
    (): ExpireDtStateType => ({
      default: ' ',
      indefinitely: t('deviceList.expireDtState.indefinitely'),
      beforeUse: t('deviceList.expireDtState.beforeUse'),
    }),
    [t],
  );

  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return '';
    }
    switch (errorType) {
      case DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA:
        return t('deviceList.deviceListApiError.noData');
      default:
        return t('deviceList.deviceListApiError.unknown');
    }
  }, [errorType, t]);

  const browserBackBlockFunction = (): void => {
    navigate(PAGE_PATH_NAME.DEVICE_LIST);
  };

  const onClickImeiLink = useCallback((imei: string) => {
    // setIsShowTransHistoryDaialog(true);
    const imeiState: deviceDetailsStateProps = {
      imeiNo: imei,
    };
    navigate(PAGE_PATH_NAME.DEVICE_DETAILS, { state: imeiState });
    deviceDitailImei.current = imei;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort || isCallingApi) {
        return;
      }
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
      changeCurrentPage(1);
    },
    [sortConfig, changeCurrentPage, isCallingApi],
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setErrorType(null);
  };

  /**
   * 端末情報更新状態をリセット
   */
  const resetDeviceDetailUpdateState = (): void => {
    // 端末情報更新状態が初期値ではない場合はリセット
    if (
      updateState.deviceDetailUpdateState !== DEVICE_DETAIL_UPDATE_STATE.PENDING
    ) {
      deviceDetailUpdateStateReset();
    }
  };

  /**
   * [翻訳履歴]ダイアログの［閉じる］ボタンまたはダイアログ外がクリックされた場合の処理
   */
  const onClickCloseModal = (): void => {
    // ダイアログ閉じる
    setIsShowTransHistoryDaialog(false);

    // 端末情報が更新された場合のみ一覧更新
    if (
      updateState.deviceDetailUpdateState === DEVICE_DETAIL_UPDATE_STATE.SUCCESS
    ) {
      setDisplayContentState({
        ...displayContentState,
        apiCallState: API_CALL_STATE.pending,
      });

      return;
    }
    // 端末情報更新状態をリセット
    resetDeviceDetailUpdateState();
  };

  /**
   * オリジナルの端末一覧情報と、フィルタリング用の端末一覧情報内の
   * 指定されたIMEIと一致する要素を
   * 新しい端末情報に差し替え
   *
   * @param imieKey 更新対象の要素の key となるIMEI
   * @param updateDeviceInfo 新しい端末情報
   *
   */
  // const replaceDataAndSimInfo = (
  //   imieKey: string,
  //   updateDeviceInfo: DeviceInfo,
  // ): void => {
  //   // オリジナルの端末一覧情報内の該当データを新しい端末情報に差し替え
  //   if (originalDeviceInfos.current.length > 0) {
  //     const newOriginalDatas: DeviceInfoData[] = spliceDeviceInfo(
  //       imieKey,
  //       { [imieKey]: updateDeviceInfo },
  //       originalDeviceInfos.current,
  //     );
  //     originalDeviceInfos.current = newOriginalDatas;
  //   }

  //   // Replace the corresponding data of the terminal list information for filtering with a new terminal information
  //   if (filterDeviceInfos.current.length > 0) {
  //     const newFilterDatas: DeviceInfoData[] = spliceDeviceInfo(
  //       imieKey,
  //       { [imieKey]: updateDeviceInfo },
  //       filterDeviceInfos.current,
  //     );
  //     filterDeviceInfos.current = newFilterDatas;
  //   }

  //   // 表示中の該当行のSIM情報更新
  //   setCurrentPageDataRows((state) =>
  //     spliceDeviceTableRowData(imieKey, updateDeviceInfo, state),
  //   );
  // };

  const updateSimInfo = (targetDeviceInfo: DeviceInfo): void => {
    if (imeiQueueForSimInfo.current.indexOf(targetDeviceInfo.imei) >= 0) {
      return;
    }
    const imeiKey: string = targetDeviceInfo.imei;
    if (
      isFetchedeExpiredt(
        imeiKey,
        originalDeviceInfos.current,
        expireDtStateType,
      )
    ) {
      return;
    }

    imeiQueueForSimInfo.current.push(imeiKey);

    // SIM情報取得処理実行
    // void findSimInfo(accessKey, targetDeviceInfo, expireDtStateType)
    //   .then((simInfoData: SimInfoData) => {
    //     // 取得したSIM情報
    //     const newSimInfo = simInfoData.simInfo;
    //     // 取得失敗
    //     if (newSimInfo === null) {
    //       // 内蔵SIM期限日を空欄に更新
    //       targetDeviceInfo.expiredt = '';
    //       // 保持している端末一覧情報内の該当データを、更新後の情報に差し替え
    //       replaceDataAndSimInfo(imeiKey, targetDeviceInfo);

    //       return;
    //     }

    //     // 取得に成功
    //     const updateInfo = targetDeviceInfo;

    //     // ICCIDが空だったら差し替え
    //     if (!updateInfo.iccid) {
    //       updateInfo.iccid = newSimInfo.iccid;
    //     }
    //     // 表示中の内蔵SIM期限日を更新
    //     updateInfo.expiredt = newSimInfo.expiredt;
    //     // 保持している端末一覧情報内の該当データを、更新後の情報に差し替え
    //     replaceDataAndSimInfo(updateInfo.imei, updateInfo);
    //   })
    //   .finally(() => {
    //     // finally の中で、呼び出し完了したらキューから消す（呼び出し完了）
    //     const index = imeiQueueForSimInfo.current.indexOf(imeiKey);
    //     if (index >= 0) {
    //       imeiQueueForSimInfo.current.splice(index, 1);
    //     }
    //   });
  };

  // Group selection
  const handleSelectGroup = (grouInfo: DeviceGroupInfo) => {
    setSelectOpen(false);
    setSelectedGroupId(grouInfo.groupId);
    if (totalGroup?.length > 0) {
      setTotalGroup((prevState) => prevState.slice(0, groupRowNumber));
    }
    setGroupFilterPageNumber(0);
    setGroupSearchText('');
    changeCurrentPage(1);
    setSelectedGroup(grouInfo.groupName);
    // GA tag
    sendGAEvent('select_list', 'select_group_list_value', grouInfo.groupName);
  };

  // Searching on group select tag
  // let timeOutId = 0;
  const handleDropDownSearch = (data: React.ChangeEvent<HTMLInputElement>) => {
    if (!data.target) {
      return;
    }
    // if (timeOutId === 0) {
    const timeOutId = setTimeout(() => {
      setGroupSearchText(data.target.value.toLowerCase());
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeOutId);
    // }
  };

  // Select tag status toggling
  const handleSelectGroupOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    setTotalGroup((prevState) => prevState.slice(0, groupRowNumber));
    setGroupFilterPageNumber(0);
    if (selectOpen) {
      setGroupSearchText('');
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
  };

  // select box click status
  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSelectAnchor(event.currentTarget);
    if (selectOpen) {
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_group_list');
  };

  // Resetting the group status to All
  const resetGroup = () => {
    setSelectedGroup('All');
  };
  // Scrolling groups in group dropdown filter
  const onGroupListScroll = () => {
    setGroupFilterPageNumber((prevState) => {
      const maxPageNumber = Math.floor(
        groupTotalFetchData.current / groupRowNumberPerPage.current,
      );
      if (maxPageNumber > prevState) {
        return prevState + 1;
      }

      return prevState;
    });
  };
  // useEffect(() => {
  //   if (!allowTransition()) {
  //     return () => ({});
  //   }
  //   window.history.pushState(null, '', location.pathname);
  //   window.addEventListener('popstate', browserBackBlockFunction);

  //   return () => {
  //     window.removeEventListener('popstate', browserBackBlockFunction);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!canFetchSimInfo) {
      return;
    }
    if (
      originalDeviceInfos.current.length <= 0 ||
      currentPageDataRows.length <= 0
    ) {
      return;
    }

    Object.entries(currentPageDataRows).forEach((entry) => {
      const [, tableRowData] = entry;
      if (
        isFetchedeExpiredt(
          tableRowData.id,
          originalDeviceInfos.current,
          expireDtStateType,
        )
      ) {
        return;
      }
      const deviceInfoData: DeviceInfoData | null = findDeviceInfoData(
        tableRowData.id,
        originalDeviceInfos.current,
      );
      if (deviceInfoData !== null) {
        void updateSimInfo(deviceInfoData[tableRowData.id]);
      }
    });

    // updateSimInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canFetchSimInfo, currentPageDataRows]);

  const restoreFilter = (): void => {
    if (filterInputData.searchText !== '') {
      onClickFilterSearch({
        searchId: filterInputData.searchText,
        searchText: filterInputData.searchText,
      });

      return;
    }
    resetDeviceDetailUpdateState();
  };

  useEffect(() => {
    setIsCallingApi(true);
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false;

      return;
    }
    fetchDeviceList(
      accessKey,
      expireDtStateType,
      currentRowNumberPerPage,
      currentPage - 1,
      sortConfig.direction === 'NONE' ? '' : sortConfig.direction.toLowerCase(),
      filterInputData.searchText,
      sortKeyMap[sortConfig.key],
      selectedGroupId,
    )
      .then((result: DeviceListResult) => {
        if (result.resultCode === SERVER_MESSAGE.NO_INTERNET) {
          displaySnackbar({
            message: t('common.error.noInternet'),
            type: 'error',
            timeout: 5000,
          });
        }

        const list: DeviceInfoData[] = result.devices;
        const tableRowDataArray: TableRowData[] =
          convertDeviceInfoArrayToTableRowDataArray(list);
        setCanFetchSimInfo(true);
        originalDeviceInfos.current = result.devices;
        totalData.current = result.totalDeviceCount;
        setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
        setErrorType(result.resultCode);
        setPageDataIndexTo(
          currentPage * currentRowNumberPerPage > totalData.current
            ? totalData.current
            : currentPage * currentRowNumberPerPage,
        );
        setTotalListCount(result.totalDeviceCount);
        changeTotalPages(
          Math.ceil(totalData.current / currentRowNumberPerPage),
        );
        setCurrentPageDataRows(tableRowDataArray);
      })
      .catch((err) => {
        setTotalListCount(0);
        setCurrentPageDataRows([]);
        changeTotalPages(1);
        changeCurrentPage(1);
        resetDeviceDetailUpdateState();
        setErrorType(err.resultCode);
      })
      .finally(() => {
        setIsCallingApi(false);
        restoreFilter();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // accessKey,
    // accountInfo.email,
    // changeTotalPages,
    currentPage,
    currentRowNumberPerPage,
    selectedGroupId,
    listSearchText,
    // displayContentState,
    sortConfig,
  ]);

  useEffect(() => {
    changeCurrentPage(1);
    setListSearchText(filterInputData.searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputData.searchText]);

  useEffect(() => {
    setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
    setPageDataIndexTo(
      currentPage * currentRowNumberPerPage > totalData.current
        ? totalData.current
        : currentPage * currentRowNumberPerPage,
    );
  }, [currentPage, currentRowNumberPerPage]);

  // Resetting Search data
  const searchDataApiCall = useCallback(() => {
    if (!stopInitialSearch.current) {
      stopInitialSearch.current = true;
      return;
    }
    if (groupFilterPageNumber !== 0) {
      setGroupFilterPageNumber(0);
    }
    setIsGroupListApiCalling(true);
    fetchDeviceGroupList(groupRowNumberPerPage.current, 0, groupSearchText)
      .then((result: DeviceGroupListApiResponse) => {
        if (result?.details) {
          setTotalGroup(result?.details?.groups);
          groupTotalFetchData.current = result.details.totalCount;
        }
      })
      // .catch((error) => console.log(error, 'Error'))
      .finally(() => {
        setIsGroupListApiCalling(false);
      });
    // eslint-disable-next-line
  }, [groupSearchText]);

  // eslint-disable-next-line
  useEffect(() => searchDataApiCall(), [groupSearchText]);

  // Adding more group list options
  useEffect(() => {
    setIsGroupListApiCalling(true);
    fetchDeviceGroupList(
      groupRowNumberPerPage.current,
      groupFilterPageNumber,
      groupSearchText,
    )
      .then((result: DeviceGroupListApiResponse) => {
        // if (result?.message === SERVER_MESSAGE.USER_ROLE_CHANGED) {
        //   displayRedirectDialog({
        //     open: true,
        //     title: 'Not authorized',
        //     message: 'Your role has been changed. Please re-login.',
        //     routePath: onClickLogoff,
        //   });
        // }
        if (result?.details) {
          setTotalGroup((prevState) => [
            ...prevState,
            ...result?.details?.groups,
          ]);
          groupTotalFetchData.current = result.details.totalCount;
        }
      })
      // .catch((error) => console.log(error, 'Error'))
      .finally(() => setIsGroupListApiCalling(false));
    // eslint-disable-next-line
  }, [groupFilterPageNumber]);

  return {
    errorType,
    errorMessage,
    t,
    isCallingApi,
    onClickLogoutModal,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    onClickImeiLink,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      isGroupListApiCalling,
      onGroupListScroll,
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onClickFirstPage,
      onClickLastPage,
      onChangeRowNumber,
      changeTotalPages,
      pageDataIndexFrom,
      pageDataIndexTo,
    },
    transHistoryDaialogValue: {
      isShowTransHistoryDaialog,
      deviceDitailImei,
      onClickCloseModal,
    },
    totalGroup,
    selectedGroup,
    selectOpen,
    selectAnchor,
    handleSelectGroup,
    handleDropDownSearch,
    handleSelectGroupOpen,
    handleSelectBoxClick,
    resetGroup,
  };
};

export default useDeviceList;
