import { useState } from 'react';
import type {
  OnetimeCheckApiRequestParameter,
  OnetimeCheckApiResponse,
} from '../types/apis/onetimeCheckApi';
import callOnetimeCheckApi, {
  ONETIME_CHECK_API_RESULT_CODE,
} from '../apis/callOnetimeCheckApi';
import { URL_PARAMETER_KEY } from '../constants/constants';

/**
 * Class type (specified functions, etc. to be Public)
 */
export type UseCheckOnetimeValue = {
  // Check if a one -time key is effective (true = valid/false = invalid)
  checkValidOnetime: (params: URLSearchParams) => Promise<void>;
  // An effective check result of one -time key
  onetimeValidCheckResult: ONETIME_CHECK_STATE_TYPE;
  // One -time key
  onetimeKey: string;
  // E -mail address returned from the one -time key confirmation API
  mailAddress: string;
};

/**
 * One -time key valid check state
 */
export const ONETIME_CHECK_STATE = {
  // Before checking
  BEFORE_VALID_CHECK: 'BEFORE_VALID_CHECK',
  // After checking: Valid
  VALID: 'VALID',
  // After check: Invalid
  INVALID: 'INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
} as const;

/**
 * One -time key valid check state type
 */
export type ONETIME_CHECK_STATE_TYPE =
  typeof ONETIME_CHECK_STATE[keyof typeof ONETIME_CHECK_STATE];

/**
 * One -time key effect confirmation HOOKS
 * If it is valid, hold the e -mail address returned from the one -time key and the API.
 *
 * @returns
 */
const useCheckOnetime = (): UseCheckOnetimeValue => {
  // Effective state of one -time key GET/SET
  const [onetimeValidCheckResult, setOnetimeValidCheckResult] =
    useState<ONETIME_CHECK_STATE_TYPE>(ONETIME_CHECK_STATE.BEFORE_VALID_CHECK);
  // One -time key (set when valid)
  const [onetimeKey, setOnetimeKey] = useState<string>('');
  // An email address returned from the one -time key valid confirmation API (set when the one -time key is valid)
  const [mailAddress, setMailAddress] = useState<string>('');

  /**
   * One -time key check
   *
   * @param targetOnetimekey One -time key to check
   */
  const checkOnetime = async (targetOnetimekey: string): Promise<void> => {
    // Account registration API request transmission
    try {
      const requestParameter: OnetimeCheckApiRequestParameter = {
        onetimekey: targetOnetimekey,
      };
      const apiResponse: OnetimeCheckApiResponse = await callOnetimeCheckApi(
        requestParameter,
      );
      if (
        apiResponse.message ===
        ONETIME_CHECK_API_RESULT_CODE.SUCCESSFULLY_FETCHED
      ) {
        // One -time key check status: Valid
        setOnetimeValidCheckResult(ONETIME_CHECK_STATE.VALID);
        // Holding one -time key
        setOnetimeKey(targetOnetimekey);
        // Email address returned from the API
        setMailAddress(apiResponse.details);

        return;
      }
      if (apiResponse.message === ONETIME_CHECK_API_RESULT_CODE.INFO_EXPIRED) {
        setOnetimeValidCheckResult(ONETIME_CHECK_STATE.INFO_EXPIRED);

        return;
      }
      switch (apiResponse.message) {
        case ONETIME_CHECK_API_RESULT_CODE.INFO_INVALID:
          throw ONETIME_CHECK_API_RESULT_CODE.INFO_INVALID;
        case ONETIME_CHECK_API_RESULT_CODE.INFO_EXPIRED:
          throw ONETIME_CHECK_API_RESULT_CODE.INFO_EXPIRED;
        case ONETIME_CHECK_API_RESULT_CODE.WARN_INPUT_PARAM:
          throw ONETIME_CHECK_API_RESULT_CODE.WARN_INPUT_PARAM;
        default:
          throw ONETIME_CHECK_API_RESULT_CODE.ERR_UNKNOWN;
      }
    } catch (error) {
      // One -time key check status: Disable
      // If it is returned except for OK from the API, it will be uniformly invalidated
      setOnetimeValidCheckResult(ONETIME_CHECK_STATE.INVALID);
    }
  };

  /**
   * Check whether one -time key is effective and set the result
   * In the case of an effective one -time key, hold the one -time key
   *
   * @param params: URL parameter information
   */
  const checkValidOnetime = async (params: URLSearchParams): Promise<void> => {
    // One -time key check status: Checking
    setOnetimeValidCheckResult(ONETIME_CHECK_STATE.BEFORE_VALID_CHECK);
    // 初期化
    setOnetimeKey('');

    // Obtain a one -time key contained in the URL parameters
    const onetimekeyValue: string | null = params.get(
      URL_PARAMETER_KEY.onetimekey,
    );
    if (!onetimekeyValue) {
      // Disable because the parameters were not specified by one -time key
      setOnetimeValidCheckResult(ONETIME_CHECK_STATE.INVALID);

      return;
    }

    // One -time key effective check process execution
    await checkOnetime(onetimekeyValue);
  };

  return {
    checkValidOnetime,
    onetimeValidCheckResult,
    onetimeKey,
    mailAddress,
  };
};

export default useCheckOnetime;
