import callAccountRegistrationInvitationApi from '../../apis/callAccountRegistrationInvitationApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import {
  AccountRegisterInvitationApiRequestParameter,
  AccountRegisterInvitationApiResponse,
} from '../../types/apis/accountRegistrationInvitation.d';
import { isErrorType } from '../../types/apis/apiErrorTypeGuard';

export const accountRegistrationInvitation = async (
  mailAddress: string,
  localeCode: string,
): Promise<AccountRegisterInvitationApiResponse> => {
  try {
    const parameter: AccountRegisterInvitationApiRequestParameter = {
      mailAddress,
      localeCode,
    };
    const apiResponse: AccountRegisterInvitationApiResponse =
      await callAccountRegistrationInvitationApi(parameter);
    // console.log(apiResponse.message, 'apiResponse.message');
    switch (apiResponse.message) {
      case SERVER_MESSAGE.SUCCESSFULLY_SEND_MAIL:
        return { message: SERVER_MESSAGE.SUCCESSFULLY_SEND_MAIL };
      case SERVER_MESSAGE.WARN_INPUT_PARAM:
        return { message: SERVER_MESSAGE.WARN_INPUT_PARAM };
      case SERVER_MESSAGE.INFO_ALREADY_INVITED:
        return { message: SERVER_MESSAGE.INFO_ALREADY_INVITED };
      // case SERVER_MESSAGE.INFO_ALREADY_REGISTERED:
      //   throw SERVER_MESSAGE.INFO_ALREADY_REGISTERED;
      case SERVER_MESSAGE.USER_ALREADY_REGISTERED:
        throw SERVER_MESSAGE.USER_ALREADY_REGISTERED;
      case SERVER_MESSAGE.REGISTRATION_SEND_MESSAGE_FAILED:
        throw SERVER_MESSAGE.REGISTRATION_SEND_MESSAGE_FAILED;
      case SERVER_MESSAGE.INFO_ALREADY_REGISTERED:
        return { message: SERVER_MESSAGE.INFO_ALREADY_REGISTERED };
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    // console.log(error, 'error-utils');
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
    };
  }
};

export const kits = () => {
  console.log('test');
};
