// import usePasswordSetComplete from '../../hooks/usePasswordSetComplete';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import PasswordSetSuccessCard from '../Atoms/PasswordSetSuccessCard';

/**
 * パスワード設定完了画面コンポーネント
 */
const PasswordReSetComplete = (): JSX.Element => {
  // usePasswordSetComplete();
  console.log('test');
  const { t } = useSwitchLocaleLanguage();

  return (
    <PasswordSetSuccessCard
      cardText={t('resetPassword.passwordResetComplete.resetSuccess')}
    />
  );
};

export default PasswordReSetComplete;
