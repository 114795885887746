import React from 'react';
import { Container, CssBaseline, Box, Typography, Grid } from '@mui/material';
import { useStyles } from './CommonStyle';
// import BreadcrumbHome from '../Modecules/BreadcrumbHome';

type Props = {
  children: React.ReactNode;
  titleText: string;
};

const PersonalSettingsTemplate = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      id="groupPageTemplateRoot"
      className={classes.root}
      style={{ position: 'relative', height: '100%' }}
    >
      <CssBaseline />

      <Container
        sx={{
          padding: '40px 0px 0px 0px !important',
          '@media screen and (max-width:1336px)': {
            padding: '40px 24px 0px 24px !important',
          },
          maxWidth: '1250px !important',
        }}
      >
        <Grid container direction="column" pb="25px">
          <Typography
            sx={{
              pt: '4px',
              fontSize: '20px',
              lineHeight: '20px',
              height: '29px',
              fontWeight: 'bold',
            }}
          >
            {props.titleText}
          </Typography>
        </Grid>
        <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {props.children}
        </Box>
      </Container>
    </div>
  );
});

export default PersonalSettingsTemplate;
