/* eslint-disable */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ALL_ERROR_TYPE,
  SERVER_MESSAGE,
  PAGE_PATH_NAME,
  URL_PARAMETER_KEY,
} from '../constants/constants';
import { accountRegistrationInvitation } from '../utils/account/accountRegistrationInvitation';
import type {
  AccountRegisterInvitationApiRequestParameter,
  AccountRegisterInvitationApiResponse,
} from '../types/apis/accountRegistrationInvitation';
import useSnackbar from './useSnackbar';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { TFunction } from 'react-i18next';
import useLocale from './useLocale';

export type mailAddressStateProps = {
  mailAddress: string;
};
/* eslint-disable */
export type UseAccountRegistrationInvitationValue = {
  registraionInvitationMessage: ALL_ERROR_TYPE | null;
  isCallingRegistrationInvitationApi: boolean;
  t: TFunction;
  callAccountRegistrationInvitationApi: ({
    mailAddress,
    localeCode,
  }: AccountRegisterInvitationApiRequestParameter) => void;
};

const useAccountRegistrationInvitation =
  (): UseAccountRegistrationInvitationValue => {
    const [
      isCallingRegistrationInvitationApi,
      setIsCallingRegistrationInvitationApi,
    ] = useState<boolean>(false);
    const [registraionInvitationMessage, setRegistraionInvitationMessage] =
      useState<ALL_ERROR_TYPE | null>(null);
    const navigate = useNavigate();
    const { displaySnackbar } = useSnackbar();
    const { t } = useSwitchLocaleLanguage();
    // web browser's language (for granting URL parameters when screen transition)
    const { browserLanguage } = useLocale();

    const callAccountRegistrationInvitationApi = ({
      mailAddress,
      localeCode,
    }: AccountRegisterInvitationApiRequestParameter): void => {
      setIsCallingRegistrationInvitationApi(true);

      accountRegistrationInvitation(mailAddress.trim(), localeCode)
        .then((result: AccountRegisterInvitationApiResponse) => {
          // console.log(result.message, 'result.message');
          if (result.message === SERVER_MESSAGE.SUCCESSFULLY_SEND_MAIL) {
            setRegistraionInvitationMessage(result.message);

            // STATE passed
            const sendMailCompleteState: mailAddressStateProps = {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              mailAddress: mailAddress,
            };
            navigate(
              `${PAGE_PATH_NAME.ACCOUNT_RRGISTED_SEND_MAIL_COMPLETE}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
              {
                state: sendMailCompleteState,
              },
            );
            return;
          } else if (
            result.message === SERVER_MESSAGE.INFO_ALREADY_REGISTERED
          ) {
            displaySnackbar({
              message: t(
                'emailSendCard.accountCreateSendMail.apiErrors.alreadyRegistered',
              ),
              type: 'error',
            });
          } else if (result.message === SERVER_MESSAGE.INFO_ALREADY_INVITED) {
            displaySnackbar({
              message: t(
                'emailSendCard.accountCreateSendMail.apiErrors.alreadySent',
              ),
              type: 'error',
            });
          } else if (result.message === SERVER_MESSAGE.WARN_INPUT_PARAM) {
            displaySnackbar({
              message: t(
                'emailSendCard.accountCreateSendMail.apiErrors.wrongInput',
              ),
              type: 'error',
            });
          } else {
            // displaySnackbar({
            //   message: 'Server Error.',
            //   type: 'error',
            // });
          }
          setRegistraionInvitationMessage(result.message);
        })
        .catch((error: ALL_ERROR_TYPE | unknown) => {
          // console.log(error, 'error');
          if (isErrorType(error)) {
            if (error === SERVER_MESSAGE.INFO_ALREADY_REGISTERED) {
              displaySnackbar({
                message: t(
                  'emailSendCard.accountCreateSendMail.apiErrors.alreadyRegistered',
                ),
                type: 'error',
              });
            }
          } else {
            // displaySnackbar({
            //   message: 'Server Error.',
            //   type: 'error',
            // });
          }
        })
        .finally(() => {
          setIsCallingRegistrationInvitationApi(false);
        });
    };

    return {
      callAccountRegistrationInvitationApi,
      registraionInvitationMessage,
      isCallingRegistrationInvitationApi,
      t,
    };
  };

export default useAccountRegistrationInvitation;
