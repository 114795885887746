/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  // Container,
  Button,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/base/ClickAwayListener';
// import { Link } from 'react-router-dom';
// import PageTemplate from '../Templates/PageTemplate';
import type { AccountInputForm } from '../../types/inputs/accountInput';
import useAccount from '../../hooks/useAccount';
import { ONETIME_CHECK_STATE } from '../../hooks/useCheckOnetime';
import {
  EMAIL_CHECK_PATTERN,
  USER_PASSWORD_PATTERN,
} from '../../constants/constants';
import PageLoadingLayer from '../Templates/PageLoadingLayer';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import { ReactComponent as RedirectIcon } from '../../images/icon/redirect_icon.svg';
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';
import PasswordStrengthCard from '../Atoms/PasswordStrengthCard';
import passwordStrengthCheckUtil from '../../utils/user/passwordStrengthCheckUtil';
import PasswordStrengthText from '../Atoms/PasswordStrengthText';
import Loader from '../Atoms/Loader';
import RegistrationExpiredLinkCard from '../Atoms/RegistrationExpiredLinkCard';
import useEulaGetLink from '../../hooks/useEulaGetLink';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import DisabledInputField from '../Atoms/DisabledInputField';
/**
 * アカウント登録画面コンポーネント
 * 画面内に「エラーメッセージコンポーネント」を表示
 */
const Account = (): JSX.Element => {
  const { localeLanguage } = useLocale();
  // 共通スタイル
  const classes = useStyles();
  // バリデーションフォーム作成
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
    clearErrors,
    // register,
    // getValues,
  } = useForm<AccountInputForm>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
    mode: 'onChange',
  });

  // アカウント登録処理hooks
  const {
    onClickRegist,
    onetimeValidCheckResult,
    mailAddress,
    t,
    isCallingApi,
  } = useAccount();

  const { onClickEulaApi, eulaValue, isCallingEulaApi } = useEulaGetLink();

  const handleClickAwayNameField = () => {
    clearErrors('name');
  };

  const handleClickAwayPasswordField = () => {
    clearErrors('password');
  };

  const handleClickAwayCompanyField = () => {
    clearErrors('company');
  };

  const [showPassword, setShowPassword] = useState(false);
  // Toggle Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'display_icon');
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const passwordFormValue = watch('password')?.toString();
  const nameFormValue = watch('name')?.toString();
  const companyFormValue = watch('company')?.toString();
  // const privacyPolicy = watch('privacyPolicy');
  const [activateNameCloseIcon, setActivateNameCloseIcon] = useState(
    !!nameFormValue,
  );
  const [activateCompanyCloseIcon, setActivateCompanyCloseIcon] = useState(
    !!companyFormValue,
  );

  const [focusedName, setFocusedName] = useState(false);
  const onFocusName = () => {
    setFocusedName(true);

    // GA tag
    sendGAEvent('edit_txt_char', 'txt_name', 'input_name');
  };
  const onBlurName = () => {
    setFocusedName(false);
    setActivateNameCloseIcon(false);
  };

  const [focusedCompany, setFocusedCompany] = useState(false);
  const onFocusCompany = () => {
    setFocusedCompany(true);

    // GA tag
    sendGAEvent('edit_txt_char', 'txt_name', 'input_corprate_name');
  };
  const onBlurCompany = () => {
    setFocusedCompany(false);
    setActivateCompanyCloseIcon(false);
  };

  const [passwordStrength, setPasswordStrength] = useState('');
  const [checkedCheckbox, setCheckedCheckbox] = useState(false);
  const [activeSubmitButton, setActiveSubmitButton] = useState(false);

  useEffect(() => {
    setActiveSubmitButton(
      !!passwordFormValue && !!nameFormValue && !!companyFormValue,
    );
  }, [passwordFormValue, nameFormValue, companyFormValue]);

  useEffect(() => {
    if (nameFormValue?.length) {
      setActivateNameCloseIcon(true);
    } else {
      setActivateNameCloseIcon(false);
    }
  }, [nameFormValue]);

  useEffect(() => {
    if (companyFormValue?.length) {
      setActivateCompanyCloseIcon(true);
    } else {
      setActivateCompanyCloseIcon(false);
    }
  }, [companyFormValue]);

  useEffect(() => {
    if (passwordFormValue?.length === 0) {
      setPasswordStrength('');
    }
    if (passwordFormValue?.length < 7) {
      setPasswordStrength('');
    }
    if (passwordFormValue && passwordFormValue.length > 7) {
      const strength = passwordStrengthCheckUtil(passwordFormValue);
      setPasswordStrength(strength);
    }
  }, [passwordFormValue]);

  // An email address returned from the one -time key valid confirmation API in the input field
  useEffect(() => {
    setValue('email', mailAddress);
    // eslint-disable-next-line
  }, [mailAddress]);

  useEffect(() => {
    onClickEulaApi();
    // eslint-disable-next-line
  }, []);

  const validateUserName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('common.validationError.name.maxLength');
    }

    return true;
  };

  const validateCompanyName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('account.validationError.company.maxLength');
    }

    return true;
  };
  switch (onetimeValidCheckResult) {
    case ONETIME_CHECK_STATE.BEFORE_VALID_CHECK:
      return (
        // ワンタイムキーチェック中はローディングバーを表示
        <PageLoadingLayer />
      );
    case ONETIME_CHECK_STATE.VALID:
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Box
            sx={{
              padding:
                localeLanguage === LOCALE_CODE.DE
                  ? '32px 27.5px!important'
                  : '32px',
            }}
            className={classes.registrationCard}
          >
            <Typography className={classes.registrationCardTitle}>
              {t('account.viewName')}
            </Typography>
            <form
              onSubmit={handleSubmit((inpudData: AccountInputForm) => {
                onClickRegist(inpudData, {
                  privacyPolicyId: eulaValue.privacyPolicyId,
                  termsOfUseId: eulaValue.termsOfUseId,
                });
              })}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography className={classes.registrationCardLevel}>
                  {t('account.label.name')}
                </Typography>
                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              <ClickAwayListener onClickAway={handleClickAwayNameField}>
                <Box sx={{ position: 'relative' }}>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: true,
                      validate: validateUserName,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        value={field.value || ''}
                        fullWidth
                        margin="normal"
                        placeholder={t('account.placeholder.name')}
                        autoFocus
                        error={Boolean(errors.name)}
                        autoComplete="off"
                        onMouseOver={() => {
                          if (nameFormValue?.length) {
                            setActivateNameCloseIcon(true);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!focusedName) {
                            setActivateNameCloseIcon(false);
                          }
                        }}
                        onFocus={onFocusName}
                        onBlur={onBlurName}
                        inputProps={{
                          maxLength: 257,
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="close"
                                onClick={() => {
                                  setValue('name', '');
                                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                  trigger('name');
                                  setActivateNameCloseIcon(false);
                                }}
                                onMouseDown={handleMouseDown}
                                edge="end"
                                sx={{
                                  p: '8px',
                                  marginLeft: '8px',
                                  marginRight: '-8px',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                  },
                                  display: activateNameCloseIcon ? '' : 'none',
                                }}
                                disableRipple
                              >
                                <CloseIcon
                                  style={{
                                    width: '9.01px',
                                    height: '9.01px',
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        className={classes.loginFormInput}
                        sx={{
                          marginBottom: '12px',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                              errors.name?.types?.required ||
                              errors.name?.types?.validate
                                ? '#E1341E !important'
                                : '#939598 !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            '&.Mui-focused fieldset': {
                              border:
                                errors.name?.types?.required ||
                                errors.name?.types?.validate
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {(errors.name?.types?.required ||
                    errors.name?.types?.validate) && (
                    <Box
                      sx={{ bottom: '57px !important' }}
                      className={classes.errorCard}
                    >
                      <p style={{ padding: '0', margin: '0' }}>
                        {(errors.name?.types?.required &&
                          t('common.validationError.name.empty')) ||
                          (errors.name?.types?.validate && errors.name.message)}
                      </p>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>

              <Box>
                <Typography className={classes.registrationCardLevel}>
                  {t('account.label.email')}
                </Typography>
              </Box>

              <Box sx={{ position: 'relative' }}>
                <DisabledInputField text={mailAddress} />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.registrationCardLevel}>
                  {t('account.label.password')}
                  <span className={classes.loginCardLevelMarker}>*</span>
                </Typography>
                <PasswordStrengthText passwordStrength={passwordStrength} />
              </Box>

              <ClickAwayListener onClickAway={handleClickAwayPasswordField}>
                <Box sx={{ position: 'relative' }}>
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: true,
                      minLength: 8,
                      maxLength: 16,
                      pattern: {
                        value: USER_PASSWORD_PATTERN,
                        message: t(
                          'common.validationError.password.enterValidPass',
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onFocus={() => {
                          // GA tag
                          sendGAEvent('edit_text_pass', 'txt_name', 'password');
                        }}
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ maxLength: 17 }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDown}
                                edge="end"
                                disableRipple
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={field.value || ''}
                        fullWidth
                        margin="normal"
                        placeholder={t('account.label.password')}
                        autoComplete="off"
                        error={Boolean(errors.password)}
                        sx={{
                          'input::-ms-reveal,input::-ms-clear': {
                            display: 'none',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                              errors.password?.types?.required ||
                              errors.password?.types?.minLength ||
                              errors.password?.types?.maxLength ||
                              errors.password?.types?.pattern
                                ? '#E1341E !important'
                                : '#939598 !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            '&.Mui-focused fieldset': {
                              border:
                                errors.password?.types?.required ||
                                errors.password?.types?.minLength ||
                                errors.password?.types?.maxLength ||
                                errors.password?.types?.pattern
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                            },
                          },
                        }}
                        className={classes.loginFormInput}
                      />
                    )}
                  />
                  {(errors.password?.types?.required ||
                    errors.password?.types?.minLength ||
                    errors.password?.types?.maxLength ||
                    errors.password?.types?.pattern) && (
                    <Box className={classes.errorCard}>
                      <p style={{ padding: '0', margin: '0', bottom: '44px' }}>
                        {(errors.password?.types?.required &&
                          t('common.validationError.password.empty')) ||
                          ((errors.password?.types?.minLength ||
                            errors.password?.types?.maxLength) &&
                            t('common.validationError.password.invalid')) ||
                          (errors.password?.types?.pattern &&
                            t(
                              'common.validationError.password.enterValidPass',
                            ))}
                      </p>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
              <PasswordStrengthCard
                passwordStrength={passwordStrength}
                pageType="registration"
              />
              <Box sx={{ marginTop: '6px !important' }}>
                <Typography
                  sx={{
                    height: '37px',
                    textAlign: 'left',
                    color: '#939598',
                    fontSize: '12px',
                    marginBottom: '0px',
                  }}
                >
                  {t('common.validationError.password.invalidFormat')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', marginTop: '12px' }}>
                <Typography className={classes.registrationCardLevel}>
                  {t('account.label.company')}
                </Typography>
                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              <ClickAwayListener onClickAway={handleClickAwayCompanyField}>
                <Box sx={{ position: 'relative' }}>
                  <Controller
                    control={control}
                    name="company"
                    rules={{
                      required: true,
                      validate: validateCompanyName,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        value={field.value || ''}
                        fullWidth
                        margin="normal"
                        placeholder={t('account.placeholder.company')}
                        error={Boolean(errors.company)}
                        autoComplete="off"
                        onMouseOver={() => {
                          if (companyFormValue?.length) {
                            setActivateCompanyCloseIcon(true);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!focusedCompany) {
                            setActivateCompanyCloseIcon(false);
                          }
                        }}
                        onFocus={onFocusCompany}
                        onBlur={onBlurCompany}
                        inputProps={{
                          maxLength: 257,
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="close"
                                onClick={() => {
                                  setValue('company', '');
                                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                  trigger('company');
                                  setActivateNameCloseIcon(false);
                                }}
                                onMouseDown={handleMouseDown}
                                edge="end"
                                sx={{
                                  p: '8px',
                                  marginLeft: '8px',
                                  marginRight: '-8px',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                  },
                                  display: activateCompanyCloseIcon
                                    ? ''
                                    : 'none',
                                }}
                                disableRipple
                              >
                                <CloseIcon
                                  style={{
                                    width: '9.01px',
                                    height: '9.01px',
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                              errors.company?.types?.required ||
                              errors.company?.types?.validate
                                ? '#E1341E !important'
                                : '#939598 !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            '&.Mui-focused fieldset': {
                              border:
                                errors.company?.types?.required ||
                                errors.company?.types?.validate
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                            },
                          },
                        }}
                        className={classes.loginFormInput}
                      />
                    )}
                  />
                  {(errors.company?.types?.required ||
                    errors.company?.types?.validate) && (
                    <Box className={classes.errorCard}>
                      <p style={{ padding: '0', margin: '0' }}>
                        {(errors.company?.types?.required &&
                          t('account.validationError.company.empty')) ||
                          (errors.company?.types?.validate &&
                            errors.company.message)}
                      </p>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>

              <Box sx={{ display: 'flex', marginTop: '12px', align: 'center' }}>
                <Checkbox
                  name="privacyPolicy"
                  checked={checkedCheckbox}
                  disabled={isCallingEulaApi}
                  // ref={register}
                  onClick={() => {
                    setCheckedCheckbox(!checkedCheckbox);
                    if (!checkedCheckbox) {
                      sendGAEvent('select_check', 'btn_name', 'agree');
                    }
                  }}
                  icon={
                    <CheckBox
                      style={{
                        marginRight: '-2px',
                      }}
                    />
                  }
                  checkedIcon={<CheckedCheckBox />}
                  sx={{
                    p: '0px',
                    mr: '8px',
                    height: '18px',
                    width: '18px',
                    marginTop: '2px',
                    '&:hover': {
                      '& svg': {
                        '& g': {
                          fill: checkedCheckbox ? '#007EB4' : '#fff',
                          stroke: checkedCheckbox ? '#009de1' : '#939598',
                        },
                      },
                    },
                  }}
                />
                <Typography align="left" sx={{ fontSize: '14px' }}>
                  {localeLanguage !== LOCALE_CODE.JA &&
                    `${t('account.policy.haveRead')} `}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={eulaValue?.termsOfUse}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.registrationCardLink}
                    onClick={() => {
                      // GA tag
                      sendGAEvent(
                        'press_btn_link',
                        'link_name',
                        'terms_of_service',
                      );
                    }}
                  >
                    {t('account.policy.agreement')}
                    {'  '}
                    <RedirectIcon
                      style={{
                        height: '12px',
                        width: '13px',
                        marginTop: '-3px',
                        marginLeft: '3px',
                      }}
                    />
                  </a>
                  {'   '}
                  {t('account.policy.and')}
                  {'   '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={eulaValue?.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.registrationCardLink}
                    onClick={() => {
                      // GA tag
                      sendGAEvent(
                        'press_btn_link',
                        'link_name',
                        'privacy_policy',
                      );
                    }}
                  >
                    {t('account.policy.policy')}
                    {'  '}
                    <RedirectIcon
                      style={{
                        height: '12px',
                        width: '13px',
                        marginTop: '-3px',
                        marginLeft: '3px',
                      }}
                    />
                  </a>
                  {localeLanguage === LOCALE_CODE.JA &&
                    ` ${t('account.policy.haveRead')}`}
                </Typography>
              </Box>

              <Box>
                <div className={classes.submitButton}>
                  <Button
                    className={classes.submitButtonLogin}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={
                      !(checkedCheckbox && activeSubmitButton) || isCallingApi
                    }
                    sx={{
                      '&:hover': {
                        backgroundColor: '#007EB4 !important',
                      },
                      opacity:
                        checkedCheckbox && activeSubmitButton ? '' : '.5',
                      backgroundColor: '#009DE1 !important',
                    }}
                  >
                    {isCallingApi ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        {t('account.label.registering')}
                        <Loader colorCode="#fff" />
                      </Box>
                    ) : (
                      t('account.label.registButton')
                    )}
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </Box>
      );
    case ONETIME_CHECK_STATE.INFO_EXPIRED:
      return (
        <RegistrationExpiredLinkCard
          text={t('account.accountRegisterApiError.expiredLink')}
        />
      );
    default:
      return (
        <RegistrationExpiredLinkCard
          text={t('account.accountRegisterApiError.invalidLink')}
        />
      );
  }
};

export default Account;
