import { useEffect } from 'react';

import usePasswordResetSendMail from '../../hooks/usePasswordResetSendMail';
import EmailSendCardPasswordReset from '../Modecules/EmailSendCardPasswordReset';
import useCheckTransition from '../../hooks/useCheckTransition';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

/**
 * パスワード再設定メール送信画面
 * @returns
 */
const PasswordResetSendMail = (): JSX.Element => {
  const { allowTransition } = useCheckTransition();
  const { t } = useSwitchLocaleLanguage();

  // パスワード再設定メール送信hooks
  const { onClickSendMail, onClickGoBack, isCallingApi } =
    usePasswordResetSendMail();

  useEffect(() => {
    if (!allowTransition()) {
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <EmailSendCardPasswordReset
      title={t('emailSendCard.forgotPasswordSendMail.title')}
      btnText={t('emailSendCard.forgotPasswordSendMail.sendReqBtn')}
      onClickSendMail={onClickSendMail}
      isCallingApi={isCallingApi}
      onClickGoBack={onClickGoBack}
    />
  );
};

export default PasswordResetSendMail;
