import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store from './state/store';
// import mockServiceWorker from './mocks/browser';
import '@fontsource/noto-sans';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-sans-jp/300.css';
import '@fontsource/noto-sans-jp/500.css';

// mock起動
// if (process.env.NODE_ENV === 'development') {
//   void mockServiceWorker.start();
// }

// ReduxフレームワークのStore利用準備
const persistor = persistStore(store);

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById('root'),
);
