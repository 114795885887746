import { useState } from 'react';

import {
  ALL_ERROR_TYPE,
  //   PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../constants/constants';

import callEulaUpdateApi from '../apis/callEulaUpdateApi';
// import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import type {
  EulaUpdateApiRequestParameter,
  //   EulaUpdateApiResponse,
} from '../types/apis/eulaUpdateApi';
import useAccountInfo from './useAccountInfo';
import useLogout from './useLogout';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

export type UseEulaValue = {
  isCallingEulaUpdateApi: boolean;
  onClickEulaUpdate: (data: EulaUpdateApiRequestParameter) => void;
  message: string;
};

const useEulaUpdate = (): UseEulaValue => {
  const [isCallingEulaUpdateApi, setIsCallingEulaUpdateApi] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { displaySnackbar } = useSnackbar();
  const { saveLatestEulaConsented } = useAccountInfo();
  const { onClickLogoff } = useLogout();
  const { t } = useSwitchLocaleLanguage();

  const onClickEulaUpdate = (data: EulaUpdateApiRequestParameter) => {
    setIsCallingEulaUpdateApi(true);

    void callEulaUpdateApi(data)
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.CREATE_OK: {
            setMessage(apiResponse.message);
            setIsCallingEulaUpdateApi(false);
            saveLatestEulaConsented(true);
            break;
          }
          case SERVER_MESSAGE.USER_ALREADY_CONSENTED: {
            setMessage(apiResponse.message);
            setIsCallingEulaUpdateApi(false);
            saveLatestEulaConsented(true);
            displaySnackbar({
              message: t('eulaLink.errors.alreadyConsented'),
            });
            break;
          }
          case SERVER_MESSAGE.WARN_INPUT_PARAM: {
            displaySnackbar({
              message: t('eulaLink.errors.wrongPolicy'),
              type: 'error',
              timeout: 3000,
            });
            onClickLogoff();
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            break;
          }
          default: {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        setIsCallingEulaUpdateApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error');
        // if (isErrorType(error)) {
        //   setUserDeleteErrorType(error);
        // } else {
        //   setUserDeleteErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        // }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingEulaUpdateApi(false);
      });
  };

  return {
    isCallingEulaUpdateApi,
    onClickEulaUpdate,
    message,
  };
};
export default useEulaUpdate;
