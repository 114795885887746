import React from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { useStyles } from '../Templates/CommonStyle';

type Props = {
  passwordStrength: string;
  pageType?: string | undefined;
};

const PasswordStrengthCard = React.memo<Props>(
  ({ passwordStrength, pageType }): JSX.Element => {
    const classes = useStyles();

    return (
      <Box className={pageType === 'registration' ? classes.regPassStrengthBars : classes.passStrengthBars}>
        {passwordStrength === '' && (
          <>
            <hr
              className={clsx(classes.strengthBarFirst, classes.strengthBar)}
            />
            <hr className={classes.strengthBar} />
            <hr className={classes.strengthBar} />
            <hr className={classes.strengthBar} />
            <hr className={classes.strengthBar} />
            <hr
              className={clsx(classes.strengthBarLast, classes.strengthBar)}
            />
          </>
        )}
        {passwordStrength === 'Weak' && (
          <>
            <hr
              className={clsx(
                classes.strengthBarFirst,
                classes.weakStrengthBar,
              )}
            />
            <hr className={classes.weakStrengthBar} />
            <hr className={classes.strengthBar} />
            <hr className={classes.strengthBar} />
            <hr className={classes.strengthBar} />
            <hr
              className={clsx(classes.strengthBarLast, classes.strengthBar)}
            />
          </>
        )}
        {passwordStrength === 'Good' && (
          <>
            <hr
              className={clsx(
                classes.strengthBarFirst,
                classes.goodStrengthBar,
              )}
            />
            <hr className={classes.goodStrengthBar} />
            <hr className={classes.goodStrengthBar} />
            <hr className={classes.goodStrengthBar} />
            <hr className={classes.strengthBar} />
            <hr
              className={clsx(classes.strengthBarLast, classes.strengthBar)}
            />
          </>
        )}
        {passwordStrength === 'Strong' && (
          <>
            <hr
              className={clsx(
                classes.strengthBarFirst,
                classes.strongStrengthBar,
              )}
            />
            <hr className={classes.strongStrengthBar} />
            <hr className={classes.strongStrengthBar} />
            <hr className={classes.strongStrengthBar} />
            <hr className={classes.strongStrengthBar} />
            <hr
              className={clsx(
                classes.strengthBarLast,
                classes.strongStrengthBar,
              )}
            />
          </>
        )}
      </Box>
    );
  },
);

export default PasswordStrengthCard;
