import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as EmaiSendSuccess } from '../../images/icon/emai_send_success.svg';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../../constants/constants';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';
import useAccessKey from '../../hooks/useAccessKey';
import useLocale from '../../hooks/useLocale';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  cardText: string;
};

const PasswordSetSuccessCard = React.memo<Props>(
  ({ cardText }): JSX.Element => {
    const { t } = useSwitchLocaleLanguage();
    // common styles
    const classes = useStyles();
    // web browser's language (for granting URL parameters when screen transition)
    const { browserLanguage } = useLocale();
    // navigate(Screen transition)
    const navigate = useNavigate();

    const { accountoInfoReset } = useAccountInfo();
    const { reset } = useAccessKey();

    const onClickGoBack = () => {
      accountoInfoReset();
      reset();
      navigate(
        `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
      );

      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'back_login_btn');
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Box className={classes.passwordSetSuccessCard}>
          <EmaiSendSuccess />
          <Typography
            sx={{
              marginTop: '12px',
              fontSize: '14px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            {cardText}
          </Typography>
          <Button
            className={classes.submitButtonLogin}
            fullWidth
            size="large"
            variant="contained"
            onClick={onClickGoBack}
          >
            {t('resetPassword.passwordSetComplete.button')}
          </Button>
        </Box>
      </Box>
    );
  },
);

export default PasswordSetSuccessCard;
