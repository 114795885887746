import { useForm } from 'react-hook-form';
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import PageTemplate from '../Templates/PageTemplate';
import { useStyles } from '../Templates/CommonStyle';
import useAccountRegisted from '../../hooks/useAccountRegisted';
import '../../locales/locales';
import styles from './AccountRegisted.module.scss';

/**
 * アカウント登録完了画面 コンポーネント
 *
 * @returns
 */
const AccountRegisted = (): JSX.Element => {
  const classes = useStyles();
  // アカウント登録完了 hooks
  const { onClickLogin, t, accountRegistedPageState, loginUrl } =
    useAccountRegisted();

  // バリデーションフォーム作成
  const { handleSubmit } = useForm({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  return (
    <PageTemplate title={t('accountRegisted.viewName')}>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onClickLogin)}>
          <div className={styles['account-registed-message-container']}>
            <div className={styles['account-registed-message']}>
              <Box>
                <Typography
                  align="left"
                  variant="body1"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {t('accountRegisted.label.message1')}
                </Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <Typography
                  align="left"
                  variant="body2"
                  style={{ color: 'red' }}
                >
                  {t('accountRegisted.label.message2')}
                </Typography>
              </Box>
            </div>
          </div>

          <div className={styles['account-registed-content-container']}>
            <div className={styles['account-registed-content']}>
              <Box sx={{ pt: 5 }}>
                <Typography variant="subtitle1">
                  {t('accountRegisted.label.content')}
                </Typography>
                <div>
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.accountRegistedTableGrid}
                  >
                    <Table className={classes.accountRegistedTable}>
                      <TableRow>
                        <TableCell variant="head">
                          {t('accountRegisted.label.email')}
                        </TableCell>
                        <TableCell>
                          {accountRegistedPageState &&
                            accountRegistedPageState.iotAccountMailAddress}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('accountRegisted.label.corpId')}
                        </TableCell>
                        <TableCell>
                          {accountRegistedPageState &&
                            accountRegistedPageState.iotAccountCorpId}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('accountRegisted.label.company')}
                        </TableCell>
                        <TableCell>
                          {accountRegistedPageState &&
                            accountRegistedPageState.iotAccountCompany}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('accountRegisted.label.address')}
                        </TableCell>
                        <TableCell>
                          {accountRegistedPageState &&
                            accountRegistedPageState.iotAccountAddress}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('accountRegisted.label.tel')}
                        </TableCell>
                        <TableCell>
                          {accountRegistedPageState &&
                            accountRegistedPageState.iotAccountTel}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                </div>
              </Box>
            </div>
          </div>

          <Box sx={{ pt: 5 }}>
            <div className={classes.submitButton}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                {t('accountRegisted.label.loginButton')}
              </Button>
            </div>
          </Box>
          <Box sx={{ pt: 1 }}>
            <Typography color="textSecondary" variant="body2">
              {`${t('accountRegisted.label.loginUrl')}${loginUrl}`}
            </Typography>
          </Box>
        </form>
      </Container>
    </PageTemplate>
  );
};

export default AccountRegisted;
