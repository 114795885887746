import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import type { PasswordSetInput } from '../types/inputs/passwordSetInput';
import useCheckOnetime, { ONETIME_CHECK_STATE_TYPE } from './useCheckOnetime';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import { URL_PARAMETER_KEY, PAGE_PATH_NAME } from '../constants/constants';
import { isPasswordResetErrorType } from '../types/apis/apiErrorTypeGuard';
import callPasswordSetApi, {
  PASSWORD_RESET_API_RESULT_CODE,
  PASSWORD_RESET_ERROR_TYPE,
} from '../apis/callPasswordSetApi';
import type { PasswordResetApiRequestParam } from '../types/apis/passwordResetApi';
import useSnackbar from './useSnackbar';
import { sendGAEvent } from './useGAEvent';
import useLocale from './useLocale';

/**
 * [パスワード設定完了]画面遷移時に渡す値
 */
export type PasswordSetCompleteState = {
  iotAccountMailAddress: string;
};

/**
 * 本カスタムフックからの返却値
 */
export type UsePasswordSet = {
  // ［設定］ボタンクリック時処理
  onClickSetting: (inputData: PasswordSetInput) => void;
  // パスワード設定エラータイプ
  passwordSetErrorType: PASSWORD_RESET_ERROR_TYPE | null;
  // エラーメッセージ
  passwordSetErrorMessage: string;
  // ワンタイムキー有効チェック結果
  onetimeValidCheckResult: ONETIME_CHECK_STATE_TYPE;
  // ワンタイムキー有効確認APIから返却されたメールアドレス
  mailAddress: string;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // APIコール中か否か
  isCallingApi: boolean;
  // [ログイン画面を表示]ボタン押下時処理
  onClickLogin: () => void;
};

/**
 * パスワード設定画面 hooks
 *
 * @returns
 */
const usePasswordSet = (): UsePasswordSet => {
  // web browser's & locale language  (for granting URL parameters when screen transition)
  const { browserLanguage, localeLanguage } = useLocale();
  const { displaySnackbar } = useSnackbar();
  // パスワード設定エラータイプ get/set
  const [passwordSetErrorType, setPasswordSetErrorType] =
    useState<PASSWORD_RESET_ERROR_TYPE | null>(null);
  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // navigate(画面遷移)
  const navigate = useNavigate();
  // URLパラメータ取得
  const { search } = useLocation();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  // ワンタイムキー有効確認 hooksクラス
  const {
    checkValidOnetime,
    onetimeValidCheckResult,
    onetimeKey,
    mailAddress,
  } = useCheckOnetime();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // loadingBar表示
    setIsCallingApi(true);
    // ワンタイムキー有効チェック処理を実行
    const urlSearchParams = new URLSearchParams(search);
    checkValidOnetime(urlSearchParams).finally(() => {
      // loadingBar非表示
      setIsCallingApi(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 第二引数に[]を指定してコンポーネントの更新によるuseEffectを止める

  /**
   * ［設定］ボタンクリック処理
   *
   * @param inputData 入力フォームデータ
   */
  const onClickSetting = (inputData: PasswordSetInput): void => {
    // loadingBar表示
    setIsCallingApi(true);

    const passwordResetRequestParam: PasswordResetApiRequestParam = {
      password: inputData.password,
      oneTimeKey: onetimeKey,
    };

    // パスワードリセットAPI呼び出し
    void callPasswordSetApi(passwordResetRequestParam)
      .then((apiResponse) => {
        if (apiResponse.message === PASSWORD_RESET_API_RESULT_CODE.OK_CREATED) {
          // [パスワード設定完了]画面に渡すstate
          const passwordSetCompleteState: PasswordSetCompleteState = {
            iotAccountMailAddress: mailAddress,
          };

          // Transition to the [Password Settings Completed] screen
          // In the browser back, do not return to the [Password Settings] screen
          navigate(
            `${PAGE_PATH_NAME.PASSWORD_SET_COMPLETE}?${URL_PARAMETER_KEY.locale}=${localeLanguage}`,
            { state: passwordSetCompleteState, replace: true },
          );

          return;
        }

        switch (apiResponse.message) {
          case PASSWORD_RESET_API_RESULT_CODE.WARN_INPUT_PARAM:
            throw PASSWORD_RESET_API_RESULT_CODE.WARN_INPUT_PARAM;
          case PASSWORD_RESET_API_RESULT_CODE.INFO_EXPIRED:
            throw PASSWORD_RESET_API_RESULT_CODE.INFO_EXPIRED;
          case PASSWORD_RESET_API_RESULT_CODE.INFO_INVALID:
            throw PASSWORD_RESET_API_RESULT_CODE.INFO_INVALID;
          default:
            throw PASSWORD_RESET_API_RESULT_CODE.ERR_UNKNOWN;
        }
      })
      .catch((error: PASSWORD_RESET_ERROR_TYPE | null) => {
        if (isPasswordResetErrorType(error)) {
          setPasswordSetErrorType(error);
        } else {
          setPasswordSetErrorType(PASSWORD_RESET_API_RESULT_CODE.ERR_UNKNOWN);
        }
      })
      .finally(() => {
        // loadingBar非表示
        setIsCallingApi(false);
      });

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'set');
    sendGAEvent('press_btn', 'txt_value', inputData.email.toString());
  };

  /**
   * パスワード設定エラーメッセージ
   */
  const passwordSetErrorMessage = useMemo(
    (): string => {
      if (!passwordSetErrorType) {
        return ''; // エラーコンポーネント自体非表示にする
      }
      switch (passwordSetErrorType) {
        case PASSWORD_RESET_API_RESULT_CODE.WARN_INPUT_PARAM:
          return t('passwordSet.passwordResetApiError.warnInputParam');
        case PASSWORD_RESET_API_RESULT_CODE.INFO_INVALID:
          return t('passwordSet.passwordResetApiError.infoInvalid');
        case PASSWORD_RESET_API_RESULT_CODE.INFO_EXPIRED:
          return t('passwordSet.passwordResetApiError.infoExpired');
        default:
          return t('passwordSet.passwordResetApiError.otherError');
      }
    },
    [passwordSetErrorType, t], // エラータイプが変わる度にエラーメッセージが返却される
  );

  // Error card show handle with Snackbar
  useEffect(() => {
    if (passwordSetErrorType) {
      displaySnackbar({
        message: passwordSetErrorMessage,
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSetErrorType]);

  /**
   * [ログイン画面を表示]ボタン押下時処理
   */
  const onClickLogin = () => {
    // [ログイン]画面へ遷移する
    navigate(
      `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );
  };

  return {
    onClickSetting,
    passwordSetErrorType,
    passwordSetErrorMessage,
    onetimeValidCheckResult,
    mailAddress,
    t,
    isCallingApi,
    onClickLogin,
  };
};

export default usePasswordSet;
