import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as EmaiSendSuccess } from '../../images/icon/emai_send_success.svg';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../../constants/constants';
import useAccountInfo from '../../hooks/useAccountInfo';
import useAccessKey from '../../hooks/useAccessKey';
import useLocale from '../../hooks/useLocale';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  cardText: string;
  email: string;
};

const EmailSendSuccessCard = React.memo<Props>(
  ({ cardText, email }): JSX.Element => {
    // common styles
    const classes = useStyles();

    const { t } = useSwitchLocaleLanguage();

    // navigate(Screen transition)
    const navigate = useNavigate();
    // web browser's language (for granting URL parameters when screen transition)
    const { browserLanguage } = useLocale();
    const { accountoInfoReset } = useAccountInfo();
    const { reset } = useAccessKey();

    const onClickGoBack = () => {
      accountoInfoReset();
      reset();
      navigate(
        `${PAGE_PATH_NAME.LOGON}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
      );
    };

    const [tooltipEnabled, setTooltipEnabled] = useState(false);
    const handleShouldShowTooltip = (event: React.MouseEvent<Element>) => {
      const element = event.currentTarget as HTMLElement;
      const clientWidth = element.getBoundingClientRect().width;

      // Set the element's overflow to visible
      element.style.overflow = 'visible';
      element.style.maxWidth = 'fit-content';

      // Get the scroll width
      const scrollWidth = element.getBoundingClientRect().width;

      // Set the element's overflow back to hidden
      element.style.overflow = 'hidden';
      element.style.maxWidth = '200px';

      if (scrollWidth > clientWidth) {
        setTooltipEnabled(true);
      } else {
        setTooltipEnabled(false);
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Box className={classes.emailSendSuccessCard}>
          <EmaiSendSuccess />
          <Typography
            sx={{ marginTop: '12px' }}
            className={classes.emailSendSuccessCardText}
          >
            {cardText}
          </Typography>
          <Typography className={classes.emailSendSuccessCardText}>
            {t('emailSendCompleteCard.email')} (
            <Tooltip
              placement="top"
              title={email}
              disableHoverListener={!tooltipEnabled}
              onMouseEnter={handleShouldShowTooltip}
              arrow
              sx={{ marginTop: 0 }}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-7, -7],
                    },
                  },
                ],
              }}
              componentsProps={{
                tooltip: {
                  className: classes.tooltipStyle,
                },
              }}
            >
              <span className={classes.emailSendSuccessCardEmail}>
                <b>{email}</b>
              </span>
            </Tooltip>
            )
          </Typography>
          <Button
            className={classes.submitButtonLogin}
            fullWidth
            size="large"
            variant="contained"
            onClick={onClickGoBack}
          >
            {t('emailSendCompleteCard.button')}
          </Button>
        </Box>
      </Box>
    );
  },
);

export default EmailSendSuccessCard;
