import { useLocation } from 'react-router-dom';
import usePasswordResetSendMailComplete from '../../hooks/usePasswordResetSendMailComplete';
import EmailSendSuccessCard from '../Modecules/EmailSendSuccessCard';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

export type iotAccountMailAddressStateProps = {
  mailAddress: string;
};

/**
 * パスワード再設定メール送信完了画面
 * @returns
 */
const PasswordResetSendMailComplete = (): JSX.Element => {
  const location = useLocation();
  const locatonState = location.state as iotAccountMailAddressStateProps;
  const { t } = useSwitchLocaleLanguage();

  let email = '';
  if (locatonState !== null) {
    email = locatonState.mailAddress;
  }
  usePasswordResetSendMailComplete();

  return (
    <EmailSendSuccessCard
      cardText={t('emailSendCompleteCard.passwordResetSendMailComplete')}
      email={email}
    />
  );
};
export default PasswordResetSendMailComplete;
