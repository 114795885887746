/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import type {
  TransCountListApiParameter,
  TransCountListApiResponse,
} from '../../types/apis/transCountListApi.d';
import callTransCountListApi, {
  TRANS_COUNT_LIST_API_RESULT_CODE,
  TRANS_COUNT_LIST_ERROR_TYPE,
} from '../../apis/callTransCountListApi';
import {
  TableHeaderData,
  TRANS_COUNT_TABLE_DATA_KEY,
} from '../../types/datatable/dataTable.d';
import { isTransCountListErrorType } from '../../types/apis/apiErrorTypeGuard';
import type { TransCountListResult } from '../../types/report/transCountList.d';
import { convertStringArrayToTableHeaderColumns } from '../datatable/dataTableUtil';
import { SERVER_MESSAGE } from '../../constants/constants';

/**
 * 翻訳回数画面に依存する汎用クラス
 */

/**
 * 翻訳回数一覧取得APIにリクエスト送信して、一覧を取得
 *
 * @param accessKey アクセスキー
 * @param corpId 法人ID
 * @param from 期間開始日
 * @param to 期間終了日
 * @param view ビュータイプ
 * @returns
 */
export const fetchTransCountList = async (
  // accessKey: string,
  corpId: string,
  from: string,
  to: string,
  view: string,
): Promise<TransCountListResult> => {
  // リクエストパラメータ
  const requestParam: TransCountListApiParameter = {
    corpId,
    from,
    to,
    view,
  };
  // 翻訳回数取得API呼び出し
  try {
    const apiResponse: TransCountListApiResponse = await callTransCountListApi(
      requestParam,
    );

    if (
      apiResponse.message ===
      TRANS_COUNT_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
    ) {
      // APIから返却された「日・週・月別の翻訳回数列のヘッダ」を変換
      const tableHeader: TableHeaderData[] =
        convertStringArrayToTableHeaderColumns(
          apiResponse.details.titleList,
          TRANS_COUNT_TABLE_DATA_KEY.COUNT_LIST,
          view,
        );

      return {
        resultCode: TRANS_COUNT_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
        transCountInfos: apiResponse.details.data,
        tableHeader,
        viewType: apiResponse.details.viewType,
      };
    }
    
    // 翻訳回数の取得に失敗
    switch (apiResponse.message) {
      case TRANS_COUNT_LIST_API_RESULT_CODE.INFO_DATA_OVER:
        throw TRANS_COUNT_LIST_API_RESULT_CODE.INFO_DATA_OVER;
      case TRANS_COUNT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw TRANS_COUNT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case TRANS_COUNT_LIST_API_RESULT_CODE.INFO_INVALID:
        throw TRANS_COUNT_LIST_API_RESULT_CODE.INFO_INVALID;
      case TRANS_COUNT_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw TRANS_COUNT_LIST_API_RESULT_CODE.INFO_EXPIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      default:
        throw TRANS_COUNT_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err?.message === TRANS_COUNT_LIST_API_RESULT_CODE.NO_INTERNET) {
      const message: TRANS_COUNT_LIST_ERROR_TYPE =
        err.message as TRANS_COUNT_LIST_ERROR_TYPE;

      return {
        resultCode: message,
        transCountInfos: null,
        tableHeader: [],
        viewType: '',
      };
    }
    let resultCode: TRANS_COUNT_LIST_ERROR_TYPE =
      TRANS_COUNT_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isTransCountListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      transCountInfos: null,
      tableHeader: [],
      viewType: '',
    };
  }
};
