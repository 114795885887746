import { REPORT_VIEW_TYPE } from "../report/fromLangList.d";

/**
 * 翻訳回数取得API リクエストパラメータ
 */
export type TransCountListApiParameter = {
  // 法人ID
  corpId: string;
  // 期間(From)
  from: string;
  // 期間(To)
  to: string;
  // ビュー(d:日, w:週, m:月)
  view: string;
};

/**
 * 翻訳回数取得API レスポンス
 */
export type TransCountListApiResponse = {
  statusCode: number;
  // 結果コード
  message: TRANS_COUNT_LIST_API_RESULT_CODE;
  details:{
    titleList: string[];
    viewType: string;
    data: TransCountInfo[];
  }
  
};

/**
 * 翻訳回数情報 key
 */
export const TRANS_COUNT_INFO_KEY = {
  TRANS_COUNT: 'transCount',
  IMEI: 'imei',
  DEVICE_NAME: 'deviceName',
  PRODUCT_TYPE: 'productType',
  ICCID: 'iccid',
  IS_DEREGISTER: 'isDeregister',
  COUNT_LIST: 'countList',
} as const;

/**
 * 翻訳回数情報
 */
export type TransCountInfo = {
  // 翻訳回数(期間内合計)
  [TRANS_COUNT_INFO_KEY.TRANS_COUNT]: number;
  // IMEI
  [TRANS_COUNT_INFO_KEY.IMEI]: string;
  // 製品名
  [TRANS_COUNT_INFO_KEY.DEVICE_NAME]: string;
  // 機種名
  [TRANS_COUNT_INFO_KEY.PRODUCT_TYPE]: string;
  // ICCID
  [TRANS_COUNT_INFO_KEY.ICCID]: string;
  // 端末登録解除されているか否か(true:解除されている/false:解除されていない)
  [TRANS_COUNT_INFO_KEY.IS_DEREGISTER]: boolean;
  // 日、週、月ごとの翻訳回数
  [TRANS_COUNT_INFO_KEY.COUNT_LIST]: string[];
};
