import { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { TFunction } from 'react-i18next';
import { signInWithCustomToken } from 'firebase/auth';
import type { LoginInput } from '../types/inputs/loginInput';
import type { LoginApiRequestParameter } from '../types/apis/loginApi';
import { LOGIN_API_RESULT_CODE, LOGIN_ERROR_TYPE } from '../apis/callLoginApi';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import { accessKeySlice } from '../state/slice/accessKeySlice';
import { AppDispatch, useAppDispatch } from '../state/store';
import {
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  ALL_ERROR_TYPE,
  URL_PARAMETER_KEY,
} from '../constants/constants';
import useLocale from './useLocale';
import useSnackbar from './useSnackbar';
import { sendGAEvent } from './useGAEvent';
import useDrawerInfo from './useDrawerInfo';
import useWelcomeDialog from './useWelcomeDialog';
import { fireBaseAuth } from '../firebase/firebase';
import callApiUtil from '../utils/callApiUtil';
import { encryptText } from '../utils/utility';

/**
 * クラスの型(publicにする関数などを指定)
 */
export type UseLoginValue = {
  // ［ログイン］ボタンクリック時処理
  onClickLogin: (inputData: LoginInput) => void;
  // エラータイプ
  errorType: ALL_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // APIコール中か否か
  isCallingApi: boolean;
  // [パスワードを忘れた]リンク押下時処理
  onClickPasswordReminder: () => void;
  onClickRegisterBtn: () => void;
};

/**
 * ログイン画面 hooks
 *
 * @returns
 */
const useLogin = (): UseLoginValue => {
  dayjs.extend(duration);
  // First Time LogIn
  const { displayWelcomeDialog } = useWelcomeDialog();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const pageState = location.state;

  // エラータイプ get/set
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  // navigate(画面遷移)
  const navigate = useNavigate();
  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  const date = new Date();
  // date.setTime(date.getTime() + 24 * 3600 * 1000); // 24 Hours
  date.setTime(date.getTime() + 0.1 * 60 * 1000); // 1 min

  // アクセスキー保存hooks
  const { accessKey, callLoginApi, reset } = useAccessKey();
  // ログインAPIから返却されたアカウント情報保存hooks
  const {
    saveLocale,
    saveEmail,
    saveCorpId,
    saveCompany,
    saveCorpCreatedDt,
    saveRoleCode,
    saveRoleName,
    saveUserName,
    saveUserId,
    saveTotalGroups,
    saveFeaturePermissions,
    saveImageUrl,
    savePlanName,
    savePlanCode,
    saveLatestEulaConsented,
    saveReLoginEulaState,
    saveEulaConsentedPreviously,
    saveShopifyFeatureEnabled,
    saveNotificationApiCallDone,
  } = useAccountInfo();
  const { updateToken } = accessKeySlice.actions;

  const { closeDrawer } = useDrawerInfo();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  // web browser's language (for granting URL parameters when screen transition)
  const { browserLanguage } = useLocale();

  const { displaySnackbar } = useSnackbar();
  const dispatch: AppDispatch = useAppDispatch();

  /**
   * ログイン処理
   * @param inputData 入力フォームの内容
   */
  const login = (email: string, password: string): void => {
    // loadingBar表示
    setIsCallingApi(true);
    // エラータイプ初期化
    setErrorType(null);

    // ログインAPI リクエスト送信
    const loginApiRequestParameter: LoginApiRequestParameter = {
      mailAddress: email,
      password,
    };
    callLoginApi(loginApiRequestParameter)
      .then(unwrapResult)
      // eslint-disable-next-line
      .then((apiResponse) => {
        saveNotificationApiCallDone(false);
        signInWithCustomToken(
          fireBaseAuth,
          apiResponse.details?.firebaseAccessKey,
        )
          .then(() => saveNotificationApiCallDone(true))
          .catch((error) => console.log('Error: Firebase Sign In', error));
        if (apiResponse.message === SERVER_MESSAGE.LOGIN_SUCCESS) {
          // Save account information to storage
          callApiUtil.defaults.headers.common.authorization = `Console-Token ${apiResponse.details.accessKey}`;

          dispatch(updateToken(encryptText(apiResponse.details.accessKey)));
          saveLocale(apiResponse.details.locale);
          saveEmail(apiResponse.details.mailAddress);
          saveCorpId(apiResponse.details.corpId);
          saveCompany(apiResponse.details.company);
          saveCorpCreatedDt(apiResponse.details.corpCreatedDt);
          saveRoleCode(apiResponse.details.roleCode);
          saveRoleName(apiResponse.details.roleName);
          saveUserName(apiResponse.details.userName);
          saveUserId(apiResponse.details.userId);
          saveTotalGroups(apiResponse.details.totalGroups);
          saveFeaturePermissions(apiResponse.details.featurePermissions);
          saveLatestEulaConsented(apiResponse.details.latestEulaConsented);
          saveImageUrl(apiResponse.details.imageUrl);
          savePlanName(apiResponse.details.planName);
          savePlanCode(apiResponse.details.planCode);
          saveShopifyFeatureEnabled(apiResponse.details.shopifyFeatureEnabled);
          saveReLoginEulaState(false);
          saveEulaConsentedPreviously(
            apiResponse.details.eulaConsentedPreviously,
          );
          closeDrawer();
          // First Time LogIn
          if (apiResponse.details.firstLogin) {
            displayWelcomeDialog({
              open: true,
            });
          }

          // loadingBar非表示
          // finallyで実行すると警告が出るためここで実行する。
          setIsCallingApi(false);
          // If the API is successful, transition to the [Dashboard] screen
          if (pageState) {
            navigate(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              location.state.pageSrc
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  location.state.pageSrc
                : PAGE_PATH_NAME.DASHBOARD,
              { replace: true },
            );
          } else {
            navigate(PAGE_PATH_NAME.DASHBOARD, { replace: true }); // Prevent returning to the [Login] screen even if the browser is backed up
          }

          // If the API is successful, transition to the [Dashboard] screen
          // navigate(PAGE_PATH_NAME.DASHBOARD, { replace: true }); // Prevent returning to the [Login] screen even if the browser is backed up

          return;
        }

        if (apiResponse.message === SERVER_MESSAGE.ACCOUNT_LOCKED) {
          const unlockTime = dayjs(apiResponse.details.unlockTime);
          const currentTime = dayjs();

          const durationObj = dayjs.duration(unlockTime.diff(currentTime));

          // const hours = durationObj.hours();
          const minutes = durationObj.minutes();
          const seconds = durationObj.seconds();
          setIsCallingApi(false);

          let accountLockMsg;

          if (minutes > 1) {
            accountLockMsg = t('login.loginApiError.accountLockMinutes', {
              min: minutes,
              sec: seconds,
            });
          } else {
            accountLockMsg = t('login.loginApiError.accountLockMinute', {
              min: minutes,
              sec: seconds,
            });
          }

          displaySnackbar({
            message: accountLockMsg,
            type: 'error',
          });

          return;
        }

        switch (apiResponse.message) {
          case SERVER_MESSAGE.WRONG_CREDENTIALS:
            throw SERVER_MESSAGE.WRONG_CREDENTIALS;
          case SERVER_MESSAGE.WARN_INPUT_PARAM:
            throw SERVER_MESSAGE.WARN_INPUT_PARAM;
          case SERVER_MESSAGE.USER_NOT_FOUND:
            throw SERVER_MESSAGE.USER_NOT_FOUND;
          case SERVER_MESSAGE.NO_INTERNET:
            throw SERVER_MESSAGE.NO_INTERNET;
          default:
            throw LOGIN_API_RESULT_CODE.ERR_UNKNOWN;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        // loadingBar非表示
        setIsCallingApi(false);

        if (isErrorType(error)) {
          setErrorType(error);
        } else {
          setErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        }
      });
  };

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo(
    (): string => {
      if (!errorType) {
        return ''; // エラーコンポーネント自体非表示にする
      }
      switch (errorType) {
        case SERVER_MESSAGE.WARN_INPUT_PARAM:
          return t('login.loginApiError.warnInputParam');
        case LOGIN_API_RESULT_CODE.WRONG_CREDENTIALS:
          return t('login.loginApiError.warnInputParam');
        case SERVER_MESSAGE.USER_NOT_FOUND:
          return t('login.loginApiError.noAccount');
        case SERVER_MESSAGE.NO_INTERNET:
          return t('common.error.noInternet');
        default:
          return t('login.loginApiError.unknown');
      }
    },
    [errorType, t], // エラータイプが変わる度にエラーメッセージが返却される
  );

  /**
   * ［ログイン］ボタンクリック処理
   *
   * @param inputData 入力フォームデータ
   */
  const onClickLogin = (inputData: LoginInput) => {
    // ログイン処理
    login(inputData.email, inputData.password);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'login');
    sendGAEvent('press_btn', 'btn_name', inputData.email);
  };

  /**
   * [パスワードを忘れた]リンク押下処理
   */
  const onClickPasswordReminder = () => {
    // パスワード再設定メール送信画面に遷移
    navigate(
      `${PAGE_PATH_NAME.PASSWORD_RESET_SEND_MAIL}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );
  };
  const onClickRegisterBtn = () => {
    navigate(
      `${PAGE_PATH_NAME.ACCOUNT_RRGISTED_SEND_MAIL}?${URL_PARAMETER_KEY.locale}=${browserLanguage}`,
    );
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // If the access key was saved when the [Login] screen was displayed, delete it (it will be considered as unauthorized access)
    if (accessKey) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 第二引数に[]を指定してコンポーネントの更新によるuseEffectを止める

  // Error card show handle with Snackbar
  useEffect(() => {
    if (errorType) {
      displaySnackbar({
        message: errorMessage,
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  return {
    onClickLogin,
    errorType,
    errorMessage,
    t,
    isCallingApi,
    onClickPasswordReminder,
    onClickRegisterBtn,
  };
};

export default useLogin;
