/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/de'; // Import the German locale
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  // differenceInYears,
  // differenceInYears,
} from 'date-fns';
import { TFunction } from 'react-i18next';
import callDeviceListApi, {
  DEVICE_LIST_API_RESULT_CODE,
  DEVICE_LIST_ERROR_TYPE,
} from '../../apis/callDeviceListApi';
import type {
  DeviceInfo,
  DeviceListApiResponse,
} from '../../types/apis/deviceListApi';
import callFetchSimInfoApi, {
  FETCH_SIM_INFO_API_RESULT_CODE,
  FETCH_SIM_INFO_ERROR_TYPE,
} from '../../apis/callFetchSimInfoApi';
import {
  isDeviceListErrorType,
  isFetchSimInfoErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import type {
  FetchSimInfoApiRequestParameter,
  FetchSimInfoApiResponse,
} from '../../types/apis/fetchSimInfoApi';
import type {
  DeviceListResult,
  DeviceInfoData,
  SimInfoData,
  ExpireDtStateType,
} from '../../types/devicelist/deviceList';
import {
  TableRowData,
  DEVICE_TABLE_DATA_KEY,
} from '../../types/datatable/dataTable.d';
import { convertISO8601StrToyyyyMMdd } from '../dateUtil';
import { ALL_ERROR_TYPE, STATUS_COLOR_CODE } from '../../constants/constants';
import { DeviceGroupListApiResponse } from '../../types/apis/deviceGroupListApi';
import callDeviceGroupListApi, {
  DEVICE_GROUP_LIST_API_RESULT_CODE,
} from '../../apis/callGetGroupListApi';
import { LOCALE_CODE } from '../../hooks/useLocale';

/**
 * 端末一覧画面に依存する汎用クラス
 */

/**
 * DeviceInfoData 型の配列を作成
 *
 * @param originalInfos 端末一覧取得APIから返却された一覧
 * @returns
 */
export const createOriginalDeviceInfo = (
  originalInfos: DeviceInfo[],
  expireDtStateType: ExpireDtStateType,
): DeviceInfoData[] => {
  const data: DeviceInfoData[] = [];

  originalInfos.forEach((info: DeviceInfo) => {
    info.expiredt = expireDtStateType.default; // 半角空白文字
    // info.latestTransdt = convertISO8601StrToyyyyMMdd(info.latestTransdt); // yyyy/MM/dd形式に変換
    info.createdDt = convertISO8601StrToyyyyMMdd(info.createdDt); // yyyy/MM/dd形式に変換
    data.push({
      [info.imei]: info,
    });
  });

  return data;
};

/**
 * SIM情報取得API を呼んで新規にSIM情報を取得
 *
 * @param accessKey アクセスキー
 * @param targetDeviceInfo SIM情報が必要な端末情報（APIリクエスト時にパラメータに端末情報の指定が必要）
 * @returns
 */
const fetchSimInfo = async (
  accessKey: string,
  targetDeviceInfo: DeviceInfo,
): Promise<FetchSimInfoApiResponse | null> => {
  try {
    const fetchSimInfoApiRequestParameter: FetchSimInfoApiRequestParameter = {
      imei: targetDeviceInfo.imei,
    };
    const apiResponse: FetchSimInfoApiResponse = await callFetchSimInfoApi(
      fetchSimInfoApiRequestParameter,
    );

    return apiResponse;
  } catch (error) {
    // 取得失敗
    return null;
  }
};

/**
 * SIM情報取得APIから返却された結果を加工
 *
 * @param apiResponse SIM情報取得APIから返却された結果
 * @param expireDtStateType 内蔵SIM期限日タイプが格納された配列
 * @returns
 */
const createSimInfoTypeBaseOnApiResponse = (
  apiResponse: FetchSimInfoApiResponse,
  expireDtStateType: ExpireDtStateType,
): SimInfoData => {
  // SIM情報取得APIから返却された結果コード
  let errorType: FETCH_SIM_INFO_ERROR_TYPE =
    FETCH_SIM_INFO_API_RESULT_CODE.ERR_UNKNOWN;
  if (isFetchSimInfoErrorType(apiResponse.resultCode)) {
    errorType = apiResponse.resultCode;
  }

  const { simInfo } = apiResponse;
  switch (errorType) {
    case FETCH_SIM_INFO_API_RESULT_CODE.OK:
      if (simInfo.expiredt) {
        // 内蔵SIM期限日=利用中(期限日が設定されている)
        simInfo.expiredt = convertISO8601StrToyyyyMMdd(simInfo.expiredt);
      } else {
        // 内蔵SIM期限日=無期限
        simInfo.expiredt = expireDtStateType.indefinitely;
      }

      return { simInfo };
    case FETCH_SIM_INFO_API_RESULT_CODE.INFO_NOT_FOUND_DB:
      // 内蔵SIM期限日=利用開始前
      simInfo.expiredt = expireDtStateType.beforeUse;

      return { simInfo };
    default:
      // アクセスキー不正エラー、その他エラー
      return { simInfo: null };
  }
};

/**
 * SIM情報を取得
 *
 * @param accessKey アクセスキー
 * @param targetDeviceInfo SIM情報を取得したい端末情報(1件分)
 * @param expireDtStateType 内蔵SIM期限日タイプが格納された配列
 * @returns
 */
export const findSimInfo = async (
  accessKey: string,
  targetDeviceInfo: DeviceInfo,
  expireDtStateType: ExpireDtStateType,
): Promise<SimInfoData> => {
  // SIM情報取得APIを呼んでSIM情報を新規取得
  const apiResponse: FetchSimInfoApiResponse | null = await fetchSimInfo(
    accessKey,
    targetDeviceInfo,
  );

  // 取得に失敗(通常起こらない)
  if (apiResponse === null) {
    return { simInfo: null };
  }

  // 新規SIM情報作成
  const newSimInfoType = createSimInfoTypeBaseOnApiResponse(
    apiResponse,
    expireDtStateType,
  );

  // APIから新規取得したSIM情報と、更新したキャッシュを返却
  return newSimInfoType;
};

/**
 * targetDeviceInfo内の指定されたIMEIと一致する端末情報を差し替え
 *
 * @param imeiKey keyとなるIMEI
 * @param updateData 更新された端末情報
 * @param targetDeviceInfos データを差し替えたい端末情報配列
 * @returns
 */
export const spliceDeviceInfo = (
  imeiKey: string,
  updateData: DeviceInfoData,
  targetDeviceInfos: DeviceInfoData[],
): DeviceInfoData[] => {
  const newDeviceInfos: DeviceInfoData[] = [...targetDeviceInfos];

  let result = false;
  Object.entries(targetDeviceInfos).some((entory) => {
    const [index, infoData] = entory;
    if (imeiKey === infoData[imeiKey]?.imei) {
      newDeviceInfos.splice(Number(index), 1, updateData);
      result = true;
    }

    return result === true;
  });

  return newDeviceInfos;
};

/**
 * 指定されたIMEIと一致する表示用データを差し替え
 *
 * @param imeiKey keyとなるIMEI
 * @param updateData 更新された表示用データ
 * @param targetTableRowData データを差し替えたい表示用データ配列
 * @returns
 */
export const spliceDeviceTableRowData = (
  imeiKey: string,
  updateData: DeviceInfo,
  targetTableRowData: TableRowData[],
): TableRowData[] => {
  const newTableRowData: TableRowData[] = [...targetTableRowData];
  Object.entries(targetTableRowData).some((entory) => {
    const [index, infoData] = entory;

    let result = false;
    if (imeiKey === infoData?.id) {
      newTableRowData.splice(Number(index), 1, {
        id: imeiKey,
        data: {
          [DEVICE_TABLE_DATA_KEY.IMEI]: updateData.imei,
          [DEVICE_TABLE_DATA_KEY.DEVICE_NAME]: updateData.deviceName,
          // [DEVICE_TABLE_DATA_KEY.LATEST_TRANSDT]: updateData.latestTransdt,
          // [DEVICE_TABLE_DATA_KEY.EXPIREDT]:
          //   updateData.expiredt !== undefined ? updateData.expiredt : '',
          // [DEVICE_TABLE_DATA_KEY.CREATEDDT]: updateData.createdDt,
          [DEVICE_TABLE_DATA_KEY.DEVICE_MODEL]: updateData.deviceModel,
          // [DEVICE_TABLE_DATA_KEY.ICCID]: updateData.iccid,
          [DEVICE_TABLE_DATA_KEY.MODE]: updateData.modeName,
          [DEVICE_TABLE_DATA_KEY.STATUS]: updateData.modeStatus,
          [DEVICE_TABLE_DATA_KEY.STATUS_COLOR]: updateData.statusColor,
        },
      });
      result = true;
    }

    return result === true;
  });

  return newTableRowData;
};

/**
 * 端末一覧取得APIにリクエスト送信して、端末一覧を取得
 *
 * @param accessKey アクセスキー
 * @param expireDtStateType 内蔵SIM期限日タイプが格納された配列
 * @returns
 */

export const fetchDeviceList = async (
  accessKey: string,
  expireDtStateType: ExpireDtStateType,
  rowLimitPerPage: number,
  currentPageNumber: number,
  sortDirection: string,
  searchText: string,
  colName: string,
  groupId?: number,
): Promise<DeviceListResult> => {
  try {
    // 端末一覧API呼び出し
    const apiResponse: DeviceListApiResponse = await callDeviceListApi(
      rowLimitPerPage,
      currentPageNumber,
      sortDirection,
      encodeURIComponent(searchText),
      colName,
      groupId,
    );
    if (apiResponse.message === 'SUCCESSFULLY_FETCHED') {
      // APIから返却された一覧
      const newDeviceInfoDatas: DeviceInfoData[] = createOriginalDeviceInfo(
        apiResponse.details.devices,
        expireDtStateType,
      );

      return {
        resultCode: DEVICE_LIST_API_RESULT_CODE.OK,
        devices: newDeviceInfoDatas,
        totalDeviceCount: apiResponse.details.totalDeviceCount,
      };
    }

    switch (apiResponse.message) {
      case DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA:
        throw DEVICE_LIST_API_RESULT_CODE.INFO_NO_DATA;
      case DEVICE_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw DEVICE_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case DEVICE_LIST_API_RESULT_CODE.INFO_INVALID:
        throw DEVICE_LIST_API_RESULT_CODE.INFO_INVALID;
      case DEVICE_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw DEVICE_LIST_API_RESULT_CODE.INFO_EXPIRED;
      case DEVICE_LIST_API_RESULT_CODE.NO_INTERNET:
        throw DEVICE_LIST_API_RESULT_CODE.NO_INTERNET;
      default:
        throw DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error: ALL_ERROR_TYPE | unknown) {
    let resultCode: DEVICE_LIST_ERROR_TYPE =
      DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isDeviceListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      devices: [],
      totalDeviceCount: 0,
    };
  }
};
export const fetchDeviceGroupList = async (
  rowNumber: number,
  pageNumber: number,
  searchText: string,
): Promise<DeviceGroupListApiResponse> => {
  try {
    const apiResponse: DeviceGroupListApiResponse =
      await callDeviceGroupListApi(
        rowNumber,
        pageNumber,
        encodeURIComponent(searchText),
      );
    if (
      apiResponse.message ===
      DEVICE_GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
    ) {
      return apiResponse;
    }

    switch (apiResponse.message) {
      case DEVICE_GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA:
        throw apiResponse;
      case DEVICE_GROUP_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw apiResponse;
      case DEVICE_GROUP_LIST_API_RESULT_CODE.INFO_INVALID:
        throw apiResponse;
      case DEVICE_GROUP_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw apiResponse;
      case DEVICE_GROUP_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw apiResponse;
      default:
        throw apiResponse;
    }
  } catch (error) {
    return error as DeviceGroupListApiResponse;
  }
};

/**
 * 指定されたDeviceInfoData配列から、
 * 指定された IMEI と一致する DeviceInfoData を見つけて返却
 * 見つからなかった場合は null を返却
 *
 * @param imeiKey 検索対象Key
 * @param targetDeviceInfoDatas 検索対象のDeviceInfoData配列
 * @returns
 */
export const findDeviceInfoData = (
  imeiKey: string | number,
  targetDeviceInfoDatas: DeviceInfoData[],
): DeviceInfoData | null => {
  let findData: DeviceInfoData | null = null;

  let result = false;
  Object.entries(targetDeviceInfoDatas).some((entry) => {
    const [, infoData] = entry;

    const data = infoData[imeiKey];
    if (data !== null && data?.imei === imeiKey) {
      findData = infoData;
      result = true;
    }

    return result === true; // break;
  });

  return findData;
};

/**
 * 指定されたIMEIと一致する端末情報を取得済みでかつ、
 * SIM情報を取得済みか確認
 *
 * 内蔵SIM期限日が「空」だった場合は、"SIM情報未取得" とみなす
 *
 * @param imeiKey IMEI
 * @param targetOriginalDeviceInfos 取得済みの端末一覧
 * @param expireDtStateType 内蔵SIM期限日タイプが格納された配列
 * @returns true=取得済み / false=未取得
 */
export const isFetchedeExpiredt = (
  imeiKey: string | number,
  targetOriginalDeviceInfos: DeviceInfoData[],
  expireDtStateType: ExpireDtStateType,
): boolean => {
  const data = findDeviceInfoData(imeiKey, targetOriginalDeviceInfos);

  // 指定されたIMEIと一致する端末情報がある＆内蔵SIM期限日が空でない場合SIM情報取得済み
  if (data !== null && data[imeiKey].expiredt !== expireDtStateType.default) {
    return true;
  }

  return false;
};
export const statusColorFn = (color: string): string => {
  switch (color) {
    case 'RED':
      return STATUS_COLOR_CODE.RED;
    case 'YELLOW':
      return STATUS_COLOR_CODE.YELLOW;
    case 'GRAY':
      return STATUS_COLOR_CODE.GRAY;
    case 'GREEN':
      return STATUS_COLOR_CODE.GREEN;
    case 'NAVYBLUE':
      return STATUS_COLOR_CODE.NAVYBLUE;
    case 'BLUE':
      return STATUS_COLOR_CODE.BLUE;
    default:
      return STATUS_COLOR_CODE.NONE;
  }
};

export const dateFormatMMMddyyyy = (
  date: Date,
  localeLanguage: string,
): string => {
  if (localeLanguage === LOCALE_CODE.FR || localeLanguage === LOCALE_CODE.DE) {
    const formattedDate = dayjs(date)
      .locale(localeLanguage)
      .format('MMM DD, YYYY');

    return formattedDate;
  }
  const formattedDate = date.toLocaleDateString(localeLanguage, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
};

const isOneMonthInFuture = (date: Date) => {
  const currentDate = new Date();
  const oneMonthFromNow = new Date(
    currentDate.setMonth(currentDate.getMonth() + 1),
  );

  return date.getTime() >= oneMonthFromNow.getTime();
};

export const simExpiryDateFormat = (
  date: string | number,
  t: TFunction,
  localeLanguage: string,
): { date: string; warning: boolean } => {
  if (!date) {
    return { date: ' ', warning: false };
  }
  const givenDate = new Date(date);
  const currentDate = new Date();
  if (givenDate.toLocaleDateString() === 'Invalid Date') {
    return { date: ' ', warning: false };
  }
  if (givenDate < currentDate) {
    return { date: t('common.dateFormet.expired'), warning: false };
  }
  if (!isOneMonthInFuture(givenDate)) {
    return {
      date: dateFormatMMMddyyyy(givenDate, localeLanguage),
      warning: true,
    };
  }

  return {
    date: dateFormatMMMddyyyy(givenDate, localeLanguage),
    warning: false,
  };
};

export const getTimeFormat = (
  date: string | number,
  t: TFunction,
): { date: string; recentUsed: boolean } => {
  if (!date) {
    return { date: 'invalid', recentUsed: false };
  }
  const dateObj = new Date(date);
  if (dateObj.toLocaleDateString() === 'Invalid Date') {
    return { date: 'invalid', recentUsed: false };
  }
  if (dateObj.getTime() > new Date().getTime()) {
    return { date: 'invalid', recentUsed: false };
  }

  // const diffInSeconds =
  //   differenceInSeconds(new Date(), dateObj) !== 0
  //     ? differenceInSeconds(new Date(), dateObj)
  //     : 1;

  // if (diffInSeconds < 60) {
  //   return { date: `${diffInSeconds} seconds ago`, recentUsed: true };
  // }
  // const diffInMinutes = differenceInMinutes(new Date(), dateObj);

  // if (diffInMinutes < 60) {
  //   return diffInMinutes === 1
  //     ? { date: 'A minute ago', recentUsed: true }
  //     : { date: `${diffInMinutes} minutes ago`, recentUsed: true };
  // }

  const diffInHours = differenceInHours(new Date(), dateObj);
  const diffHourZeroCheck = diffInHours === 0 ? 1 : diffInHours;
  if (diffHourZeroCheck < 24) {
    return {
      date:
        diffHourZeroCheck === 1
          ? t('common.dateFormet.hourAgo')
          : t('common.dateFormet.hoursAgo', { num: diffHourZeroCheck }),
      recentUsed: true,
    };
  }

  const diffInDays = differenceInDays(new Date(), dateObj);

  if (diffInDays < 7) {
    return {
      date:
        diffInDays === 1
          ? t('common.dateFormet.dayAgo')
          : t('common.dateFormet.daysAgo', { num: diffInDays }),
      recentUsed: true,
    };
  }

  // if (diffInDays < 7) {
  //   return { date: 'Over a day ago', recentUsed: true };
  // }

  const diffInWeeks = differenceInWeeks(new Date(), dateObj);
  const diffInMonths = differenceInMonths(new Date(), dateObj);
  // if (diffInWeeks <= 4 && diffInMonths < 1) {
  //   return diffInWeeks === 1
  //     ? { date: 'A week ago', recentUsed: true }
  //     : { date: `${diffInWeeks} weeks ago`, recentUsed: true };
  // }
  if (diffInWeeks <= 4 && diffInMonths < 1) {
    return { date: t('common.dateFormet.overWeekAgo'), recentUsed: true };
  }
  // if (diffInMonths < 12) {
  //   return diffInMonths === 1
  //     ? { date: 'A month ago', recentUsed: false }
  //     : { date: `${diffInMonths} months ago`, recentUsed: false };
  // }

  if (diffInMonths < 12) {
    return { date: t('common.dateFormet.overMonthAgo'), recentUsed: false };
  }

  // const diffInYears = differenceInYears(new Date(), dateObj);

  // return diffInYears === 1
  //   ? { date: 'A year ago', recentUsed: false }
  //   : { date: `Over a year ago`, recentUsed: false };
  return { date: t('common.dateFormet.overYearAgo'), recentUsed: false };
};

export const getNotificationTimeFormat = (
  date: string | number,
  t: TFunction,
): { date: string; recentUsed: boolean } => {
  if (!date) {
    return { date: 'invalid', recentUsed: false };
  }
  const dateObj = new Date(date);
  if (dateObj.toLocaleDateString() === 'Invalid Date') {
    return { date: 'invalid', recentUsed: false };
  }
  if (dateObj.getTime() > new Date().getTime()) {
    return { date: 'invalid', recentUsed: false };
  }

  const diffInSeconds =
    differenceInSeconds(new Date(), dateObj) !== 0
      ? differenceInSeconds(new Date(), dateObj)
      : 1;

  if (diffInSeconds < 60) {
    return { date: t('common.dateFormet.fewSecondsAgo'), recentUsed: true };
  }
  const diffInMinutes = differenceInMinutes(new Date(), dateObj);

  if (diffInMinutes < 60) {
    return {
      date:
        diffInMinutes === 1
          ? t('common.dateFormet.minuteAgo')
          : t('common.dateFormet.minutesAgo', { num: diffInMinutes }),
      recentUsed: true,
    };
  }

  const diffInHours = differenceInHours(new Date(), dateObj);
  const diffHourZeroCheck = diffInHours === 0 ? 1 : diffInHours;
  if (diffHourZeroCheck < 24) {
    return {
      date:
        diffHourZeroCheck === 1
          ? t('common.dateFormet.hourAgo')
          : t('common.dateFormet.hoursAgo', { num: diffHourZeroCheck }),
      recentUsed: true,
    };
  }

  const diffInDays = differenceInDays(new Date(), dateObj);

  if (diffInDays < 7) {
    return {
      date:
        diffInDays === 1
          ? t('common.dateFormet.dayAgo')
          : t('common.dateFormet.daysAgo', { num: diffInDays }),
      recentUsed: true,
    };
  }

  // if (diffInDays < 7) {
  //   return { date: 'Over a day ago', recentUsed: true };
  // }

  const diffInWeeks = differenceInWeeks(new Date(), dateObj);
  const diffInMonths = differenceInMonths(new Date(), dateObj);
  // if (diffInWeeks <= 4 && diffInMonths < 1) {
  //   return diffInWeeks === 1
  //     ? { date: 'A week ago', recentUsed: true }
  //     : { date: `${diffInWeeks} weeks ago`, recentUsed: true };
  // }
  if (diffInWeeks <= 4 && diffInMonths < 1) {
    return { date: t('common.dateFormet.overWeekAgo'), recentUsed: true };
  }
  // if (diffInMonths < 12) {
  //   return diffInMonths === 1
  //     ? { date: 'A month ago', recentUsed: false }
  //     : { date: `${diffInMonths} months ago`, recentUsed: false };
  // }

  if (diffInMonths < 12) {
    return { date: t('common.dateFormet.overMonthAgo'), recentUsed: false };
  }

  // const diffInYears = differenceInYears(new Date(), dateObj);

  // return diffInYears === 1
  //   ? { date: 'A year ago', recentUsed: false }
  //   : { date: `Over a year ago`, recentUsed: false };
  return { date: t('common.dateFormet.overYearAgo'), recentUsed: false };
};
