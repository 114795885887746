/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useRef } from 'react';
import CountryList from 'country-list-with-dial-code-and-flag';
import ReactCountryFlag from 'react-country-flag';
import {
  Box,
  Typography,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import { FixedSizeList as VariableListWindow } from 'react-window';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import { ReactComponent as NetherlandsAntillesFlag } from '../../images/icon/netherlands_antilles_flag.svg';

type countryInfo = {
  dialCode: string;
  countryFlagCode: string;
};

type Props = {
  selectAnchor: null | HTMLElement;
  selectOpen: boolean;
  width: number;
  handleSelectPhoneDialOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectCountryInfo: (phoneInfo: countryInfo) => void;
  selectedCountryCode: string;
};

const CountryListWithDialCode = ({
  selectAnchor,
  selectOpen,
  width,
  handleSelectPhoneDialOpen,
  handleSelectCountryInfo,
  selectedCountryCode,
}: Props) => {
  const classes = useStyles();
  const allCountry = CountryList.getAll();
  const listRef = useRef(null);

  return (
    <Popper
      placement="bottom-start"
      anchorEl={selectAnchor}
      open={selectOpen}
      className={classes.countryCodeModel}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-14, 6],
            },
          },
        ],
      }}
      sx={{
        // width: '17.8%',
        width: `${width}px`,
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
      }}
    >
      <ClickAwayListener onClickAway={handleSelectPhoneDialOpen}>
        <Paper className={classes.countryPopperBox}>
          <Box className={classes.countryPopperBoxScrollBar}>
            <SimpleBarReact className={classes.countryPopperScrollBar}>
              {({ scrollableNodeRef, contentNodeRef }) => (
                <VariableListWindow
                  className={classes.listWindowContainer}
                  itemCount={allCountry.length}
                  itemSize={37}
                  height={183}
                  width={width}
                  outerRef={scrollableNodeRef}
                  innerRef={contentNodeRef}
                  ref={listRef}
                >
                  {({ index, style }) => {
                    const item = allCountry[index];

                    return (
                      <MenuItem
                        key={item.code}
                        style={style}
                        className={classes.countryPopperMenuItem}
                        sx={{
                          backgroundColor:
                            selectedCountryCode === item.code
                              ? '#EAEAEB'
                              : '#ffffff',
                        }}
                        // value={item.code}
                        onClick={() => {
                          handleSelectCountryInfo({
                            countryFlagCode: item.code,
                            dialCode: item.dial_code,
                          });
                        }}
                      >
                        <Box className={classes.countryPopperMenuItemBox}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {item.code === 'AN' ? (
                              <NetherlandsAntillesFlag
                                style={{
                                  width: '30px',
                                  height: '20px',
                                }}
                              />
                            ) : (
                              <ReactCountryFlag
                                style={{
                                  width: '27px',
                                  height: '20px',
                                  border: '0.5px solid rgba(217, 217, 217, .5)',

                                  // border:
                                  //   item.code === 'JP'
                                  //     ? '0.5px solid rgba(217, 217, 217, .5)'
                                  //     : 'none',
                                }}
                                countryCode={item.code}
                                svg
                              />
                            )}

                            <Typography
                              className={classes.countryPopperMenuItemName}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              className={classes.countryPopperMenuItemDialCode}
                            >
                              ({item.dial_code})
                            </Typography>
                          </Box>
                          {/* <CheckIcon /> */}
                          <Box>
                            {selectedCountryCode === item.code && <CheckIcon />}
                          </Box>
                        </Box>
                      </MenuItem>
                    );
                  }}
                </VariableListWindow>
              )}
            </SimpleBarReact>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default CountryListWithDialCode;
