import type {
  LoginApiRequestParameter,
  LoginApiResponse,
} from '../types/apis/loginApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * Login API result code
 */
export const LOGIN_API_RESULT_CODE = {
  // Login API
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  USER_NOT_FOUND: 'INFO_NO_USER',
  INFO_NO_CORPORATION: 'INFO_NO_CORPORATION',
  LANGUAGE_NOT_FOUND: 'INFO_NO_LANGUAGE',
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',
  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
} as const;

/**
 * Login API error type
 */
export type LOGIN_ERROR_TYPE =
  (typeof LOGIN_API_RESULT_CODE)[keyof typeof LOGIN_API_RESULT_CODE];

/**
 * Login API call
 *
 * @param requestParams Request parameter
 * @returns Response from the login API
 */
const callLoginApi = (
  requestParams: LoginApiRequestParameter,
): Promise<LoginApiResponse> => {
  // Request parameter
  const jsonParams = {
    mailAddress: requestParams.mailAddress,
    password: requestParams.password,
  };
  // Request transmission (post)
  /* eslint-disable */
  const response = callApiUtil
    .post<LoginApiResponse>(API_URL.LOGIN_API, jsonParams, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return { message: error.message };
    });

  return response;
};

export default callLoginApi;
