import { API_URL } from '../constants/constants';
import type {
  PasswordReminderSendMailApiRequestParam,
  PasswordReminderSendMailApiResponse,
} from '../types/apis/passwordReminderSendMailApi';
import callApiUtil from '../utils/callApiUtil';

// 変数名長くなりすぎるのでPASSWORD -> PWと記載
export const PW_REMINDER_SEND_MAIL_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED',
  // 入力パラメータエラー or アカウント未登録
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  INFO_NO_USER: 'INFO_NO_USER',

  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

export type PW_REMINDER_SEND_MAIL_ERROR_TYPE =
  (typeof PW_REMINDER_SEND_MAIL_API_RESULT_CODE)[keyof typeof PW_REMINDER_SEND_MAIL_API_RESULT_CODE];

/**
 * パスワードリマインダーメール送信API(認証なし)呼び出し
 * @param requestParams リクエストパラメータ
 * @returns パスワードリマインダーメール送信API(認証なし)からのレスポンス
 */
/*eslint-disable*/
const callPasswordReminderSendMailApi = (
  requestParams: PasswordReminderSendMailApiRequestParam,
): Promise<PasswordReminderSendMailApiResponse> => {
  const jsonParams = {
    mailAddress: requestParams.mailAddress,
  };

  const response = callApiUtil
    .post<PasswordReminderSendMailApiResponse>(
      API_URL.FORGOT_PASSWORD_SEND_API,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return { message: error.message };
    });

  return response;
};

export default callPasswordReminderSendMailApi;
