/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography, Breadcrumbs, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
// common style
import { useStyles } from '../Templates/CommonStyle';

const BreadcrumbDetailsPage = (): JSX.Element => {
  // common styles
  const classes = useStyles();

  const { t } = useSwitchLocaleLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  // Get the path name from the route
  const pathNames = location.pathname
    .split('/')
    .filter((x) => x)
    .slice(0, -1);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          fontSize: '14px',
          color: '#939598',
          margin: '0px 7px',
          width: '5px',
          height: '20px',
        },
      }}
    >
      {pathNames.length > 0 ? (
        <Link
          // underline="hover"
          className={classes.breadcrumbLink}
          // Used onClick method instead href to prevent the page refreshing error
          onClick={(e) => {
            e.preventDefault();
            navigate('/dashboard');
            // GA tag
            sendGAEvent('press_btn_link', 'link_name', 'open_url');
            sendGAEvent('press_btn_link', 'open_url_value', '/');
          }}
        >
          {t('Breadcrumbs.home')}
        </Link>
      ) : (
        <Typography color="#939598" fontSize="14px" lineHeight="20px">
          {t('Breadcrumbs.home')}
        </Typography>
      )}
      {pathNames.map((pathname, index) => {
        // Breadcrum path
        const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;

        return (
          <Link
            // underline="hover"
            className={classes.breadcrumbLink}
            key={pathname}
            // Used onClick method instead of href to prevent the page refreshing error
            onClick={(e) => {
              e.preventDefault();
              navigate(routeTo);
              // GA tag
              sendGAEvent('press_btn_link', 'link_name', 'open_url');
              sendGAEvent('press_btn_link', 'open_url_value', routeTo);
            }}
          >
            {t(`Breadcrumbs.${pathname}`)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbDetailsPage;
