import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import type {
  AccountInputForm,
  eulaVersion,
} from '../types/inputs/accountInput';
import type {
  AccountRegisterApiRequestParameter,
  AccountRegisterApiResponse,
} from '../types/apis/accountRegisterApi';
import callAccountRegisterApi, {
  ACCOUNT_RRGISTER_API_RESULT_CODE,
} from '../apis/callAccountRegisterApi';
import useCheckOnetime, { ONETIME_CHECK_STATE_TYPE } from './useCheckOnetime';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import { ALL_ERROR_TYPE } from '../constants/constants';
import {
  // isAccountErrorType,
  isErrorType,
} from '../types/apis/apiErrorTypeGuard';
import { SERVER_MESSAGE } from '../constants/constants';
import useSnackbar from './useSnackbar';
import useLogin from './useLogin';
import { sendGAEvent } from './useGAEvent';
import { LOCALE_CODE } from './useLocale';
/**
 * 本カスタムフックからの返却値
 */
export type UseAccountValue = {
  // ［登録］ボタンクリック時処理
  onClickRegist: (
    inputData: AccountInputForm,
    privacyPolicy: eulaVersion,
  ) => void;
  // アカウント登録エラータイプ
  accountRegisterErrorType: ALL_ERROR_TYPE | null;
  // ワンタイムキー有効チェック結果
  onetimeValidCheckResult: ONETIME_CHECK_STATE_TYPE;
  // ワンタイムキー有効確認APIから返却されたメールアドレス
  mailAddress: string;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // APIコール中か否か
  isCallingApi: boolean;
};

/**
 * ［アカウント登録完了］画面遷移時に渡す値
 */
export type AccountRegistedPageState = {
  iotAccountMailAddress: string;
  iotAccountCorpId: string;
  iotAccountCompany: string;
  iotAccountAddress: string;
  iotAccountTel: string;
};

/**
 * アカウント登録画面 hooks
 *
 * @returns
 */
const useAccount = (): UseAccountValue => {
  // アカウント登録エラータイプ get/set
  const [accountRegisterErrorType, setAccountRegisterErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // navigate(画面遷移)
  // const navigate = useNavigate();
  // URLパラメータ取得
  const location = useLocation();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  // ワンタイムキー有効確認 hooksクラス
  const {
    checkValidOnetime,
    onetimeValidCheckResult,
    onetimeKey,
    mailAddress,
  } = useCheckOnetime();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();

  const { onClickLogin } = useLogin();

  /**
   * アカウント登録エラーメッセージ
   */
  const accountRegisterErrorMessage = (error: string) => {
    if (!error) {
      return; // エラーコンポーネント自体非表示にする
    }

    let errMsg = '';
    switch (error) {
      case SERVER_MESSAGE.WARN_INPUT_PARAM:
        errMsg = t('account.accountRegisterApiError.warnInputParam');
        break;
      case SERVER_MESSAGE.INVALID_TOKEN:
        errMsg = t('account.accountRegisterApiError.invalid');
        break;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        errMsg = t('account.accountRegisterApiError.expired');
        break;
      case SERVER_MESSAGE.USER_ALREADY_REGISTERED:
        errMsg = t('account.accountRegisterApiError.registedIot');
        break;
      default:
        errMsg = t('account.accountRegisterApiError.unknown');
    }

    displaySnackbar({
      message: errMsg,
      type: 'error',
    });
  };

  /**
   * アカウント登録
   *
   * @param email 入力されたメールアドレス
   * @param password 入力されたパスワード
   * @param company 入力された会社名
   * @param address 入力された住所
   * @param tel 入力された電話番号
   * @param onetimekey URLパラメータに指定されたワンタイムキー
   * @param locale URLパラメータに指定された言語
   * @returns
   */
  const registAccount = (
    email: string,
    password: string,
    company: string,
    name: string,
    onetimekey: string,
    locale: string,
    privacyPolicy: eulaVersion,
  ): void => {
    // loadingBar表示
    setIsCallingApi(true);

    // エラータイプ初期化
    setAccountRegisterErrorType(null);

    // Account registration API request transmission
    const requestParameter: AccountRegisterApiRequestParameter = {
      mailAddress: email,
      password,
      company,
      name,
      onetimekey,
      locale,
      privacyPolicyId: privacyPolicy?.privacyPolicyId,
      termsOfUseId: privacyPolicy?.termsOfUseId,
    };
    // console.log(requestParameter, 'requestParameter');
    callAccountRegisterApi(requestParameter)
      .then((apiResponse: AccountRegisterApiResponse) => {
        if (
          apiResponse.message ===
          ACCOUNT_RRGISTER_API_RESULT_CODE.SUCCESSFULLY_CREATED
        ) {
          onClickLogin({
            email: requestParameter.mailAddress,
            password: requestParameter.password,
          });

          displaySnackbar({
            message: t('account.registerSuccess'),
          });
          // ［アカウント登録完了］画面に遷移
          // moveAccountRegistedPage(apiResponse);

          return;
        }

        switch (apiResponse.message) {
          case ACCOUNT_RRGISTER_API_RESULT_CODE.WARN_INPUT_PARAM:
            throw ACCOUNT_RRGISTER_API_RESULT_CODE.WARN_INPUT_PARAM;
          case ACCOUNT_RRGISTER_API_RESULT_CODE.ONE_TIME_KEY_CHECK_FAILED:
            throw ACCOUNT_RRGISTER_API_RESULT_CODE.ONE_TIME_KEY_CHECK_FAILED;
          // case ACCOUNT_RRGISTER_API_RESULT_CODE.INFO_EXPIRED:
          //   throw ACCOUNT_RRGISTER_API_RESULT_CODE.INFO_EXPIRED;
          case ACCOUNT_RRGISTER_API_RESULT_CODE.USER_ALREADY_REGISTERED:
            throw ACCOUNT_RRGISTER_API_RESULT_CODE.USER_ALREADY_REGISTERED;
          default:
            throw ACCOUNT_RRGISTER_API_RESULT_CODE.ERR_UNKNOWN;
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setAccountRegisterErrorType(error);
          accountRegisterErrorMessage(error);
        } else {
          setAccountRegisterErrorType(
            ACCOUNT_RRGISTER_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
      })
      .finally(() => {
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  /**
   * ［Registration] button click processing
   *
   * @param inpudData Input form data
   */
  const onClickRegist = (
    inpudData: AccountInputForm,
    privacyPolicy: eulaVersion,
  ): void => {
    // account registration
    registAccount(
      mailAddress,
      inpudData.password,
      inpudData.company.trim(),
      inpudData.name.trim(),
      onetimeKey,
      LOCALE_CODE.EN,
      privacyPolicy,
    );

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'register');
    sendGAEvent(
      'press_btn',
      'input_name_value',
      inpudData.name.trim().toString(),
    );
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // loadingBar表示
    setIsCallingApi(true);
    // ワンタイムキー有効チェック処理を実行
    const urlSearchParams = new URLSearchParams(location.search);
    checkValidOnetime(urlSearchParams).finally(() => {
      // loadingBar非表示
      setIsCallingApi(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 第二引数に[]を指定してコンポーネントの更新によるuseEffectを止める

  return {
    onClickRegist,
    accountRegisterErrorType,
    onetimeValidCheckResult,
    mailAddress,
    t,
    isCallingApi,
  };
};

export default useAccount;
