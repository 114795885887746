import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { TFunction } from 'react-i18next';
import SimpleBarReact from 'simplebar-react';
import dayjs from 'dayjs';
import { ToLangInfo } from '../types/apis/toLangListApi.d';
import {
  convertToLangInfoArrayToTableRowDataArray,
  sortedItems,
  updateSortConfig,
  filterArray,
} from '../utils/datatable/dataTableUtil';
import {
  createTotalPageNumber,
  // createDonwloadLinkAndClick,
} from '../utils/utility';
import {
  convertStrToDateyyyyMM01T00,
  fetchTodayUTCyyyyMM01T00,
} from '../utils/dateUtil';
import {
  TableHeaderData,
  TableRowData,
  SortConfig,
  TO_LANG_TABLE_DATA_KEY,
  DIRECTION_TYPE,
  DisplayContentState,
  API_CALL_STATE,
  FILTERING_STATE,
} from '../types/datatable/dataTable.d';
import useFilter from './useFilter';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import type { FilterInput } from '../types/inputs/filterInput';
import usePagination from './usePagination';
import useDateRange, { UseDateRangeValue } from './useDateRange';
import useViewSwitchDropDown from './useViewSwitchDropDown';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import {
  TABLE_ROW_NUMBER_PER_PAGE,
  API_URL,
  CSV_DOWNLOAD_KEY,
  DATE_FORMAT,
  SERVER_MESSAGE,
} from '../constants/constants';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../types/download/downloadDate';
import {
  TO_LANG_LIST_ERROR_TYPE,
  TO_LANG_LIST_API_RESULT_CODE,
} from '../apis/callToLangListApi';
import {
  formatDownloadDate,
  validateDownloadDate,
  createDonwloadErrorMessage,
} from '../utils/donwload/downloadDateUtil';
import { ViewType, VIEW_KEY } from '../types/viewswitch/viewSwitch.d';
import { ViewSwitchInput } from '../types/inputs/viewSwitchInput.d';
import {
  ToLangListResult,
  TO_LANG_WIDTH_ARRAY,
} from '../types/report/toLangList.d';
import { fetchToLangList } from '../utils/report/toLangListUtil';
import useSnackbar from './useSnackbar';
import { REPORT_VIEW_TYPE } from '../types/report/fromLangList.d';
import callReportDownload, { UrlList } from '../apis/callReportApi';
import { sendGAEvent } from './useGAEvent';
import { LOCALE_CODE } from './useLocale';

/**
 * ソート情報
 */
export type SortValue = {
  // ソート設定
  sortConfig: SortConfig;
  // テーブルの[ソート]ボタンがクリックされた場合の処理
  onClickSortButton: (data: TableHeaderData) => void;
};

/**
 * フィルタリング情報
 */
export type FilterValue = {
  // フィルタリングの[検索]ボタンがクリックされた
  onClickFilterSearch: (inputData: FilterInput) => void;
  // 入力中の検索ワード
  filterInputData: FilterInput;
};

/**
 * ページネーション情報
 */
export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  // ページ総数を変更
  changeTotalPages: (pages: number) => void;
  onClickFirstPage: () => void;

  onClickLastPage: () => void;

  pageDataIndexTo: number;

  pageDataIndexFrom: number;
};

/**
 * ビュー切替情報
 */
export type ViewSwitchValue = {
  // [ビュー切替]メニューの表示内容
  viewMenuContents: ViewType;
  // 現在選択中のビュー
  viewSwitchInputData: ViewSwitchInput;
  // [ビュー切替]ドロップダウンがクリックされた
  clickViewSwitch: (key: string) => void;
};

/**
 * 日付指定エラー情報
 */
export type DateRangeErrorValue = {
  // エラータイプ
  dateRangeAlertType: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  dateRangeErrorMessage: string;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseToLangListValue = {
  // エラータイプ
  errorType: TO_LANG_LIST_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // APIコール中か否か(true=コール中)
  isCallingApi: boolean;
  // アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
  onClickLogoutModal: () => void;
  // テーブルヘッダーに表示する内容
  headerColumns: TableHeaderData[];
  // 表示中のページに表示するテーブル内容
  currentPageDataRows: TableRowData[];
  // 一覧総数
  totalListCount: number;
  // [一括ダウンロード]ボタンクリック
  clickDownload: () => void;
  // ソート情報
  sortValue: SortValue;
  // フィルタリング情報
  // eslint-disable-next-line spaced-comment
  filterValue?: FilterValue;

  // ページネーション情報
  paginationValue: PaginationValue;
  // ビュー切替情報
  viewSwitchValue: ViewSwitchValue;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // 日付指定エラー情報
  dateRangeErrorValue: DateRangeErrorValue;
  // 言語切り替え(フレームワーク)
  t: TFunction;

  viewType: REPORT_VIEW_TYPE;
  isCallingReportApi: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

type Props = {
  searchTextInput: string;
};

/**
 * 翻訳後言語画面 hooks
 *
 * @returns
 */
const useToLangList = ({ searchTextInput }: Props): UseToLangListValue => {
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState<number>(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);
  const [viewType, setViewType] = useState<REPORT_VIEW_TYPE>('d');
  const [isCallingReportApi, setIsCallingReportApi] = useState<boolean>(false);

  // 翻訳後言語取得APIから取得した一覧 get/set
  const originalToLangInfos = useRef<ToLangInfo[]>([]);
  // フィルタリング用一覧
  const filterToLangInfos = useRef<ToLangInfo[]>([]);

  // テーブルに表示中のページデータ get/set
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);

  // 画面の表示状態を管理
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API呼び出し状態(呼び出し前)
      filteringState: FILTERING_STATE.inactive, // フィルタリング適用状態(フィルタリング解除)
    });
  // 初回レンダリングか否か(true=初回レンダリング)
  const isFirstRenderingRef = useRef(true);

  // 翻訳後言語取得エラータイプ get/set
  const [errorType, setErrorType] = useState<TO_LANG_LIST_ERROR_TYPE | null>(
    null,
  );
  // APIコール中か否か(true=コール中)
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // 一覧に表示中のデータ総数(データ総数, フィルタリング結果数) get/set
  const [totalListCount, setTotalListCount] = useState<number>(0);
  // ソート状態 get/set
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });

  // 日付指定アラート状態
  const [dateRangeAlertType, setDateRangeAlertType] =
    useState<DOWNLOAD_DATE_ERROR_TYPE | null>(null);

  // アクセスキーhooks
  const { accessKey } = useAccessKey();
  // アカウント情報hooks
  const { accountInfo } = useAccountInfo();
  // フィルターhooks
  const { onClickFilterSearch, filterInputData } = useFilter('');
  // ページネーション hooks
  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 1);
  // [ビュー切替]ドロップダウン hooks
  const { viewMenuContents, viewSwitchInputData, clickViewSwitch } =
    useViewSwitchDropDown(VIEW_KEY.d);
  // 日付範囲 hooks
  const { startDate, endDate, changeStartDate, changeEndDate } = useDateRange(
    dayjs().startOf('month').toDate(),
    new Date(),
  );
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  // 選択できる最小値(法人登録日時)
  const minDate = useMemo(
    (): Date => convertStrToDateyyyyMM01T00(accountInfo.corpCreatedDt),
    [accountInfo.corpCreatedDt],
  );
  // 選択できる最大値(現在月)
  const maxDate = useMemo((): Date => fetchTodayUTCyyyyMM01T00(), []);

  // 翻訳後言語取得APIから返却されたヘッダー（日、週、月ごとに変動）
  const [toLangApiResponseHeaderColums, setToLangApiResponseHeaderColums] =
    useState<TableHeaderData[]>([]);
  /**
   * テーブルのヘッダーに表示する内容
   *
   * 言語切替、APIから返却されたヘッダーが更新された場合に表示用ヘッダー更新
   */
  const headerColumns = useMemo((): TableHeaderData[] => {
    // 固定ヘッダ
    const defaultColumns = [
      {
        key: TO_LANG_TABLE_DATA_KEY.LANG,
        value: t('toLangList.header.lang'),
        isSort: true,
        width: TO_LANG_WIDTH_ARRAY[0],
      },
      {
        key: TO_LANG_TABLE_DATA_KEY.USE_COUNT,
        value: t('toLangList.header.useCount'),
        isSort: true,
        width:
          // eslint-disable-next-line no-nested-ternary
          accountInfo.locale === LOCALE_CODE.FR
            ? '190px'
            : accountInfo.locale === LOCALE_CODE.DE
            ? '170px'
            : TO_LANG_WIDTH_ARRAY[1],
      },
    ];

    // 固定ヘッダとAPIから返却されたヘッダを統合
    return [...defaultColumns, ...toLangApiResponseHeaderColums];
  }, [accountInfo.locale, t, toLangApiResponseHeaderColums]);

  /**
   * 日付指定エラーメッセージ
   */
  const dateRangeErrorMessage = useMemo((): string => {
    if (!dateRangeAlertType) {
      return '';
    }

    return createDonwloadErrorMessage(
      dateRangeAlertType,
      t,
      DATE_FORMAT,
      minDate,
      maxDate,
    );
  }, [dateRangeAlertType, maxDate, minDate, t]);

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return ''; // エラーコンポーネント自体非表示にする
    }
    switch (errorType) {
      case TO_LANG_LIST_API_RESULT_CODE.INFO_DATA_OVER:
        if (viewSwitchInputData.viewKey === VIEW_KEY.d) {
          return t('report.apiError.dateOver');
        }
        if (viewSwitchInputData.viewKey === VIEW_KEY.w) {
          return t('report.apiError.dateOverWeek');
        }

        return t('report.apiError.dateOverMonth');
      case TO_LANG_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
      case TO_LANG_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
      case TO_LANG_LIST_API_RESULT_CODE.NO_INTERNET:
      case TO_LANG_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        return '';
      default:
        return t('toLangList.toLangListApiError.unknown');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType, t]);
  const { displaySnackbar } = useSnackbar();
  useEffect(() => {
    if (errorMessage) {
      displaySnackbar({ message: errorMessage, type: 'error' });
    }
    // eslint-disable-next-line
  }, [errorType]);

  /**
   * [ソート]ボタンがクリックされた
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort) {
        // ソートが許可されていない列だった場合は何もしない
        return;
      }

      // ソート情報作成
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
    },
    [sortConfig],
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setErrorType(null);
  };

  /**
   * フィルタリング実行
   */
  const performFilter = (): void => {
    // フィルタリング再実行(フィルタリング中にビュー切替が行われたときに実行されることを想定)
    if (searchTextInput !== '') {
      // 必要な内部処理が同じため、ボタンクリックイベントではないがここで呼び出す
      onClickFilterSearch({
        searchId: searchTextInput, // 一意のキーを指定すればいいので検索ワードを指定
        searchText: searchTextInput,
      });
    }
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    allowTransition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 画面の状態を管理して表示データを作成
   *
   * 状態
   * ・翻訳後言語一覧取得API呼び出し状態（前/済/取得失敗）
   * ・フィルタリング適用状態（フィルタリングなし/フィルタリングあり/フィルタリング結果が0件）
   * ・ソート状態（昇順/降順/なし）
   *
   * 1.翻訳後言語一覧取得API呼び出し前
   *   ・翻訳後言語一覧取得API呼び出し
   * 2.翻訳後言語一覧取得API呼び出し後
   *   ・フィルター適用あり/なしで表示用データ作成
   *   ・ソート適用
   */
  const [apiCalled, setApiCalled] = useState<number>(0);

  useEffect(() => {
    // 初回レンダリング時は何もしない(一覧取得が2回行われていたので制御追加)
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false;

      return;
    }

    // API呼び出し前
    if (displayContentState.apiCallState === API_CALL_STATE.pending) {
      // loadingBar表示
      setIsCallingApi(true);
      // エラータイプ初期化
      setErrorType(null);
      // ここにきた時点でnullになることはありえないが、Date型を確定させるために処理入れる
      if (startDate == null || endDate == null) {
        return;
      }
      // 翻訳後言語一覧取得
      const formattedStartDate = formatDownloadDate(startDate)?.slice(0, 6);
      const formattedEndDate = formatDownloadDate(endDate)?.slice(0, 6);

      if (
        startDate.toDateString() === 'Invalid Date' ||
        endDate.toDateString() === 'Invalid Date'
      ) {
        return;
      }

      fetchToLangList(
        accountInfo.corpId,
        formattedStartDate,
        formattedEndDate,
        viewSwitchInputData.viewKey,
      )
        .then((result: ToLangListResult) => {
          if (
            result.resultCode ===
            TO_LANG_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
          ) {
            if (result.toLangInfos !== null) {
              // APIから返却された一覧をuseRefに追加
              originalToLangInfos.current = result.toLangInfos;
              setViewType(result.viewType as REPORT_VIEW_TYPE);
              // APIから返却されたヘッダー
              setToLangApiResponseHeaderColums([...result.tableHeader]);
              // state更新
              setDisplayContentState({
                ...displayContentState,
                apiCallState: API_CALL_STATE.fulfilled,
              });
            }

            return;
          }
          // エラー
          originalToLangInfos.current = [];
          setErrorType(result.resultCode);
          // state更新
          setDisplayContentState({
            ...displayContentState,
            apiCallState: API_CALL_STATE.rejected,
          });
        })
        .finally(() => {
          // loadingBar非表示
          setIsCallingApi(false);
          // 一覧情報が揃ったタイミングでフィルタリング再実行
          performFilter();
          setApiCalled((api) => api + 1);
        });
    }
    // API呼び出し成功
    else if (displayContentState.apiCallState === API_CALL_STATE.fulfilled) {
      const firstPageIndex = (currentPage - 1) * currentRowNumberPerPage;
      const lastPageIndex = firstPageIndex + currentRowNumberPerPage;

      let list: ToLangInfo[] = [];

      // フィルターなし
      if (displayContentState.filteringState === FILTERING_STATE.inactive) {
        list = originalToLangInfos.current;
      }
      // フィルターあり
      else if (displayContentState.filteringState === FILTERING_STATE.active) {
        list = filterToLangInfos.current;
      }

      // 一覧をもとに表示用データに変換
      let tableRowDataArray: TableRowData[] =
        convertToLangInfoArrayToTableRowDataArray(list);

      // ソート適用
      if (sortConfig.direction !== DIRECTION_TYPE.none) {
        tableRowDataArray = sortedItems(tableRowDataArray, sortConfig);
      }
      // 1ページ分の表示内容に分割
      tableRowDataArray = tableRowDataArray.slice(
        firstPageIndex,
        lastPageIndex,
      );

      setCurrentPageDataRows(tableRowDataArray); // 表示用データセット
      changeTotalPages(
        createTotalPageNumber(list.length, currentRowNumberPerPage),
      ); // ページ総数設定セット
      setTotalListCount(list.length); // 件数セット
      setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
      setPageDataIndexTo(
        currentPage * currentRowNumberPerPage > list.length
          ? list.length
          : currentPage * currentRowNumberPerPage,
      );
      // after pagination or sorting the scroll bar will be on top
      if (simpleBarRef.current) {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }
    }
    // API呼び出し失敗
    else {
      setCurrentPageDataRows([]); // 表示用データセット
      changeTotalPages(1); // ページ総数設定セット
      setTotalListCount(0); // 件数セット
      changeCurrentPage(1); // 1ページ目を表示
    }

    // startDate, endDateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessKey,
    accountInfo.corpId,
    changeTotalPages,
    currentPage,
    currentRowNumberPerPage,
    displayContentState,
    sortConfig,
    viewSwitchInputData.viewKey,
  ]);

  useEffect(() => {
    setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
    setPageDataIndexTo(
      currentPage * currentRowNumberPerPage > totalListCount
        ? totalListCount
        : currentPage * currentRowNumberPerPage,
    );
  }, [currentPage, currentRowNumberPerPage, totalListCount]);

  useEffect(() => {
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      return;
    }
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });
    // eslint-disable-next-line
  }, [startDate, viewSwitchInputData.viewKey]);

  /**
   * フィルタリングが更新された場合の処理
   */
  useEffect(() => {
    // フィルタリング実行直後は1ページ目に移動
    changeCurrentPage(1);

    if (!searchTextInput) {
      // フィルターなし
      filterToLangInfos.current = [];
      // state更新
      setDisplayContentState({
        ...displayContentState,
        filteringState: FILTERING_STATE.inactive,
      });

      return;
    }
    // 検索文字列
    const searchText: string = searchTextInput;
    // フィルター適用
    filterToLangInfos.current = filterArray(
      originalToLangInfos.current,
      searchText,
      [], // フィルタリング対象外のkey
    );
    // state更新
    setDisplayContentState({
      ...displayContentState,
      filteringState: FILTERING_STATE.active,
    });

    // displayContentStateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCurrentPage, searchTextInput, apiCalled]);

  /**
   * 日付バリデーションチェック
   *
   * @returns true=OK / false=バリデーションエラー
   */
  const validateDate = useCallback((): boolean => {
    // 日付指定アラート状態初期化
    setDateRangeAlertType(null);
    // バリエーションチェック
    const result: DOWNLOAD_DATE_ERROR_TYPE | null = validateDownloadDate(
      startDate,
      endDate,
      minDate,
      maxDate,
      DATE_FORMAT,
    );
    if (result !== null) {
      // エラー結果を日付指定アラート状態に設定
      setDateRangeAlertType(result);

      return false;
    }

    return true;
  }, [startDate, endDate, minDate, maxDate]);

  /**
   * 期間開始日または期間終了日が変更された時点で、
   * 日付バリデーションチェック実行
   */
  useEffect(() => {
    validateDate();
  }, [validateDate]);

  /**
   * ビュー切替が変更された場合の処理
   */
  useEffect(() => {
    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままビュー切替可能なのでここでもチェックする
    if (!validateDate()) {
      return;
    }

    // ビュー切替後は1ページ目に移動
    changeCurrentPage(1);
    // stateを更新して、一覧を再取得
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });

    // displayContentStateを検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewSwitchInputData]);

  /**
   * [一括ダウンロード]ボタンクリック
   *
   * @returns
   */
  const clickDownload = (): void => {
    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままダウンロード可能なのでここでもチェックする
    // if (!validateDate()) {
    //   return;
    // }
    setIsCallingReportApi(true);
    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままダウンロード可能なのでここでもチェックする

    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      displaySnackbar({
        message: t('report.apiError.validRange'),
        type: 'error',
      });

      return;
    }
    const formattedStartDate = formatDownloadDate(startDate)?.slice(0, 6);
    const formattedEndDate = formatDownloadDate(endDate)?.slice(0, 6);
    callReportDownload(
      accountInfo.corpId,
      formattedStartDate,
      formattedEndDate,
      viewSwitchInputData.viewKey,
      UrlList.TransTo,
    )
      .then((response) => {
        if (response.message === SERVER_MESSAGE.FETCH_OK) {
          displaySnackbar({
            message: t('toLangList.toLangListApiError.success'),
          });
        } else {
          displaySnackbar({
            message: t('toLangList.toLangListApiError.failed'),
            type: 'error',
          });
        }
        setIsCallingReportApi(false);
      })
      .catch(() => {
        displaySnackbar({
          message: t('toLangList.toLangListApiError.failed'),
          type: 'error',
        });
        setIsCallingReportApi(false);
      });
    // // ドメイン
    // const domain = process.env.REACT_APP_API_DOMAIN ?? '';
    // // CSVダウンロード
    // const url = `${domain}${API_URL.TO_LANG_LIST_DOWNLOAD_API}?${
    //   CSV_DOWNLOAD_KEY.accesskey
    // }=${accessKey}&${CSV_DOWNLOAD_KEY.corpId}=${accountInfo.corpId}&${
    //   CSV_DOWNLOAD_KEY.from
    // }=${formatDownloadDate(startDate)}&${
    //   CSV_DOWNLOAD_KEY.to
    // }=${formatDownloadDate(endDate)}&${CSV_DOWNLOAD_KEY.view}=${
    //   viewSwitchInputData.viewKey
    // }`;
    // createDonwloadLinkAndClick(url);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'exprt_data');
  };

  return {
    errorType,
    errorMessage,
    isCallingApi,
    onClickLogoutModal,
    headerColumns,
    currentPageDataRows,
    viewType,
    totalListCount,
    clickDownload,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      changeTotalPages,
      onClickFirstPage,
      onClickLastPage,
      pageDataIndexFrom,
      pageDataIndexTo,
    },
    viewSwitchValue: {
      viewMenuContents,
      viewSwitchInputData,
      clickViewSwitch,
    },
    dateRangeValue: {
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
    },
    downloadDate: {
      minDate,
      maxDate,
    },
    dateRangeErrorValue: {
      dateRangeAlertType,
      dateRangeErrorMessage,
    },
    t,
    isCallingReportApi,
    simpleBarRef,
  };
};

export default useToLangList;
