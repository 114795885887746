import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  passwordStrength: string;
};

const PasswordStrengthText = React.memo<Props>(
  ({ passwordStrength }): JSX.Element => {
    const { t } = useSwitchLocaleLanguage();
    const classes = useStyles();

    return (
      <Box sx={{ margin: 'auto 0px' }}>
        {passwordStrength === 'Weak' && (
          <Typography className={classes.weakPasswordStrengthWarn}>
            {t('personalSetting.password.strengthWeek')}
          </Typography>
        )}{' '}
        {passwordStrength === 'Good' && (
          <Typography className={classes.goodPasswordStrengthWarn}>
            {t('personalSetting.password.strengthGood')}
          </Typography>
        )}{' '}
        {passwordStrength === 'Strong' && (
          <Typography className={classes.strongPasswordStrengthWarn}>
            {t('personalSetting.password.strengthStrong')}
          </Typography>
        )}
      </Box>
    );
  },
);

export default PasswordStrengthText;
