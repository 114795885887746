import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { AccountRegistedPageState } from './useAccount';
import { URL_PARAMETER_KEY, PAGE_PATH_NAME } from '../constants/constants';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import { isAccountRegistedPageStateType } from '../utils/utility';

/**
 * 本カスタムフックからの返却値
 */
export type UseAccountRegistedValue = {
  // ［ログイン］ボタン
  onClickLogin: () => void;
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // ［アカウント登録］画面から受け取ったアカウント情報
  accountRegistedPageState: AccountRegistedPageState | null;
  // ［ログイン］画面URL
  loginUrl: string;
};

/**
 * アカウント登録完了画面 hooksクラス
 *
 * @returns
 */
const useAccountRegisted = (): UseAccountRegistedValue => {
  // navigate(画面遷移)
  const navigate = useNavigate();
  // ロケーション情報(state=［アカウント登録］画面からアカウント情報を取得)
  /*eslint-disable*/
  const { pathname, state, search } = useLocation();
  // 言語切り替えhooks
  const { t, localeLanguage } = useSwitchLocaleLanguage();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  /**
   * ［ログイン］画面のURL作成
   * @returns
   */
  const createLoginUrl = (): string =>
    `${process.env.REACT_APP_DOMAIN ?? ''}${PAGE_PATH_NAME.LOGON}?${
      URL_PARAMETER_KEY.locale
    }=${localeLanguage}`;

  /**
   * ［ログイン］ボタンクリック処理
   */
  const onClickLogin = (): void => {
    // 新規タブで［ログイン］画面を表示(ロケール付き)
    window.open(createLoginUrl(), '_blank');
  };

  /**
   * ブラウザバック制御
   */
  const browserBackBlockFunction = (): void => {
    const accountRegistedPageState: AccountRegistedPageState =
      state as AccountRegistedPageState;
    navigate(`${pathname}${search}`, {
      state: accountRegistedPageState,
    }); // [アカウント登録完了］画面に遷移(state付き)
  };

  /**
   * ［アカウント登録完了］画面に付与されたstateをAccountRegistedPageState型に変換
   * stateがAccountRegistedPageState型ではない場合は、空文字が指定されたAccountRegistedPageStateを新規作成して返却
   */
  const castState = useMemo((): AccountRegistedPageState => {
    // stateの型チェック
    if (isAccountRegistedPageStateType(state)) {
      return state;
    }
    // stateがAccountRegistedPageStateではない場合は空文字が指定されたAccountRegistedPageStateを新規作成して返却
    const emptyAccountRegistedPageState: AccountRegistedPageState = {
      iotAccountMailAddress: '',
      iotAccountCorpId: '',
      iotAccountCompany: '',
      iotAccountAddress: '',
      iotAccountTel: '',
    };

    return emptyAccountRegistedPageState;
  }, [state]);

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return () => ({});
    }

    // ブラウザバックで［アカウント登録完了］画面にとどまるように制御
    window.history.pushState(null, '', `${pathname}${search}`);
    window.addEventListener('popstate', browserBackBlockFunction);

    return () => {
      window.removeEventListener('popstate', browserBackBlockFunction); // クリーンアップ(メモリーリークしてホワイトアウトするのは防ぐ)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 第二引数に[]を指定してコンポーネントの更新によるuseEffectを止める

  return {
    onClickLogin,
    t,
    accountRegistedPageState: castState,
    loginUrl: createLoginUrl(),
  };
};

export default useAccountRegisted;
