import type {
  FromLangListApiParameter,
  FromLangListApiResponse,
} from '../types/apis/fromLangListApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * 翻訳元言語取得API 結果コード
 */
export const FROM_LANG_LIST_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 表示件数オーバー
  INFO_DATA_OVER: 'INFO_DATA_OVER',
  // アクセスキー無し(※翻訳元言語取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※翻訳元言語取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※翻訳元言語取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  // トークン無効
  INVALID_TOKEN: 'INVALID_TOKEN',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  NO_INTERNET: 'NO_INTERNET',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
} as const;

/**
 * 翻訳元言語取得エラータイプ
 */
export type FROM_LANG_LIST_ERROR_TYPE =
  (typeof FROM_LANG_LIST_API_RESULT_CODE)[keyof typeof FROM_LANG_LIST_API_RESULT_CODE];

/**
 * 翻訳元言語取得API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 翻訳元言語取得APIからのレスポンス
 */
const callFromLangListApi = (
  requestParams: FromLangListApiParameter,
  // accessKey: string,
): Promise<FromLangListApiResponse> => {
  // リクエストパラメータ
  const jsonParams = {
    corpId: requestParams.corpId,
    from: requestParams.from,
    to: requestParams.to,
    view: requestParams.view,
  };
  // GET
  const response = callApiUtil
    .get<FromLangListApiResponse>(
      `${API_URL.FROM_LANG_LIST_API}?corpId=${jsonParams.corpId}&from=${jsonParams.from}&to=${jsonParams.to}&view=${jsonParams.view}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callFromLangListApi;
