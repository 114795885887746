import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Dialog, Typography, Checkbox } from '@mui/material';
import { useStyles } from './DialogStyle';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import { ReactComponent as RedirectIcon } from '../../images/icon/redirect_icon.svg';
import useEulaGetLink from '../../hooks/useEulaGetLink';
import useAccountInfo from '../../hooks/useAccountInfo';
import useEulaUpdate from '../../hooks/useEulaUpdate';
import { RootState } from '../../state/store';
import Loader from '../Atoms/Loader';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

const AgreementUpdate = (): JSX.Element => {
  const classes = useStyles();
  const { localeLanguage } = useLocale();
  const { accountInfo, saveReLoginEulaState } = useAccountInfo();
  const [checkedCheckbox, setCheckedCheckbox] = useState(false);
  const { onClickEulaApi, eulaValue, isCallingEulaApi } = useEulaGetLink();
  const [open, setOpen] = React.useState(false);
  const { onClickEulaUpdate, isCallingEulaUpdateApi } = useEulaUpdate();
  const { t } = useSwitchLocaleLanguage();

  const welcomeDialog = useSelector(
    (state: RootState) => state.welcomeDialogState,
  );
  useEffect(() => {
    if (accountInfo.latestEulaConsented === false && !welcomeDialog.open) {
      setOpen(true);
      onClickEulaApi();
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.latestEulaConsented, welcomeDialog.open]);

  const onClickBtn = () => {
    saveReLoginEulaState(false);
    onClickEulaUpdate(eulaValue);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'agree');
    sendGAEvent('press_btn', 'input_name_value', accountInfo.userId.toString());
  };

  return (
    <Dialog open={open} className={classes.userAgrementUpdateDialog}>
      <Typography className={classes.userAgrementUpdateDialogTitle}>
        {accountInfo.eulaConsentedPreviously
          ? t('agreementUpdate.message.newUserAgreement')
          : t('agreementUpdate.message.userAgreement')}
      </Typography>

      <Typography className={classes.userAgrementUpdateDialogText}>
        {accountInfo.eulaConsentedPreviously
          ? t('agreementUpdate.message.readUpdatedUserAgreement')
          : t('agreementUpdate.message.readUserAgreementPrivacyPolicy')}
      </Typography>
      <Box sx={{ display: 'flex', marginTop: '20px', align: 'center' }}>
        <Checkbox
          checked={checkedCheckbox}
          disabled={isCallingEulaApi}
          onClick={() => {
            setCheckedCheckbox(!checkedCheckbox);

            // GA tag
            if (!checkedCheckbox) {
              sendGAEvent('select_check', 'btn_name', 'agree');
            }
          }}
          icon={
            <CheckBox
              style={{
                marginRight: '-2px',
              }}
            />
          }
          checkedIcon={<CheckedCheckBox />}
          sx={{
            p: '0px',
            mr: '8px',
            height: '18px',
            width: '18px',
            marginTop: '2px',
            '&:hover': {
              '& svg': {
                '& g': {
                  fill: checkedCheckbox ? '#007EB4' : '#D2D2D2',
                },
              },
            },
          }}
        />
        <Typography
          align="left"
          sx={{
            fontSize: '14px',
            '& a': {
              color: '#009DE1',
            },
            '&:hover': {
              '& a': {
                color: '#007EB4',
              },
            },
          }}
        >
          {localeLanguage !== LOCALE_CODE.JA &&
            `${t('agreementUpdate.policy.haveRead')} `}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={eulaValue?.termsOfUse}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              paddingRight: '2px',
            }}
            onClick={() => {
              // GA tag
              sendGAEvent('press_btn_link', 'link_name', 'terms_of_service');
            }}
          >
            {t('agreementUpdate.policy.agreement')}
            {'  '}
            <RedirectIcon
              style={{
                height: '12px',
                width: '13px',
                marginTop: '-3px',
                marginLeft: '3px',
              }}
            />
          </a>
          {'   '}
          {t('agreementUpdate.policy.and')}
          {'   '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={eulaValue?.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={() => {
              // GA tag
              sendGAEvent('press_btn_link', 'link_name', 'privacy_policy');
            }}
          >
            {t('agreementUpdate.policy.policy')}
            {'  '}
            <RedirectIcon
              style={{
                height: '12px',
                width: '13px',
                marginTop: '-3px',
                marginLeft: '3px',
              }}
            />
          </a>
          {localeLanguage === LOCALE_CODE.JA &&
            ` ${t('agreementUpdate.policy.haveRead')}`}
        </Typography>
      </Box>
      <Button
        className={classes.fullWidthButton}
        fullWidth
        size="large"
        variant="contained"
        disabled={!checkedCheckbox}
        onClick={onClickBtn}
      >
        {isCallingEulaUpdateApi ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {t('agreementUpdate.policy.agree')} <Loader colorCode="#fff" />
          </Box>
        ) : (
          t('agreementUpdate.policy.agree')
        )}
      </Button>
    </Dialog>
  );
};

export default AgreementUpdate;
