import { API_URL } from '../constants/constants';
import type {
  PasswordResetApiRequestParam,
  PasswordResetApiResponse,
} from '../types/apis/passwordResetApi';
import callApiUtil from '../utils/callApiUtil';

/**
 * パスワードリセットAPI 結果コード
 */
export const PASSWORD_RESET_API_RESULT_CODE = {
  // OK
  OK_CREATED: 'SUCCESSFULLY_CREATED',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 未登録のワンタイムキー
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  OLD_AND_NEW_PASSWORD_SAME: 'OLD_AND_NEW_PASSWORD_SAME',
} as const;

/**
 * パスワードリセットエラータイプ
 */
export type PASSWORD_RESET_ERROR_TYPE =
  (typeof PASSWORD_RESET_API_RESULT_CODE)[keyof typeof PASSWORD_RESET_API_RESULT_CODE];

/**
 * パスワードリセットAPI 呼び出し
 * @param requestParams リクエストパラメータ
 * @returns パスワードリセットAPIからのレスポンス
 */
/*eslint-disable*/
const callPasswordResetApi = (
  requestParams: PasswordResetApiRequestParam,
): Promise<PasswordResetApiResponse> => {
  // リクエストパラメータ
  const jsonParams = {
    password: requestParams.password,
    oneTimeKey: requestParams.oneTimeKey,
  };

  // リクエスト送信(POST)
  const response = callApiUtil
    .post<PasswordResetApiResponse>(API_URL.PASSWORD_RESET_API, jsonParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};
export default callPasswordResetApi;
