import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button, Dialog, Typography } from '@mui/material';
import { useStyles } from './DialogStyle';
import useLogout from '../../hooks/useLogout';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';

type Props = {
  open: boolean;
  setReloginState: () => void;
};

const AgreementUpdateReLogin = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const { open, setReloginState } = props;
  const { t } = useSwitchLocaleLanguage();
  const { accountInfo } = useAccountInfo();

  const { onClickLogoff } = useLogout();

  const onClickBtn = () => {
    onClickLogoff();
    setReloginState();

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'relogin');
    sendGAEvent('press_btn', 'input_name_value', accountInfo.userId.toString());
  };

  return (
    <Dialog open={open} className={classes.userAgrementUpdateReLoginDialog}>
      <Typography className={classes.userAgrementUpdateDialogTitle}>
        {t('userAgreementModal.message.updatedUserAgreement')}
      </Typography>
      <Typography className={classes.userAgrementUpdateDialogText}>
        {t('userAgreementModal.message.reLoginInstruction')}
      </Typography>
      <Button
        className={classes.fullWidthButton}
        fullWidth
        size="large"
        variant="contained"
        onClick={onClickBtn}
      >
        {t('userAgreementModal.message.reLogin')}
      </Button>
    </Dialog>
  );
});

export default AgreementUpdateReLogin;
