import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// icons
import { ReactComponent as CloseIcon } from '../../images/icon/close.svg';
// modal icons
import { ReactComponent as ModalDeleteIcon } from '../../images/icon/modal_delete.svg';
// import useSnackbar from '../../hooks/useSnackbar';
// mui custom style
import { useStyles } from '../Templates/CommonStyle';
import Loader from './Loader';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
// factory restore dialog
const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '460px',
    borderRadius: '12px',
    padding: '24px',
    alignSelf: 'flex-start',
    marginTop: '180px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '18px',
      lineHeight: '26px',
      maxWidth: '285px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      marginLeft: 'auto',
    },
  },
}) as typeof Dialog;

type Props = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  icon: string;
  title: string;
  subTitle: string;
  btnBgColor: string;
  btnBgColorHover: string;
  btnTextNegative: string;
  btnTextPositive: string;
  isCallingApi?: boolean;
};

const DeleteDialog = React.memo<Props>((props): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();
  // custom styles
  const classes = useStyles();
  // const { displaySnackbar } = useSnackbar();
  const { open, onClose, onSubmit } = props;

  const deleteBtnAction = () => {
    onSubmit();
  };

  const cancelBtnAction = () => {
    onClose();
  };

  const closeBtnAction = () => {
    onClose();
  };

  return (
    <div>
      <CustomDialog aria-labelledby="ok" open={open}>
        <Box className={`${classes.dialogBox} res-dialog`}>
          <Box className={`${classes.dialogIcon} res-dialog-icon`}>
            <ModalDeleteIcon />
          </Box>
          <Box sx={{ width: '100%' }}>
            <DialogTitle className="res-dialog-title">
              {props.title}
              <IconButton
                aria-label="close"
                onClick={closeBtnAction}
                className={classes.dialogCloseIcon}
              >
                <CloseIcon className={classes.close} />
              </IconButton>
            </DialogTitle>
            <DialogContent
              className={`${classes.dialogContentCommon} res-dialog-text`}
            >
              <Typography variant="body2" className={classes.dialogSubTitle}>
                {props.subTitle}
              </Typography>
            </DialogContent>
            <DialogActions
              className={`${classes.dialogActionsCommon} res-dialog-btns`}
            >
              <Button
                className={classes.btnAction}
                onClick={cancelBtnAction}
                disableRipple
              >
                {props.btnTextNegative}
              </Button>
              {props.isCallingApi ? (
                <Button
                  autoFocus
                  className={`${classes.btnAction} ${classes.btnActionCommon}`}
                  // onClick={handleFactorySettings}
                  sx={{
                    backgroundColor: props.btnBgColor,
                    '&:hover': {
                      backgroundColor: `${props.btnBgColorHover}!important`,
                    },
                    gap: '8px',
                  }}
                  onClick={() => deleteBtnAction()}
                  disableRipple
                >
                  {t('userDeleteDialog.label.deletingBtn')}{' '}
                  <Loader colorCode="#fff" />
                </Button>
              ) : (
                <Button
                  autoFocus
                  className={`${classes.btnAction} ${classes.btnActionCommon}`}
                  // onClick={handleFactorySettings}
                  sx={{
                    backgroundColor: props.btnBgColor,
                    '&:hover': {
                      backgroundColor: `${props.btnBgColorHover}!important`,
                    },
                  }}
                  onClick={() => deleteBtnAction()}
                  disableRipple
                >
                  {props.btnTextPositive}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Box>
      </CustomDialog>
    </div>
  );
});

export default DeleteDialog;
