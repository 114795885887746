import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { DeviceInfoApiResponse } from '../types/apis/eulaGetLinkApi.d';

const callEulaLinkApi = (
  localeValue: string | null,
): Promise<DeviceInfoApiResponse> => {
  /* eslint-disable */
  const response = callApiUtil
    .get<DeviceInfoApiResponse>(
      `${API_URL.EULA__URL_LINK_API}?locale=${localeValue}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callEulaLinkApi;
