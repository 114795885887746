import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAGE_PATH_NAME } from '../constants/constants';
import useAccountInfo from './useAccountInfo';
import { RootState } from '../state/store';
import { AccessKeyState } from '../state/slice/accessKeySlice';

/**
 * 本カスタムフックの返却値
 */
type UseCheckTransitionValue = {
  // 画面遷移を許可するか否か
  allowTransition: () => void;
};

/**
 * 画面遷移制御 hooks
 * @returns
 */
const useCheckEulaTransition = (): UseCheckTransitionValue => {
  const { accountInfo } = useAccountInfo();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const accessKeyRedux = useSelector<RootState, AccessKeyState>(
    (state) => state.iotAccessKey,
  );

  const allowTransition = () => {
    // console.log(accessKeyRedux.iotAccessKey,'IOT ACCESS KEY')
    if (
      accountInfo.latestEulaConsented === false &&
      pathname !== PAGE_PATH_NAME.DASHBOARD &&
      pathname !== PAGE_PATH_NAME.LOGON &&
      accessKeyRedux.iotAccessKey !== ''
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
  };

  return {
    allowTransition,
  };
};

export default useCheckEulaTransition;
