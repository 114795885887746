/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import { ONETIME_CHECK_STATE } from '../../hooks/useCheckOnetime';
import PageLoadingLayer from '../Templates/PageLoadingLayer';
import usePasswordSet from '../../hooks/usePasswordSet';
import type { PasswordSetInput } from '../../types/inputs/passwordSetInput';
import { useStyles } from '../Templates/CommonStyle';
import { USER_PASSWORD_PATTERN } from '../../constants/constants';
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';
import Loader from '../Atoms/Loader';
import PasswordStrengthCard from '../Atoms/PasswordStrengthCard';
import PasswordStrengthText from '../Atoms/PasswordStrengthText';
import passwordStrengthCheckUtil from '../../utils/user/passwordStrengthCheckUtil';
import PasswordSetExpiredLinkCard from '../Atoms/PasswordSetExpiredLinkCard';
import DisabledInputField from '../Atoms/DisabledInputField';
import { sendGAEvent } from '../../hooks/useGAEvent';

/**
 * パスワード設定画面コンポーネント
 * 画面内に「エラーメッセージコンポーネント」を表示
 */
const NewUserPasswordSet = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const inputRefPassword = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);
  // Toggle Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    (inputRefPassword.current as HTMLInputElement)?.focus();
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  // バリデーションフォーム作成
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    clearErrors,
  } = useForm<PasswordSetInput>({
    mode: 'onChange' || 'onSubmit',
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  const {
    onClickSetting,
    onetimeValidCheckResult,
    mailAddress,
    t,
    isCallingApi,
    // onClickLogin,
  } = usePasswordSet();

  // ワンタイムキー有効確認APIから返却されたメールアドレスを入力欄にセット
  useEffect(() => {
    setValue('email', mailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailAddress]);

  const password = watch('password')?.toString();

  const [passwordStrength, setPasswordStrength] = useState('');

  useEffect(() => {
    if (password?.length === 0 || password?.length < 7) {
      setPasswordStrength('');
    }
    if (password && password.length > 7) {
      const strength = passwordStrengthCheckUtil(password);
      setPasswordStrength(strength);
    }
  }, [password]);
  const handleClickAwayPasswordField = () => {
    clearErrors('password');
  };

  switch (onetimeValidCheckResult) {
    case ONETIME_CHECK_STATE.BEFORE_VALID_CHECK:
      return (
        // ワンタイムキーチェック中はローディングバーを表示
        <PageLoadingLayer />
      );
    case ONETIME_CHECK_STATE.VALID:
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <Box className={classes.passwordSetUpCard}>
            <Typography className={classes.passwordSetUpTitle}>
              {t('newUserPasswordSet.title')}
            </Typography>
            <form onSubmit={handleSubmit(onClickSetting)}>
              <Box sx={{ display: 'flex' }}>
                <Typography className={classes.loginCardLevel}>
                  {t('newUserPasswordSet.label.email')}
                </Typography>
                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              <DisabledInputField text={mailAddress} />
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className="res-label"
              >
                <Typography
                  className={`${classes.loginCardLevel} res-label-col`}
                >
                  {t('newUserPasswordSet.label.newPass')}
                  <span className={classes.loginCardLevelMarker}>*</span>
                </Typography>

                <PasswordStrengthText passwordStrength={passwordStrength} />
              </Box>
              <ClickAwayListener onClickAway={handleClickAwayPasswordField}>
                <Box sx={{ position: 'relative' }}>
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: true,
                      minLength: 8,
                      maxLength: 16,
                      pattern: USER_PASSWORD_PATTERN,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ maxLength: 17 }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle new password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disableRipple
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={field.value || ''}
                        fullWidth
                        margin="normal"
                        placeholder={t('resetPassword.placeholder.newPass')}
                        autoComplete="off"
                        error={Boolean(errors.password)}
                        onFocus={() => {
                          // GA tag
                          sendGAEvent(
                            'edit_text_pass',
                            'txt_name',
                            'passsword',
                          );
                        }}
                        sx={{
                          'input::-ms-reveal,input::-ms-clear': {
                            display: 'none',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                              errors.password?.types?.required ||
                              errors.password?.types?.minLength ||
                              errors.password?.types?.maxLength ||
                              errors.password?.types?.pattern
                                ? '#E1341E !important'
                                : '#939598 !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            '&.Mui-focused fieldset': {
                              border:
                                errors.password?.types?.required ||
                                errors.password?.types?.minLength ||
                                errors.password?.types?.maxLength ||
                                errors.password?.types?.pattern
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                            },
                          },
                        }}
                        className={classes.loginFormInput}
                        inputRef={inputRefPassword}
                      />
                    )}
                  />
                  {(errors.password?.types?.required ||
                    errors.password?.types?.minLength ||
                    errors.password?.types?.maxLength ||
                    errors.password?.types?.pattern) && (
                    <Box className={classes.errorCard}>
                      <p style={{ padding: '0', margin: '0', bottom: '44px' }}>
                        {(errors.password?.types?.required &&
                          t('common.validationError.password.empty')) ||
                          ((errors.password?.types?.minLength ||
                            errors.password?.types?.maxLength) &&
                            t('common.validationError.password.invalid')) ||
                          (errors.password?.types?.pattern &&
                            t(
                              'common.validationError.password.enterValidPass',
                            ))}
                      </p>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
              <PasswordStrengthCard
                passwordStrength={passwordStrength}
                pageType="registration"
              />
              <Typography
                sx={{
                  marginTop: '6px',
                  color: '#939598',
                  fontSize: '12px',
                  lineHeight: '20px',
                  // maxWidth: '469px',
                }}
              >
                {t('common.validationError.password.invalidFormat')}
              </Typography>
              <Button
                className={classes.submitButtonLogin}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!password}
              >
                {isCallingApi ? (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {t('newUserPasswordSet.button.settings')}
                    <Loader colorCode="#fff" />
                  </Box>
                ) : (
                  t('newUserPasswordSet.button.settings')
                )}
              </Button>
            </form>
          </Box>
        </Box>
      );
    case ONETIME_CHECK_STATE.INFO_EXPIRED:
      return (
        <PasswordSetExpiredLinkCard
          firstLine={t('resetPassword.errors.expiredURL')}
          secondLine={t('newUserPasswordSet.errors.expiredInstruction')}
        />
      );
    default:
      return (
        <PasswordSetExpiredLinkCard
          firstLine={t('resetPassword.errors.invalidURL')}
          secondLine={t('newUserPasswordSet.errors.invalidInstruction')}
        />
      );
  }
};

export default NewUserPasswordSet;
