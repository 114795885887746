import zxcvbn from 'zxcvbn';

const passwordStrengthCheckUtil = (password: string) => {
  const { score } = zxcvbn(password);
  let strength = '';

  if (score === 0 || score === 1) {
    strength = 'Weak';
  } else if (score === 2) {
    strength = 'Good';
  } else if (score === 3 || score === 4) {
    strength = 'Strong';
  } else {
    strength = '';
  }

  return strength;
};

export default passwordStrengthCheckUtil;
